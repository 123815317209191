import React from 'react';
import axios from 'axios';
import UniListing from './UniListing.js';
import Spinner from 'react-spinkit';
export default class CMSTransfer extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            docs: [],
            total: 0,
            keywords: "",
            page: 1,
            pageSize: 100
        }
        this.getData = this.getData.bind(this)
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.keywordSearch = this.keywordSearch.bind(this);
    }
    keywordSearch(event) {
        this.setState({ page: 1 });
        this.getData(this.state.page, this.state.pageSize);
    }
    handleKeywordChange(event) {
        this.setState({keywords: event.target.value});
    }
    componentDidMount() {
        this.getData(this.state.page, this.state.pageSize);
    }
    getData(page, pageSize) {
        this.setState({loading: true});
        const requestParams = {
            method: "get",
            url: "/api/admin/getUniContent",
            params: {
                page: page,
                pageSize: pageSize
            }
        };
        if (this.state.keywords.length) {
            requestParams.params.keywords = this.state.keywords.split(" ").join(",");
        }
        axios(requestParams).then(res => {
            console.log(res);
            console.log(res.data);
            const docs = res.data.result;
            const total = res.data.total;
            this.setState({loading: false});
            this.setState({docs: []});
            this.setState({ docs, total, page, pageSize });
            console.log(this.state)
        })
    }
    render() {
        return (
            <div>
                {this.state.loading && <Spinner name='double-bounce' />}
                {this.props.mode !== "account" && <div className="container-sm text-center">
                    <div className="input-group m-b-md">
                        <input type="text" name="filter" class="form-control display-inline-block" value={this.state.keywords} onChange={this.handleKeywordChange} placeholder="keywords" />
                        <span class="input-group-btn"><button type="submit" class="btn btn-primary" onClick={this.keywordSearch}>Search</button></span>
                    </div>
                </div>}
                <div className="row">
                    { this.state.docs.map((doc, i) => (<UniListing doc={doc} key={i} mode={this.props.mode} />))}
                </div>
                <div className="text-center">
                        <ul className="pagination">
                        { [...Array(Math.ceil(this.state.total/this.state.pageSize))].map((_, i) => (<li className={this.state.page === i+1 ? 'active' : ''}><a className="void" href="#" onClick={(event) => this.getData(i+1, this.state.pageSize)}>{i + 1}</a></li>))}
                        </ul>
                </div>
            </div>
        )
    }
}

