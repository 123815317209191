import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import ModuleNotice from './ModuleNotice.js';
import './ModulesAdded.css';

class ModulesAdded extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            noticeContent: this.props.noticeContent,
            doc: this.props.doc,
            expired: this.props.doc && this.props.doc.expiration_date ? new Date().getTime() > new Date(this.props.doc.expiration_date).getTime() : false,
            moduleDocs: [],
            hasQuestions: false,
            questionsChecked: false,
            moduleDone: false
        };
        this.getModuleData = this.getModuleData.bind(this);
        this.getUrlSegment = this.getUrlSegment.bind(this);
        this.getLabel = this.getLabel.bind(this);
    }
    componentDidMount() {
        this.getModuleData();
    }
    getModuleData() {
        if (this.state.doc.original_id) {
            this.setState({loading: true});
            axios({
                method: "get",
                url: "/api/getModuleProgressData/" + this.state.doc.original_id,
            }).then(res => {
                const data = res.data.result;
                this.setState({moduleDone: res.data.moduledone});
                this.setState({moduleDocs: data});
                this.setState({loading: false});
            })
            axios({
                method: "get",
                url: "/api/getModuleQuestionsCount/" + this.state.doc.original_id,
            }).then(res => {
                const hasQuestions = !!res && !!res.data && !!res.data.result && !!res.data.result.length && !!res.data.result[0].count;
                this.setState({
                    hasQuestions: hasQuestions,
                    questionsChecked: true
                });
            })
        }
    }

    getUrlSegment(content_type) {
        let segment = null;
        switch (content_type) {
            case "journal":
                segment = "journals";
                break;
            case "presentation":
                segment = "presentations";
                break;
            case "podcast":
                segment = "podcasts";
                break;
            case "self-assessment program for maintenance of certification":
                segment = "sapmoc";
                break;
            case "self-assessment test":
                segment = "satest";
                break;
            case "module":
                segment = "modules";
                break;
        }
        return segment;
    }

    getLabel(content_type) {
        let segment = null;
        switch (content_type) {
            case "journal":
                segment = "Journal";
                break;
            case "presentation":
                segment = "Presentation";
                break;
            case "self-assessment program for maintenance of certification":
                segment = "Self-Assessment Program for Maintenance of Certification";
                break;
            case "podcast":
                segment = "Podcast";
                break;
            case "self-assessment test":
                segment = "Self-Assessment Test";
                break;
            case "module":
                segment = "Module";
                break;
        }
        return segment;
    }

    render() {
        let questionsComplete = (this.state.questionsChecked && !this.state.hasQuestions) || (!!this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && !!this.state.doc.modulerecord[0].questions_complete);
        let evaluationComplete = !!this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && !!this.state.doc.modulerecord[0].evaluation_complete;
        let mocSubmitted = !!this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && !!this.state.doc.modulerecord[0].moc_submitted;
        return (
            <div>
                <div className="row">
                    {!!!this.state.doc.modulerecord.length && <div className="col-xs-12">
                        <ModuleNotice noticeContent={this.state.noticeContent} doc={this.state.doc} moduleid={this.state.doc.original_id} />
                </div>}
                    {!!this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && !!!this.state.doc.modulerecord[0].notice && <div className="col-xs-12">
                        <ModuleNotice noticeContent={this.state.noticeContent} doc={this.state.doc} moduleid={this.state.doc.original_id} />
                </div>}
                    {!!this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && !!this.state.doc.modulerecord[0].notice && <div className="col-xs-12">
                        <h2 className="text-lg text-600">Module Progress</h2>
                        <table class="table table-bordered"><tbody>
                        { this.state.moduleDocs.map((doc, i) => (
                            <tr>
                                <th scope="row">{i+1}</th>
                                <td className="text-uc text-muted">{this.getLabel(doc.education_media_type)}</td>
                                <td><Link className="text-no-underline text-600" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>{doc.title}</Link></td>
                                <td>
                                    {doc.education_media_type === "journal" && !!!doc.mvid && <span><Link className="btn btn-sm btn-secondary" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>Start (Must go to Article)</Link></span>}
                                    {doc.education_media_type === "journal" && !!doc.mvid && <><div className="btn btn-sm btn-secondary">Complete</div></>}
                                    {!!!doc.portfolio && doc.education_media_type === "presentation" && <span><Link className="btn btn-sm btn-secondary" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>Start</Link></span>}
                                    {!!doc.userrecord && doc.education_media_type === "self-assessment program for maintenance of certification" && !!!(doc.userrecord["eval" + doc.original_id] === "done") && <span>Not Complete</span>}
                                    {!!doc.userrecord && doc.education_media_type === "self-assessment program for maintenance of certification" && !!(doc.userrecord["eval" + doc.original_id] === "done") && <span>Complete</span>}
                                    {!!doc.userrecord && doc.education_media_type === "self-assessment test" && !!!(doc.userrecord["eval" + doc.original_id] === "done") && <span>Not Complete</span>}
                                    {!!doc.userrecord && doc.education_media_type === "self-assessment test" && !!(doc.userrecord["eval" + doc.original_id] === "done") && <span>Complete</span>}
                                    {!!doc.portfolio && doc.education_media_type === "presentation" && !!!doc.portfolio.percentage_complete && <Link className="btn btn-sm btn-secondary" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>Start</Link>}
                                    {!!doc.portfolio && doc.education_media_type === "presentation" && !!doc.portfolio.percentage_complete && doc.portfolio.percentage_complete > 0 && doc.portfolio.percentage_complete < 90 && <><Link className="btn btn-sm btn-secondary" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>Continue</Link> - <span>Percentage Complete: {doc.portfolio.percentage_complete}%</span></>}
                                    {!!doc.portfolio && doc.education_media_type === "presentation" && !!doc.portfolio.percentage_complete && doc.portfolio.percentage_complete > 0 && doc.portfolio.percentage_complete >= 90 && <Link className="btn btn-sm btn-secondary" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>Complete</Link>}
                                    {!!!doc.podcastProgress && doc.education_media_type === "podcast" && <Link className="btn btn-sm btn-secondary" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>Start</Link>}
                                    {!!doc.podcastProgress && doc.education_media_type === "podcast" && doc.podcastProgress.percentage_complete > 0 && doc.podcastProgress.percentage_complete < 90 && <><Link className="btn btn-sm btn-secondary" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>Continue</Link> - <span>Percentage Complete: {doc.podcastProgress.percentage_complete}%</span></>}
                                    {!!doc.podcastProgress && doc.education_media_type === "podcast" && doc.podcastProgress.percentage_complete > 0 && doc.podcastProgress.percentage_complete >= 90 && <><Link className="btn btn-sm btn-secondary" to={`/${this.getUrlSegment(doc.education_media_type)}/${doc.content_id}/module/${this.state.doc.content_id}`}>Complete</Link></>}
                                </td>
                            </tr>
                        ))}
                        </tbody></table>
                    </div>}
                </div>
                {this.state.hasQuestions && !!this.state.moduleDone && (!!this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && !!!this.state.doc.modulerecord[0].questions_complete) && <div className="row">
                    <div className="p-t col-xs-12">
                        <p><a class="btn btn-primary" href={`/modules/${this.state.doc.content_id}/questions`}>Next Step: Questions</a></p>
                    </div>
                </div>}
                {this.state.hasQuestions && !!this.state.moduleDone && (!!this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && !!this.state.doc.modulerecord[0].questions_complete) && <div className="row">
                    <div className="p-t col-xs-12">
                        <p><a class="btn btn-primary" href={`/modules/${this.state.doc.content_id}/questions`}>Review Questions</a></p>
                    </div>
                </div>}
                {!!this.state.moduleDone && (questionsComplete && !evaluationComplete) && <div className="row">
                    <div className="p-t col-xs-12">
                        <p><a class="btn btn-primary" href={`/modules/${this.state.doc.content_id}/evaluation`}>Next Step: Evaluation</a></p>
                    </div>
                </div>}
                {!!this.state.moduleDone && (questionsComplete && evaluationComplete) && <div className="row">
                    <div className="p-t col-xs-12">
                        <p>{this.state.doc && !!this.state.doc.actual_cme_value && <a target="_blank" rel="noopener noreferrer" class="btn btn-primary" href={`/api/modules/${this.state.doc.content_id}/certificate.pdf`}>Complete: Print Your CME Certificate</a>} <a target="_blank" rel="noopener noreferrer" class="btn btn-primary" href={`/api/modules/${this.state.doc.content_id}/certificate_completion.pdf`}>Complete: Print Your Certificate of Completion</a></p>
                    </div>
                </div>}
                {!this.state.expired && !!this.state.doc.moc_value && !!this.state.moduleDone && (questionsComplete && evaluationComplete && !mocSubmitted) && <div className="row">
                    <div className="p-t col-xs-12">
                        <p><a class="btn btn-primary" href={`/modules/${this.state.doc.content_id}/mocsubmit`}>Submit for MOC points</a></p>
                    </div>
                </div>}
                {this.state.expired && !!this.state.doc.moc_value && !!this.state.moduleDone && (questionsComplete && evaluationComplete && !mocSubmitted) && <div className="row">
                    <div className="p-t col-xs-12">
                        <p>Expired for MOC points</p>
                    </div>
                </div>}
                {!!this.state.doc.moc_value && !!this.state.moduleDone && (questionsComplete && evaluationComplete && mocSubmitted) && <div className="row">
                    <div className="p-t col-xs-12">
                        <p>MOC Submitted.</p>
                    </div>
                </div>}
            </div>
        )
    }
}

export default ModulesAdded;
