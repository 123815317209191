import React, { Component } from 'react';
import axios from 'axios';
import Icon from "./Icon.js";
import SATestQuestionAnswersComplete from './SATestQuestionAnswersComplete.js';
import SATestQuestionAnswersIncomplete from './SATestQuestionAnswersIncomplete.js';
import qs from 'query-string';
import Spinner from 'react-spinkit';
import SATestSideBox from './SATestSideBox.js';
import SATestDoneMessage from './SATestDoneMessage.js';

class SATestQuestions extends Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: {},
            passedCategory: this.props.location && this.props.location.search && this.props.location.search.indexOf("category") > -1 ? qs.parse(this.props.location.search).category : "",
            saCompleteReady: false,
            showMessage: false,
            submitting: false,
            selectedAnswer: null,
            correct: false,
            questionLoaded: false,
            question: {
                question: {
                    Question: ''
                }
            }
        };
        this.selectAnswer = this.selectAnswer.bind(this)
        this.submitAnswer = this.submitAnswer.bind(this)
        this.saCompleteReady = this.saCompleteReady.bind(this);
        this.getData = this.getData.bind(this)
    }
    saCompleteReady() {
        this.setState({saCompleteReady: true});
    }
    selectAnswer(e) {
        this.setState({selectedAnswer: parseInt(e.target.value, 10)});
    }
    submitAnswer(e) {
        if (this.state.selectedAnswer) {
            this.setState({submitting: true});
            axios({
                method: "post",
                url: "/api/submitSATestAnswer",
                data: {
                    answer: this.state.selectedAnswer,
                    question: this.state.question.question.actualid,
                    testid: this.state.doc.original_id
                }
            }).then(res => {
                this.setState({showMessage: true});
                this.setState({correct: res.data.correct});
                if (res.data.finished) {
                    window.location.reload();
                }
                this.getData(this.state.question.question.actualid);
                window.scrollTo(0, 0);
            })
        }
    }
    getData(qid) {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.tid,
        }).then(res => {
            this.setState({doc: res.data.result[0]});
            let questionId = this.props.match.params.qid || qid || null;
            axios({
                method: "get",
                url: !questionId ? "/api/getSATestQuestion/" + this.props.match.params.tid + "?category=" + this.state.passedCategory : "/api/getSATestQuestion/" + this.props.match.params.tid + "/" + questionId,
            }).then(res => {
                this.setState({questionLoaded: true, question: res.data.data});
                this.setState({submitting: false});
            })
        })
    }
    componentDidMount() {
        this.getData();
    }

    render() {
        console.log("rendering")
        let next,
            answers,
            nextPrevious,
            questionStatus = "",
            previous;
        if (!this.state.question.previousQuestion) {
            previous = "";
        } else {
            previous = (<a href={`/satest/${this.props.match.params.tid}/questions/${this.state.question.previousQuestion}`} className="btn btn-sm btn-primary">Previous</a>);
        }
        if (!this.state.question.nextQuestion) {
            next = "";
        } else {
            next = (<a href={`/satest/${this.props.match.params.tid}/questions/${this.state.question.nextQuestion}`} className="btn btn-sm btn-primary">Next</a>);
        }
        questionStatus = this.state.showMessage ? this.state.correct ? "That answer is correct." : 'Sorry, that answer is incorrect.' : ""
        answers = this.state.submitting ? <div class="spinner-wrap"><Spinner name='double-bounce' /></div> : this.state.question.question.complete ? <SATestQuestionAnswersComplete question={this.state.question.question} /> : this.state.questionLoaded ? <SATestQuestionAnswersIncomplete question={this.state.question.question} submitAnswer={this.submitAnswer} selectAnswer={this.selectAnswer} /> : <div />;
        nextPrevious = (
                <div className="row p-t">
                    <div className="col-md-3 col-xs-12">
                        {previous}
                    </div>
                    {this.state.question.categories && <div className="col-xs-6 text-center text-uppercase">
                        {this.state.question.question.Category} category {this.state.question.question.rankcat} of {this.state.question.categories[this.state.question.question.Category].numquescat}
                    </div>}
                    <div className="col-xs-3 text-right">
                        {next}
                    </div>
                </div>
        );
        return (
            <div className="row">
                <div className="col-md-8 col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href="/account">
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back</span>
                        </a>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <h1 class="m-b-md">{this.state.doc.title}</h1>
                        </div>
                    </div>
                    {!!this.state.doc.original_id && <SATestDoneMessage saCompleteReady={this.saCompleteReady} doc={this.state.doc} />}
                    {!!this.state.showMessage && !this.state.submitting && <div className={`alert alert-top alert alert-${!!this.state.correct ? 'success' : 'danger'}`}>
                        <strong>{questionStatus}</strong>
                    </div>}
                    {this.state.saCompleteReady && nextPrevious}
                    {this.state.saCompleteReady && !!this.state.question && !!this.state.question.question && !!this.state.question.question.Category && this.state.question.categories[this.state.question.question.Category].numquescat === this.state.question.categories[this.state.question.question.Category].numcomplete && <div class="alert alert-success">You have completed this category.</div>}
                    {this.state.saCompleteReady && <p className="text-lg m-y" dangerouslySetInnerHTML={{__html: this.state.question.question.Question}} />}
                    {this.state.saCompleteReady && answers}
                    {this.state.saCompleteReady && nextPrevious}
                </div>
                <div className="col-md-4 col-xs-12">
                    {!!this.state.doc.original_id && <SATestSideBox doc={this.state.doc} question={this.state.question} />}
                </div>
            </div>
        )
    }
}

export default SATestQuestions;
