import React from 'react';
import { Redirect, Link } from "react-router-dom";
import Icon from "./Icon.js";
import axios from 'axios';
import './DocumentListing.css';
export default class DocumentListing extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            redirect: null,
            doc: this.props.doc,
            key: this.props.key
        };
        this.getPath = this.getPath.bind(this);
        this.addToAccount = this.addToAccount.bind(this);
        this.removeHTMLShorten = this.removeHTMLShorten.bind(this);
        this.removeFromAccount = this.removeFromAccount.bind(this);
    }
    removeHTMLShorten(str) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        var returnText = tmp.textContent || tmp.innerText || "";

        return returnText && returnText.length && returnText.length > 130 ? returnText.slice(0, 130) + "..." : returnText.slice(0, 130);
    }
    removeFromAccount(e) {
        if (this.state.doc.education_media_type === "module") {
            return;
        }
        axios({
            method: "post",
            url: "/api/removeFromAccount",
            data: {
                contentId: this.state.doc.content_id
            }
        }).then(res => {
            console.log(res);
            console.log(res.data);
            this.state.doc.inAccount = false;
            this.state.doc.accountRemovable = false;
            this.setState({doc: this.state.doc});
            if (this.props.getData && typeof this.props.getData === "function") {
                this.props.getData();
            } 
        })
    }
    addToAccount(e) {
        if (this.state.doc.inAccount) {
            console.log("should redirect");
            this.setState({ redirect: "/account" });
        } else {
            axios({
                method: "post",
                url: this.state.doc.education_media_type === "module" ? "/api/addModuleToAccount" : "/api/addToAccount",
                data: {
                    contentId: this.state.doc.content_id
                }
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.state.doc.inAccount = true;
                this.setState({doc: this.state.doc});
            })
        }
    }
    getPath() {
        let firstPart = this.state.doc.education_media_type === "podcast" ? `/podcasts/${this.state.doc.content_id}` : this.state.doc.education_media_type === "self-assessment program for maintenance of certification" ? `/sapmoc/${this.state.doc.content_id}` : this.state.doc.education_media_type === "journal" ? `/journals/${this.state.doc.content_id}` : this.state.doc.education_media_type === "self-assessment test" ? `/satest/${this.state.doc.content_id}` : this.state.doc.education_media_type === "presentation" ? `/presentations/${this.state.doc.content_id}` : `/modules/${this.state.doc.content_id}`,
            secondPart = this.props.mode === "modules" ? `/module/${this.props.parentdoc.content_id}` : "";
        return firstPart + secondPart;
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        //console.log(this.state)
        let title,
            infoBlock,
            infoBar,
            wrapClass,
            wrapClass2,
            wrapClass3;

        title = this.state.doc.education_media_type === "podcast" ? (<a href={this.getPath()} dangerouslySetInnerHTML={{__html: this.state.doc.title}} />) :
            this.state.doc.education_media_type === "self-assessment program for maintenance of certification" ? (<a href={this.getPath()} dangerouslySetInnerHTML={{__html:this.state.doc.title}} />) :
            this.state.doc.education_media_type === "journal" ? (<a href={this.getPath()} dangerouslySetInnerHTML={{__html:this.state.doc.title}} />) :
            this.state.doc.education_media_type === "self-assessment test" ? (<a href={this.getPath()} dangerouslySetInnerHTML={{__html:this.state.doc.title}} />) :
            this.state.doc.education_media_type === "presentation" ? (<a href={this.getPath()} dangerouslySetInnerHTML={{__html:this.state.doc.title}} />) :
            (<a href={this.getPath()} dangerouslySetInnerHTML={{__html:this.state.doc.title}} />);


        /*
        if (this.props.mode === "account") {
        } else {
            title = (<span>{this.state.doc.title}</span>);
        }
        */
        infoBlock = (
                <div class="bg-gray-lightest item-flex m-y text-xxs">
                    <div class="border-1 border-r border-gray-light p-a-sm text-center line-height-1 flex-1">
                        <div class="text-md text-600 m-b-xs">{this.state.doc.moc_value}</div>
                        ABIM MOC
                    </div>
                    <div class="border-1 border-gray-light p-a-sm text-center line-height-1 flex-1">
                        <div class="text-md text-600 m-b-xs">{this.state.doc.actual_cme_value}</div>
                        CME
                    </div>
                    {this.state.doc.education_media_type === "podcast" && <div class="border-l border-1 border-gray-light p-a-sm text-center line-height-1 flex-1">
                        <div class="text-md text-600 m-b-xs">{this.state.doc.cme_value}</div>
                        MIN
                    </div>}
                    {!!this.state.doc.inAccount && <div class="border-l border-1 border-gray-light p-a-sm text-center line-height-1 flex-1 text-secondary text-600">
                        <div><Icon outerClass="icon icon-md text-secondary" passedClass="icon-star" /></div>Saved
                    </div>}
                </div>
        );
        if (this.state.doc.education_media_type === "journal") {
            infoBar = (
                <>
                <div>
                    <span className="label label-default">Journal</span>
                    {infoBlock}
                </div>
                <div className="text-gray text-xs">Author(s): {this.removeHTMLShorten(this.state.doc.authors)}</div>
                <div className="text-gray text-xs">Volume {this.state.doc.journal_volume}, Issue {this.state.doc.journal_issue}</div>
                </>
            )
        } else if (this.state.doc.education_media_type === "module") {
            infoBar = (
                <div>
                    <span className="label label-default">Module</span>
                    {infoBlock}
                </div>
            )
        } else if (this.state.doc.education_media_type === "podcast") {
            infoBar = (
                <div>
                    <span className="label label-default">Podcast</span>
                    {infoBlock}
                </div>
            )
        } else if (this.state.doc.education_media_type === "presentation") {
            infoBar = (
                <>
                <div>
                    <span className="label label-default">Presentation</span>
                    {infoBlock}
                </div>
                <div className="text-gray text-xs">Presenter(s): <span dangerouslySetInnerHTML={{__html: this.state.doc.authors}} /></div>
                <div className="text-gray text-xs">Event: <a href={`/search?events=${this.state.doc.event_id}`}>{this.state.doc.event_name}</a></div>
                </>
            )
        } else if (this.state.doc.education_media_type === "self-assessment test") {
            infoBar = (
                <div>
                    <span className="label label-default">Self-Assessment Test</span>
                    {infoBlock}
                </div>
            )
        } else if (this.state.doc.education_media_type === "self-assessment program for maintenance of certification") {
            infoBar = (
                <div>
                    <span className="label label-default">SAPMOC</span>
                    {infoBlock}
                </div>
            )
        }
        wrapClass = this.props.format === "grid" ? "col-md-4 col-xs-12 display-flex flex-column" : "m-b p-b border-b border-1 border-gray-light";
        wrapClass2 = this.props.format === "grid" ? "card flex-1 display-flex flex-column" : "";
        wrapClass3 = this.props.format === "grid" ? "card-block" : "";
        return (
            <div className={wrapClass}>
                <div className={wrapClass2} key={this.state.key}>
                    <div className={wrapClass3}>
                        <h2 className="text-md m-b-sm text-600">{title}</h2>
                        {infoBar}
                    </div>
                    <div class="m-t-auto card-footer p-r-sm">
                        <div class="documenttags text-sm">
                            { !!this.state.doc && !!this.state.doc.tags && !!this.state.doc.tags.length && this.state.doc.tags.map((tag, i) => (<a onClick={(event => {if (this.props.mode !== "account" && this.props.mode !== "modules" && this.props.mode !== "complete") {this.props.handleTagLink(tag); event.preventDefault();}})} href={`/search?tag=${tag.tag}`} class="label label-primary br-a-sm m-b-xs m-r-xs">{tag.name}</a> ))}
                        </div>
                        <div class="item-flex">
                            {this.props.mode !== "account" && this.props.mode !== "modules" && this.props.mode !== "complete" && this.props.user !== "anonymous" && <button onClick={(event) => this.addToAccount(event)} className={`${this.state.doc.inAccount ? 'btn-success-outline': 'btn-outline'} btn btn-sm btn-pill br-a-sm m-l-auto`}>{this.state.doc.inAccount ? <Icon passedClass="icon-check" /> : <Icon passedClass="icon-plus" />} {`${this.state.doc.inAccount ? "In your Account" : "Add to account"}`}</button>}
                            {this.props.mode !== "account" && this.props.mode !== "modules" && this.props.mode !== "complete" && this.props.user !== "anonymous"  && this.state.doc.accountRemovable && <button onClick={(event) => { if (window.confirm('Are you sure you wish to remove this item?')) this.removeFromAccount(event) } } className={`btn-danger-outline btn btn-sm btn-pill br-a-sm m-l-sm`}><Icon passedClass="icon-minus" /> Remove</button>}
                            {this.props.mode === "account" && <a href={`${this.getPath()}`} class="btn-success btn btn-sm br-a-sm m-l-auto">{this.state.doc.itemComplete ? "Completed" : "Continue"}</a>}
                            {this.props.mode === "account" && this.state.doc.accountRemovable && <button onClick={(event) => { if (window.confirm('Are you sure you wish to remove this item?')) this.removeFromAccount(event) } }href={`${this.getPath()}`} class="btn-danger btn btn-sm br-a-sm m-l-sm">Remove</button>}
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "journal" && !!this.state.doc.journaltestcomplete && !!!this.state.doc.journalevalcomplete && <a href={`/journals/${this.state.doc.content_id}/evaluation`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto"><Icon passedClass="icon-file" /> Evaluation</a>}
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "journal" && !!this.state.doc.journaltestcomplete && !!this.state.doc.journalevalcomplete && <a href={`/api/journal/${this.state.doc.content_id}/certificate.pdf`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto" target="_blank" rel="noopener noreferrer"><Icon passedClass="icon-file" /> CME Certificate</a>}
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "presentation" && !!!this.state.doc.actual_cme_value && <a href={`/presentations/${this.state.doc.content_id}`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto"><Icon passedClass="icon-file" /> Review</a>}
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "podcast" && <a href={`/podcasts/${this.state.doc.content_id}`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto"><Icon passedClass="icon-file" /> Review</a>}
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "presentation" && !!this.state.doc.actual_cme_value && !!!this.state.doc.presentationevalcomplete && <a href={`/presentations/${this.state.doc.content_id}/evaluation`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto"><Icon passedClass="icon-file" /> Evaluation</a>}
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "presentation" && !!this.state.doc.actual_cme_value && !!this.state.doc.presentationevalcomplete && <a href={`/api/presentations/${this.state.doc.content_id}/certificate.pdf`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto" target="_blank" rel="noopener noreferrer"><Icon passedClass="icon-file" /> CME Certificate</a>}
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "module" && !!this.state.doc.actual_cme_value && !!this.state.doc.moduleevalcomplete && <a href={`/api/modules/${this.state.doc.content_id}/certificate.pdf`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto" target="_blank" rel="noopener noreferrer"><Icon passedClass="icon-file" /> CME Certificate</a>}
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "self-assessment test" && !!this.state.doc.satestcomplete && !!this.state.doc.satestevalcomplete && <a href={`/api/satest/${this.state.doc.content_id}/certificate.pdf`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto" target="_blank" rel="noopener noreferrer"><Icon passedClass="icon-file" /> CME Certificate</a>}
                        </div>
                        {this.props.mode === "complete" && this.state.doc.education_media_type === "module" && !!this.state.doc.modulecomplete && <div class="item-flex">
                            {this.props.mode === "complete" && this.state.doc.education_media_type === "module" && !!this.state.doc.modulecomplete && <a href={`/api/modules/${this.state.doc.content_id}/certificate_completion.pdf`} className="btn btn-sm btn-primary-outline btn-pill br-a-sm m-l-auto m-t-sm" target="_blank" rel="noopener noreferrer"><Icon passedClass="icon-file" /> Certificate of Completion</a>}
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}
