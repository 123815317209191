import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FormErrors } from './FormErrors';
import DatePicker from "react-datepicker";
import { SelectOptions } from './SelectOptions';
import Spinner from 'react-spinkit';
import axios from 'axios';

const initialState = {
    doc: {},
    contentQuestionsPost: [],
    contentQuestions: [],
    symposia: []
};
class AdminContentDetail extends Component {
    constructor (props) {
        super(props);
        console.log(this.props.match.params.contentid);
        this.state = initialState;
    }
    componentDidMount() {
        if (this.props.match.params.contentid) {
            axios({
                method: "get",
                url: "/api/admin/getContent/" + this.props.match.params.contentid,
            }).then(res => {
                console.log(res);
                console.log(res.data);
                const data = res.data.result[0];
                console.log(data)
                this.setState({doc: data});
            })
            axios({
                method: "get",
                url: "/api/admin/getSymposia"
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.setState({ symposia: res.data.result });
            })
            axios({
                method: "get",
                url: "/api/admin/getContentQuestions/" + this.props.match.params.contentid,
            }).then(resq => {
                console.log(resq);
                console.log(resq.data);
                const questions = resq.data.result;
                this.setState({contentQuestions: questions});
                this.setState({loading: false});
            })
            axios({
                method: "get",
                url: `/api/admin/getContentQuestions/${this.props.match.params.contentid}?type=post`,
            }).then(resq => {
                console.log(resq);
                console.log(resq.data);
                const questions = resq.data.result;
                this.setState({contentQuestionsPost: questions});
                this.setState({loading: false});
            })
        }
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {
            if (name === "SymposiaOrganization") {
                axios({
                    method: "post",
                    url: "/api/admin/setSymposia",
                    data: {
                        content_id: this.state.doc.content_id,
                        symposia_organization: this.state.SymposiaOrganization
                    }
                }).then(res => {
                    console.log(res);
                    console.log(res.data);
                    let doc = this.state.doc;
                    doc.symposia_organization = this.state.SymposiaOrganization;
                    this.setState({ doc });
                })
            }
        });
    }
    render() {
        console.log(this.state)
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <p><a href="/admin/content">Return to Content</a></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h2>{this.state.doc.title}</h2>
                    </div>
                </div>
                <fieldset class="form-group">
                    <label for="SymposiaOrganization">Symposia Organization</label>
                    <select onChange={(event) => this.handleUserInput(event)} className="form-control" name="SymposiaOrganization" value={this.state.doc.symposia_organization}>
                        <option value="">Symposia Organization...</option>
                        {this.state.symposia.map((fieldName, i) => <SelectOptions key={i+fieldName.organizationName} fieldName={fieldName.organizationName} value={fieldName.id} />)}
                    </select>
                </fieldset>
                {this.props.match.params.contentid && <>
                <div className="row">
                    <div className="col-xs-12">
                        <hr />
                        <h3 className="p-t-lg">Pre Questions (shown to user before presentation)</h3>
                        <p><Link to={`/admin/content/${this.props.match.params.contentid}/question`}>Add New Pre Content Question</Link></p>
                        <ul>
                        { this.state.contentQuestions.map((question, i) => (<li><Link to={`/admin/content/${this.props.match.params.contentid}/question/${question.ID}`} dangerouslySetInnerHTML={{__html: question.Question}} /></li>))}
                        </ul>
                        <hr />
                    </div>
                </div></>}
                {this.props.match.params.contentid && <>
                <div className="row">
                    <div className="col-xs-12">
                        <hr />
                        <h3 className="p-t-lg">Post Questions (shown to user after presentation)</h3>
                        <p><Link to={`/admin/content/${this.props.match.params.contentid}/question?type=post`}>Add New Post Content Question</Link></p>
                        <ul>
                        { this.state.contentQuestionsPost.map((question, i) => (<li><Link to={`/admin/content/${this.props.match.params.contentid}/question/${question.ID}`} dangerouslySetInnerHTML={{__html: question.Question}} /></li>))}
                        </ul>
                        <hr />
                    </div>
                </div></>}
            </div>
        )
    }
}

export default AdminContentDetail;
