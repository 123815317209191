import React, { Component } from 'react';

class ModuleSideBox extends Component {
    render() {
        return (
            <div className="bg-gray-lightest p-a">
                <div className="card card-highlight card-sm card-block text-sm sg-usage">
                    <h3 className="text-lg">Test Progress</h3>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <td>Questions</td>
                                <td className="text-right"><span className="label label-pill label-info">{this.props.question.totalQuestions}</span></td>
                            </tr>
                            <tr>
                                <td>Completed</td>
                                <td className="text-right"><span className="label label-pill label-info">{this.props.question.completeQuestions}</span></td>
                            </tr>
                            <tr>
                                <td>Score So Far</td>
                                <td className="text-right"><span className="label label-pill label-secondary">{Math.round((this.props.question.completeQuestions/this.props.question.totalQuestions) * 100)}%</span></td>
                            </tr>
                            <tr>
                                <td>Remaining</td>
                                <td className="text-right"><span className="label label-pill label-warning">{this.props.question.totalQuestions - this.props.question.completeQuestions}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ModuleSideBox;
