import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';

class SATestModal extends Component {
    constructor (props) {
        super(props);
        console.log(props);
        this.state = {
            isOpen: false,
            doc: null,
            modalContent: "",
            linkTypes: {
                accreditation: "Accreditation/CME Information",
                description: "Self-Assessment Examination Description and Objectives",
                subcommittee: "2020 Self-Assessment Test Subcommittee",
                faculty: "Faculty/Contributors",
                technical: "Technical Requirements",
                privacypolicy: "Privacy Policy",
                contactus: "Contact Us"
            },
            staticContent: {
                technical: "<p>This site is best viewed using Internet Explorer 6 or higher, FIrefox 2 or higher, Chrome or Safari. To view graphic images and references that appear in  separate \"pop-up\" windows, you must have Javascript enabled on your  computer.</p>",
                privacypolicy: "<p>The American College of Gastroenterology respects your privacy. Personal  information collected from participants of our online continuing medical  education activities will not be shared with any third party. Personal  information is collected through our online CME activities when an online  user completes registration and provides a name, address, e-mail address,  and other personally identifying information. This information is stored  in a database so that the College may track the number of people using  the web site to obtain CME credit. This record-keeping is required by  the Accreditation Council for Continuing Medical Education. Personal information  including names and e-mail addresses may be requested in order to receive  e-mail alerts or other information from the College about the website  or other educational activities. We do not distribute unsolicited e-mails,  nor do we share the names of our subscribers with any third party. The  Online CME web site does not use any type of system (eg, cookies) to track  a user's movements throughout the site. We do analyze our computer log  file to determine which pages are visited most often and which external  web sites refer the most visitors to acg.gi.org. The College performs  periodic basic demographic analyses, including optional web site user  surveys, for the express purpose of tailoring future online CME activities  to meet the greatest need and interest.</p>",
                contactus: "<p>American College of  Gastroenterology<br>11333 Woodglen Dr, STE 100<br>North Bethesda, MD 20852-3071<br>(301) 263-9000</p>"
            }
        };
        this.getData = this.getData.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }
    componentDidMount() {
        if (this.props.linkType !== "technical" && this.props.linkType !== "privacypolicy" && this.props.linkType !== "contactus") {
            this.getData();
        }
        let linkTypes = this.state.linkTypes;
        this.setState({doc: this.props.doc}, () => {
            linkTypes.subcommittee = `${this.state.doc.title.substring(0, 4)} Self-Assessment Test Subcommittee`;
            this.setState({linkTypes: linkTypes});
        });
    }
    showModal() {
        this.setState({isOpen: true});
    };

    hideModal() {
        this.setState({isOpen: false});
    };
    getData() {
        axios({
            method: "get",
            url: `/api/importantinfo/satest/${this.props.linkType}/${this.props.testid}`,
        }).then(res => {
            console.log(res)
            let staticContent = {...this.state.staticContent};
            staticContent[this.props.linkType] = res.data;
            this.setState({staticContent});
        })
    }
    render() {
        return (
            <>
                <a data-toggle="modal" data-target={`.bs-example-modal-lg-${this.props.linkType}`}>{this.state.linkTypes[this.props.linkType]}</a>
                <div className={`modal bs-example-modal-lg-${this.props.linkType}`} tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="text-right">
                                    <button type="button" class="circle bg-white text-primary text-xl" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div class="container p-x p-b">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="modal-con">
                                                <h4>{this.state.linkTypes[this.props.linkType]}</h4>
                                                <span dangerouslySetInnerHTML={{__html: this.state.staticContent[this.props.linkType]}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SATestModal;
