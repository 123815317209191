import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from 'axios';
import Icon from "./Icon.js";
import TestSideBox from './TestSideBox.js';
import "react-datepicker/dist/react-datepicker.css";

class SAPMOCMOCSubmit extends Component {
    constructor (props) {
        super(props);
        const initialState = {
            formErrors: {},
            serverErrors: [],
            form: {
                abimid: null,
                birthDate: new Date(),
                fname: null,
                lname: null
            },
            formValid: false,
            doc: {},
            formErrors: {},
            requiredFields: [
                "abimid",
                "fname",
                "lname"
            ],
        };
        this.state = initialState;
        this.selectAnswer = this.selectAnswer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getBirthdate = this.getBirthdate.bind(this);
    }
    handleDateChange(date) {
        let form = this.state.form;
        form.birthDate = date;
        this.setState({form: form}, () => {console.log(this.state.form)});
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        let form = this.state.form;
        console.log(name, value, form)
        form[name] = value;
        this.setState({form: form}, () => {
            this.validateField(name, value)
        });
    }
    selectAnswer(question, answer) {
        this.setState({[question]: answer});
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.jid,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const data = res.data.result[0];
            this.setState({doc: data});
        })
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        if (this.state.requiredFields.indexOf(fieldName) > -1) {
            fieldValidationErrors[fieldName] = value && value.length > 0 ? '' : ' is required';
            console.log(value, fieldValidationErrors)
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm(callback) {
        let valid = true;
        for (var field in this.state.formErrors) {
            if (this.state.formErrors[field].length) {
                valid = false;
            }
        }
        this.setState({
            formValid: valid
        }, () => {
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    getBirthdate() {
        var dd = this.state.form.birthDate.getDate(),
            mm = this.state.form.birthDate.getMonth() + 1,
            yyyy = this.state.form.birthDate.getFullYear();

        return mm + "/" + dd + "/" + yyyy;
    }

    handleSubmit = event => {
        event.preventDefault();
        console.log(this.state);
        this.state.requiredFields.forEach(field => {
            this.validateField(field, this.state.form[field]);
        });
        var docBody = {
            fname: this.state.form.fname,
            lname: this.state.form.lname,
            abim_id: this.state.form.abimid,
            birthdate: this.getBirthdate(),
            content_id: this.state.doc.content_id
        };
        this.validateForm(() => {
            if (this.state.formValid) {
                axios({
                    method: "post",
                    url: "/api/submitMOC",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: docBody
                }).then(res => {
                    console.log(res)
                    const answ = res.data;
                    if (answ.error) {
                        if (answ.errorMessages) {
                            let errpass = [];
                            answ.errorMessages.forEach((item) => {
                                errpass.push(item.Message);
                            });
                            this.setState({serverErrors: errpass});
                        }
                    } else {
                    }
                    //window.location.reload();
                }, (err) => {
                });
            }
        });
    }
    render() {
        //if (!!!this.state.doc.parsId || !!!this.state.doc.journalrecord[0].evalcomplete) {
        //    return <Redirect to={`/journals/${this.props.match.params.jid}`} />
        //}
        if ((this.state.doc.journalrecord && !!!this.state.doc.journalrecord.length) || (this.state.doc.journalrecord && !!this.state.doc.journalrecord.length && !!!this.state.doc.journalrecord[0].evalcomplete) || (this.state.doc.journalrecord && !!this.state.doc.journalrecord.length && !!this.state.doc.journalrecord[0].mocsubmitted)) {
            return <Redirect to={`/journals/${this.props.match.params.jid}`} />
        }
        return (
            <div className="row">
                <div className="col-md-4 col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href="/account">
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back</span>
                        </a>
                    </div>
                    <h2>MOC Submission for {this.state.doc.title}</h2>
                    <p><strong>To receive ABIM MOC credit, complete the information below and click Submit.</strong></p>
                    <form onSubmit={this.handleSubmit} className="evalform">
                        <div className="p-a-md" />
                        {!this.state.formValid && Object.entries(this.state.formErrors).length > 0 && <div className="alert alert-info"><ul>{Object.entries(this.state.formErrors).map((key, value) => {if (key[1].length > 0) return (<li>{key}</li>)})}</ul></div>}
                        {this.state.serverErrors.length > 0 && <div className="alert alert-warning"><ul>{this.state.serverErrors.map((item) => {return (<li>{item}</li>)})}</ul></div>}
                        <div className="row">
                            <div className="col-xs-12">
                                MOC Points: 1
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <label for="fname">First Name</label>
                                <input id="fname" name="fname" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.form.fname} />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <label for="lname">Last Name</label>
                                <input id="lname" name="lname" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.form.lname} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <label for="abimid">ABIM ID</label>
                                <input id="abimid" name="abimid" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.form.abimid} />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <label>Date of Birth</label>
                                <div><DatePicker selected={this.state.form.birthDate} onChange={this.handleDateChange} /></div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
                <div className="col-md-4 col-xs-12">
                    <TestSideBox doc={this.state.doc} question={this.state.question} />
                </div>
            </div>
        )
    }
}

export default SAPMOCMOCSubmit;
