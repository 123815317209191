import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import BackButton from "./BackButton.js";
import Icon from "./Icon.js";
import PaymentForm from "./PaymentForm.js";
class GetCredits extends Component {
    constructor (props) {
        super(props);
        this.state = {
            amount: "",
            hours: null,
            paid: false,
            errorMessage: ""
        }
        this.paidDone = this.paidDone.bind(this);
    }
    paidDone() {
        this.setState({paid: true});
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = parseInt(e.target.value, 10);
        console.log(name, value)
        this.setState({[name]: value}, () => {
            if (name === "hours") {
                this.setState({amount: value * 25});
            }
        });
    }
    componentDidMount() {
    }
    render() {
        return (
            <div class="row align-items-center m-t-lg">
                <div class="col-md-12 col-xs-12">
                    <div className="m-b-md">
                        <BackButton />
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <h2>Get Credits</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 gform_fields">
                            <p>price per hour: $25</p>
                            {!this.state.paid && <p>Hours to Purchase:<br />
                            <select size="1" name="hours" style={{width: '50%'}} class="form-control form-control-sm" value={this.state.hours} onChange={(event) => this.handleUserInput(event)}>
                                  <option></option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                  <option value="25">25</option>
                                  <option value="30">30</option>
                                  <option value="35">35</option>
                            </select></p>}
                            {!this.state.paid && !!this.state.hours && <PaymentForm cb={this.paidDone} doctitle={``} identifier={"getCredits-" + new Date().getTime()} hours={this.state.hours} />}
                            {this.state.paid && !!this.state.hours && <p>Thank you for your purchase of {this.state.hours} hours.</p>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetCredits;
