import React, { Component } from 'react';
import Moment from 'react-moment';
import axios from 'axios';
import Icon from "./Icon.js";
import Modal from "react-bootstrap/Modal";
import BackButton from "./BackButton.js";
import ModulesIntro from './ModulesIntro.js';
import ModulesAdded from './ModulesAdded.js';
import ModuleNotice from './ModuleNotice.js';
import AccountChooser from './AccountChooser.js';
import './Modules.css';

class Modules extends Component {
    constructor (props) {
        super(props);
        this.state = {
            noticeContent: {},
            multijournals: true,
            multi: true,
            authenticated: false,
            accountsJournals: [],
            accounts: [],
            doc: {},
        };
        this.getData = this.getData.bind(this);
        this.addToAccount = this.addToAccount.bind(this);
    }
    addToAccount(e) {
        if (this.state.doc.inAccount) {
            return false;
        } else {
            axios({
                method: "post",
                url: "/api/addModuleToAccount",
                data: {
                    contentId: this.state.doc.content_id
                }
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.state.doc.inAccount = true;
                this.setState({doc: this.state.doc});
                window.location.reload();
            })
        }
    }
    getData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.id,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const data = res.data.result[0];
            this.setState({doc: data});
            axios({
                method: "get",
                url: `/api/modulecontent/${data.original_id}`,
            }).then(res2 => {
                this.setState({noticeContent: res2.data.data.length ? res2.data.data[0] : {}});
            })
        })
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/universesession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            this.setState({authenticated: true});
            this.setState({user: "known"});
            this.setState({userData: sares.data.user});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            } else {
                axios({
                    method: "get",
                    url: "/api/journalsession",
                }).then(jores => {
                    this.setState({multijournals: jores.data.multi});
                    if (jores.data.multi) {
                        this.setState({accountsJournals: jores.data.result});
                    } else {
                        this.getData();
                    }
                })
            }
        }, () => {
            this.setState({authenticated: true});
            this.setState({user: "anonymous"});
            this.getData();
        })
    }
    render() {
        let paid = this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && this.state.doc.modulerecord[0].paid,
            moduleContent = !!this.state.noticeContent.id ? this.state.authenticated && this.state.doc.inAccount && paid ? <ModulesAdded noticeContent={this.state.noticeContent} doc={this.state.doc} /> : <ModulesIntro noticeContent={this.state.noticeContent} addToAccount={this.addToAccount} doc={this.state.doc} authenticated={this.state.authenticated} /> : "";
        let accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="universe" accounts={this.state.accounts} />) : "";
        let accountChooserJournals = this.state.accountsJournals.length > 0 ? (<AccountChooser accountType="journal" accounts={this.state.accountsJournals} />) : "";
        return (
            <div>
                <div className="row">
                    {accountChooser}
                    {accountChooserJournals}
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="m-b-md">
                            <BackButton />
                        </div>
                        <strong class="text-uc text-muted">Module Name</strong>
                        <h1 className="m-b-sm">{this.state.doc.title}</h1>
                        {this.state.user === null || this.state.user === "anonymous" && <div class="alert alert-warning">You must <a href="/login">login</a> to purchase or complete the module.</div>}
                        <p class="text-lg text-muted"><span><a data-toggle="modal" data-target={`.bs-example-modal-lg-moduleinfo`}><Icon outerClass="icon icon-md text-secondary" passedClass="icon-info_outline" /></a> </span>{this.state.doc.description}</p>
                        <div className={`modal bs-example-modal-lg-moduleinfo`} tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <div class="text-right">
                                            <button type="button" class="circle bg-white text-primary text-xl" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                        <div class="container p-x p-b">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="modal-con">
                                                        {!!this.state.noticeContent.approve_1 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_1}}></span>}
                                                        {!!this.state.noticeContent.approve_2 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_2}}></span>}
                                                        {!!this.state.noticeContent.approve_3 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_3}}></span>}
                                                        {!!this.state.noticeContent.approve_4 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_4}}></span>}
                                                        {!!this.state.noticeContent.approve_5 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_5}}></span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="m-b-sm text-uc">CME Value <span className="circle bg-secondary text-inverse text-700">{this.state.doc.actual_cme_value}</span> MOC Value <span className="circle bg-secondary text-inverse text-700">{this.state.doc.moc_value}</span></p>
                        {!!this.state.noticeContent.id && <p className="m-b-sm text-uc">Member Cost <span className="circle bg-secondary text-inverse text-700">${this.state.noticeContent.memberPrice || 0}</span> Non-Member Cost <span className="circle bg-secondary text-inverse text-700">${this.state.noticeContent.nonMemberPrice || 0}</span></p>}
                        {(!!this.state.doc.publish_date || !!this.state.doc.expiration_date) && <p className="m-t-lg">Release Date: <Moment format="MM/DD/YYYY" date={this.state.doc.publish_date} /><br />Expiration Date: <Moment format="MM/DD/YYYY" date={this.state.doc.expiration_date} /></p>}
                        {!!this.state.noticeContent.id && !!this.state.noticeContent.approve_1 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_1}}></span>}
                        <hr />
                        {this.state.doc.original_id && moduleContent}
                    </div>
                </div>
            </div>
        )
    }
}

export default Modules;
