import React from 'react';
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom'
import ReactTags from 'react-tag-autocomplete'
import Icon from "./Icon.js";
import axios from 'axios';
import _ from "lodash";
import './DocumentTagsListing.css';

export default class DocumentTagsListing extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            id: this.props.id,
            doc: this.props.doc,
            key: this.props.key,
            tags: this.props.doc.tags,
            suggestions: this.props.availableTags
        };
        this.removeTagFromContent = this.removeTagFromContent.bind(this);
        this.addTagToContent = this.addTagToContent.bind(this);
        this.reactTags = React.createRef();
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        return {
            suggestions: nextProps.availableTags,
            // ... other derived state properties
        };
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ doc: { ...this.state.doc, [name]: value} }, ()=>{});
    }
    removeTagFromContent(tag) {
        axios({
            method: "post",
            url: "/api/admin/deleteTagRelationship",
            data: {
                content_item: this.state.doc.content_id,
                tag: tag.tag
            }
        }).then(res => {
        })
    }
    addTagToContent(tag) {
        axios({
            method: "post",
            url: "/api/admin/addTagRelationship",
            data: {
                content_item: this.state.doc.content_id,
                tag: tag.id
            }
        }).then(res => {
        })
    }
    onDelete (i) {
        const tags = this.state.tags.slice(0)
        let old = tags.splice(i, 1)
        this.setState({ tags })
        this.removeTagFromContent(old[0]);
    }

    onAddition (tag) {
        var existingTag = _.find(this.state.tags, function(o) { return o.name === tag.name } );
        if (!!!existingTag) {
            const tags = [].concat(this.state.tags, tag)
            this.setState({ tags })
            this.addTagToContent(tag);
        }
    }

    render() {
        let title,
            infoBar;

        title = this.state.doc.education_media_type === "journal" ? (<a href={`/journals/${this.state.doc.content_id}`}>{this.state.doc.title}</a>) : this.state.doc.education_media_type === "self-assessment test" ? (<a href={`/satest/${this.state.doc.content_id}`}>{this.state.doc.title}</a>) : (<a href={`/presentations/${this.state.doc.content_id}`}>{this.state.doc.title}</a>);
        /*
        if (this.props.mode === "account") {
        } else {
            title = (<span>{this.state.doc.title}</span>);
        }
        */
        if (this.state.doc.education_media_type === "journal") {
            infoBar = (
                <div>
                    <span className="label label-default">Journal</span>
                    <span className="text-uc text-mute text-500 p-l-xs">Volume {this.state.doc.journal_volume}, Issue {this.state.doc.journal_issue}</span>
                    <p className="text-right m-b-sm text-uc">CME Value <span className="circle bg-secondary text-inverse text-700">1</span></p>
                </div>
            )
        } else if (this.state.doc.education_media_type === "presentation") {
            infoBar = (
                <div>
                    <span className="label label-default">Presentation</span>
                    <p className="text-right m-b-sm text-uc">CME Value <span className="circle bg-secondary text-inverse text-700">{this.state.doc.actual_cme_value}</span></p>
                    <p className="m-t-sm m-b-0">{this.state.doc.event_name}</p>
                </div>
            )
        } else if (this.state.doc.education_media_type === "self-assessment test") {
            infoBar = (
                <div>
                    <span className="label label-default">Self-Assessment Test</span>
                </div>
            )
        } else if (this.state.doc.education_media_type === "module") {
            infoBar = (
                <div>
                    <span className="label label-default">Module</span>
                </div>
            )
        } else if (this.state.doc.education_media_type === "podcast") {
            infoBar = (
                <div>
                    <span className="label label-default">Podcast</span>
                </div>
            )
        }
        return (
            <div className="col-md-4 col-xs-12 display-flex flex-column" key={this.state.id}>
                <div className="card card-item flex-1 display-flex flex-column">
                    <div className="card-block">
                        <h2 className="text-lg m-b-sm text-600">{title}</h2>
                        {infoBar}
                    </div>
                    <div class="m-t-auto card-footer p-r-sm">
                        <ReactTags
                            ref={this.reactTags}
                            tags={this.state.tags}
                            suggestions={this.state.suggestions}
                            onDelete={this.onDelete.bind(this)}
                            onAddition={this.onAddition.bind(this)} />
                    </div>
                </div>
            </div>
        )
    }
}

