import React, { Component } from 'react';
import Icon from "./Icon.js";
import { Redirect, Link } from "react-router-dom";
import axios from 'axios';
import './ModulesEvaluation.css';

class ModulesEvaluation extends Component {
    constructor (props) {
        super(props);
        const initialState = {
            doc: {},
            formErrors: {},
            requiredFields: [
                "strongest_part",
                "needs_improvement",
                "future_topics",
                "register",
                "scope",
                "met_objectives",
                "objective",
                "changes_planned"
            ],
            fields: [
                "strongest_part",
                "needs_improvement",
                "future_topics",
                "register",
                "performance_changes",
                "apply_skills",
                "scope",
                "met_objectives",
                "objective",
                "objective_disagree_details",
                "improved",
                "changes_planned",
                "comments"
            ],
            strongest_part: "",
            needs_improvement: "",
            future_topics: "",
            register: "",
            performance_changes: "",
            apply_skills: "",
            scope: "",
            met_objectives: "",
            objective: "",
            objective_disagree_details: "",
            improved: "",
            changes_planned: "",
            comments: "",
        };
        this.state = initialState;
        this.selectAnswer = this.selectAnswer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        if (e.target.value.length <= 300) {
            this.setState({[e.target.name]: e.target.value});
        }
    }
    selectAnswer(question, answer) {
        this.setState({[question]: answer});
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.mid,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const data = res.data.result[0];
            this.setState({doc: data});
        })
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        console.log(this.state.requiredFields.indexOf(fieldName));
        if (this.state.requiredFields.indexOf(fieldName) > -1) {
            fieldValidationErrors[fieldName] = !!value ? '' : ' is required';
            console.log(!!value, fieldValidationErrors)
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm(callback) {
        let valid = true;
        for (var field in this.state.formErrors) {
            if (this.state.formErrors[field].length) {
                valid = false;
            }
        }
        this.setState({
            formValid: valid
        }, () => {
            if (!this.state.formValid) {
                if (document.getElementById("formErrors")) {
                    document.getElementById("formErrors").scrollIntoView();
                }
            }
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }
    handleSubmit = event => {
        event.preventDefault();
        console.log(this.state);
        this.state.requiredFields.forEach(field => {
            this.validateField(field, this.state[field]);
        });
        var docBody = Object.assign({}, this.state);
        docBody.content_id = this.state.doc.original_id;
        delete docBody.doc;
        delete docBody.fields;
        delete docBody.requiredFields;
        delete docBody.formErrors;
        this.validateForm(() => {
            if (this.state.formValid) {
                axios({
                    method: "post",
                    url: "/api/submitModuleEvaluation",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: docBody
                }).then(res => {
                    window.location.reload();
                }, () => {
                });
            }
        });
    }
    render() {
        if ((this.state.doc.modulerecord && !!!this.state.doc.modulerecord.length) || (this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && !!this.state.doc.modulerecord[0].evaluation_complete)) {
            return <Redirect to={`/modules/${this.props.match.params.mid}`} />
        }
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href={`/modules/${this.props.match.params.mid}`}>
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back to Module</span>
                        </a>
                    </div>
                    <h2>Evaluation for {this.state.doc.title}</h2>
                    <form onSubmit={this.handleSubmit} className="evalform">
                        {!this.state.formValid && Object.entries(this.state.formErrors).length > 0 && <div className="alert alert-danger"><ul id="formErrors">{Object.entries(this.state.formErrors).map((key, value) => {if (key[1].length > 0) return (<li>{key}</li>)})}</ul></div>}
                        <div className="row">
                            <div className="col-xs-12">
                                <div class="alert alert-warning">
                                    Please rate each of the following by clicking on your response. A completed evaluation form is required in order to receive CME credit for this activity.  <span class="redbold">* = required</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-7">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Strongly Disagree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Agree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Strongly Agree</strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-7">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>1</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>2</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>3</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>4</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>5</strong>
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">The activity was objective, balanced and free of commercial bias:<span class="redbold">*</span></div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="1" className="" onClick={() => this.selectAnswer("objective", 1)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="2" className="" onClick={() => this.selectAnswer("objective", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="3" className="" onClick={() => this.selectAnswer("objective", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="4" className="" onClick={() => this.selectAnswer("objective", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="5" className="" onClick={() => this.selectAnswer("objective", 5)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">For scores of 1 or 2, please tell us why you disagree:</div>
                            <div className="col-xs-5 text-left">
                                <textarea name="objective_disagree_details" value={this.state.objective_disagree_details} onChange={this.handleChange} />(max 300 characters)
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">This activity was appropriate for the scope of my professional practice.**</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scope" value="1" className="" onClick={() => this.selectAnswer("scope", 1)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scope" value="2" className="" onClick={() => this.selectAnswer("scope", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scope" value="3" className="" onClick={() => this.selectAnswer("scope", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scope" value="4" className="" onClick={() => this.selectAnswer("scope", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scope" value="5" className="" onClick={() => this.selectAnswer("scope", 5)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                This activity met the stated objectives.**
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="1" className="" onClick={() => this.selectAnswer("met_objectives", 1)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="2" className="" onClick={() => this.selectAnswer("met_objectives", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="3" className="" onClick={() => this.selectAnswer("met_objectives", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="4" className="" onClick={() => this.selectAnswer("met_objectives", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="5" className="" onClick={() => this.selectAnswer("met_objectives", 5)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">This activity will improve my ability to effectively treat and manage my patients.**</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improved" value="1" className="" onClick={() => this.selectAnswer("improved", 1)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improved" value="2" className="" onClick={() => this.selectAnswer("improved", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improved" value="3" className="" onClick={() => this.selectAnswer("improved", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improved" value="4" className="" onClick={() => this.selectAnswer("improved", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improved" value="5" className="" onClick={() => this.selectAnswer("improved", 5)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">I will apply knowledge/skills I learned in the program to my practice. **</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_skills" value="1" className="" onClick={() => this.selectAnswer("apply_skills", 1)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_skills" value="2" className="" onClick={() => this.selectAnswer("apply_skills", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_skills" value="3" className="" onClick={() => this.selectAnswer("apply_skills", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_skills" value="4" className="" onClick={() => this.selectAnswer("apply_skills", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_skills" value="5" className="" onClick={() => this.selectAnswer("apply_skills", 5)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">This activity will result in performance changes in my professional practice.**</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_changes" value="1" className="" onClick={() => this.selectAnswer("performance_changes", 1)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_changes" value="2" className="" onClick={() => this.selectAnswer("performance_changes", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_changes" value="3" className="" onClick={() => this.selectAnswer("performance_changes", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_changes" value="4" className="" onClick={() => this.selectAnswer("performance_changes", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_changes" value="5" className="" onClick={() => this.selectAnswer("performance_changes", 5)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-12">**For scores of 1 or 2, please expand with details in the “General Comments” area.</div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-12"><em>For your answers below, please be as specific as possible. If you refer to a specific lecture or presenter, please note that in your comments.</em></div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">Please list specific changes you will make in your practice as a result of the information you received from this course: <span class="redbold">*</span></div>
                            <div className="col-xs-5 text-left">
                                <textarea name="changes_planned" value={this.state.changes_planned} onChange={this.handleChange} />(max 300 characters)
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">The strongest part of the program was: <span class="redbold">*</span></div>
                            <div className="col-xs-5 text-left">
                                <textarea name="strongest_part" value={this.state.strongest_part} onChange={this.handleChange} />(max 300 characters)
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">The area most needing improvement was: <span class="redbold">*</span></div>
                            <div className="col-xs-5 text-left">
                                <textarea name="needs_improvement" value={this.state.needs_improvement} onChange={this.handleChange} />(max 300 characters)
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">Future topics that I would like to see included: <span class="redbold">*</span></div>
                            <div className="col-xs-5 text-left">
                                <textarea name="future_topics" value={this.state.future_topics} onChange={this.handleChange} />(max 300 characters)
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">I would register for similar programs in the future: <span class="redbold">*</span></div>
                            <div className="col-xs-5 text-left">
                                <input type="radio" name="register" value="Yes" className="" onClick={() => this.selectAnswer("register", "Yes")} /> Yes <br />
                                <input type="radio" name="register" value="No" className="" onClick={() => this.selectAnswer("register", "No")} /> No <br />
                                <input type="radio" name="register" value="Maybe" className="" onClick={() => this.selectAnswer("register", "Maybe")} /> Maybe <br />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                General Comments
                            </div>
                            <div className="col-xs-5 text-left">
                                <textarea name="comments" value={this.state.comments} onChange={this.handleChange} />(max 300 characters)
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default ModulesEvaluation;
