import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Icon from "./Icon.js";
class Tabs extends Component {
    constructor (props) {
        super(props);
        this.state = {
            authenticated: false,
            user: null
        }
        this.checkSession = this.checkSession.bind(this)
    }
    checkSession() {
        axios({
            method: "get",
            url: "/api/universesession",
        }).then(sares => {
                this.setState({authenticated: true});
                this.setState({user: "known"});
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
            }
        })
    }
    componentDidMount() {
        setTimeout(this.checkSession, 500);
    }
    render() {
        return (
            <>
                {this.state.authenticated && this.state.user === "known" && <ul className="nav nav-tabs m-b-md">
                    <li className="nav-item">
                        <NavLink to="/" exact={true} activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-home2" />
                            <span className="p-l-xs">Home</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact={true} to="/account" activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-user2" />
                            <span className="p-l-xs">My Account</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact={true} to="/account/complete" activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-user2" />
                            <span className="p-l-xs">Completed</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/search" activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-book2" />
                            <span className="p-l-xs">All Content</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/about" activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-book2" />
                            <span className="p-l-xs">About</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/help" activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-book2" />
                            <span className="p-l-xs">Help</span>
                        </NavLink>
                    </li>
                </ul>}
                {!!!this.state.authenticated && this.state.user === "anonymous" && <ul className="nav nav-tabs m-b-md">
                    <li className="nav-item">
                        <NavLink to="/login" activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-user2" />
                            <span className="p-l-xs">Login</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/search" exact={true} activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-home2" />
                            <span className="p-l-xs">All Content</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/about" activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-book2" />
                            <span className="p-l-xs">About</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/help" activeClassName="active" className="nav-link">
                            <Icon passedClass="icon-book2" />
                            <span className="p-l-xs">Help</span>
                        </NavLink>
                    </li>
                </ul>}
            </>
        );
    }
}

export default Tabs;
