import React, { Component } from 'react';
import { FormErrors } from './FormErrors';
import Spinner from 'react-spinkit';
import axios from 'axios';

const initialState = {
    title: '',
    selectedFile: null,
    file: null,
    qid: null,
    question: '',
    Option1: '',
    Option2: '',
    Answer: '',
    cme_value: 0,
    active: false,
    description: '',
    expiration_date: '',
    publish_date: '',
    formErrors: {
        question: '',
        title: '',
        Option1: '',
        Option2: '',
        Answer: '',
    },
    formValid: false,
    loading: false,
    docs: [],
    total: 0,
    keywords: "",
    moduleDocs: [],
    page: 1,
    pageSize: 100
};
class AdminContentDetailQuestionImage extends Component {
    constructor (props) {
        super(props);
        console.log(this.props.match.params.contentid);
        this.state = initialState;
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.keywordSearch = this.keywordSearch.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }
    keywordSearch(event) {
        this.setState({ page: 1 });
        this.getData(this.state.page, this.state.pageSize);
    }
    componentDidMount() {
        if (this.props.match.params.imageid) {
            axios({
                method: "get",
                url: "/api/admin/content/" + this.props.match.params.contentid + "/question/" + this.props.match.params.questionid + "/image/" + this.props.match.params.imageid,
            }).then(res => {
                console.log(res);
                console.log(res.data);
                const data = res.data.result;
                let state = Object.assign(initialState, data);
                this.setState(state);
            })
        }
        if (this.props.match.params.questionid) {
            axios({
                method: "get",
                url: "/api/admin/content/" + this.props.match.params.contentid + "/question/" + this.props.match.params.questionid,
            }).then(res => {
                console.log(res);
                console.log(res.data);
                const data = res.data.result;
                data.question = data.Question;
                let state = Object.assign(initialState, data);
                this.setState(state);
            })
        }
        if (this.props.match.params.docid) {
            axios({
                method: "get",
                url: "/api/admin/content/" + this.props.match.params.contentid,
            }).then(res => {
                console.log(res);
                console.log(res.data);
                const data = res.data.result;
                let state = Object.assign(initialState, data);
                this.setState(state);
            })
        }
    }
    resetForm() {
        this.setState(initialState);
    }

    handleCheck (e) {
        const name = e.target.name;
        const checked = e.target.checked;
        const state = {};
        state[name] = checked;
        this.setState(state);
    }

    handleTagInput (field, tags) {
        console.log(tags)
        this.setState({[field]: tags});
    }
    handleUserInput (e) {
        console.log(e)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('myImage', this.state.selectedFile, this.state.selectedFile.name);
        formData.append('questionid', this.props.match.params.questionid);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post("/api/admin/content/question/image", formData, config).then((response) => {
            this.resetForm();
            this.props.history.push('/admin/content/' + this.props.match.params.contentid + '/question/' + this.props.match.params.questionid);
            console.log("WHYNO")
        }).catch((error) => {
            console.log(error)
        });

    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors,
            questionValid = !!!this.state.formErrors.question;

        switch(fieldName) {
            case 'question':
                questionValid = value.length >= 6;
                fieldValidationErrors.question = questionValid ? '' : ' is too short';
                break;
            case 'title':
                titleValid = value.length >= 6;
                fieldValidationErrors.title = titleValid ? '' : ' is too short';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: !this.state.formErrors.question
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    handleKeywordChange(event) {
        this.setState({keywords: event.target.value});
    }
    onFileChange = event => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
    };
    render() {
        console.log(this.state)
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <form onSubmit={this.handleSubmit} className="demoForm">
                            <h2>Content: {this.state.title}</h2>
                            <h3>Question: <span dangerouslySetInnerHTML={{__html: this.state.Question}} /></h3>
                            <h3>Add an Image to Question</h3>
                            <p><a href={`/admin/content/${this.props.match.params.contentid}/question/${this.props.match.params.questionid}`}>Back to Question</a></p>
                            <div className="panel panel-default">
                                <FormErrors formErrors={this.state.formErrors} />
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div>
                                        <label htmlFor="imageupload">Choose Image</label>
                                        <input type="file" onChange={this.onFileChange} className="form-control" />
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        {"Add Image"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminContentDetailQuestionImage;
