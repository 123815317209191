import React, { Component } from 'react';
import SATestModal from './SATestModal.js';
import './SATestSideBox.css';

class SATestSideBox extends Component {
    render() {
        return (
            <div className="bg-gray-lightest p-a">
                <div className="card card-highlight card-sm card-block text-sm sg-usage testprogress">
                    <h3 className="text-lg">Test Progress <span><a href={`/satest/satest_${this.props.doc.original_id}/scores`}>view</a></span></h3>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <td>Questions</td>
                                <td className="text-right"><span className="label label-pill label-info">{this.props.question.totalQuestions}</span></td>
                            </tr>
                            <tr>
                                <td>Completed</td>
                                <td className="text-right"><span className="label label-pill label-info">{this.props.question.completeQuestions}</span></td>
                            </tr>
                            <tr>
                                <td>Completed Correct</td>
                                <td className="text-right"><span className="label label-pill label-secondary">{this.props.question.completeQuestionsCompleted}</span></td>
                            </tr>
                            <tr>
                                <td>Correct Needed to Pass</td>
                                <td className="text-right"><span className="label label-pill label-secondary">{this.props.question.totalQuestions * .7}</span></td>
                            </tr>
                            <tr>
                                <td>Score So Far</td>
                                <td className="text-right"><span className="label label-pill label-secondary">{Math.round((this.props.question.completeQuestionsCompleted/(this.props.question.completeQuestions || 1)) * 100)}%</span></td>
                            </tr>
                            <tr>
                                <td>Score Needed to Pass</td>
                                <td className="text-right"><span className="label label-pill label-secondary">70%</span></td>
                            </tr>
                            <tr>
                                <td>Remaining</td>
                                <td className="text-right"><span className="label label-pill label-warning">{this.props.question.totalQuestions - this.props.question.completeQuestions}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {this.props.question.question.complete && <div className="card card-highlight card-sm card-block text-sm sg-usage">
                    <h3>All Respondents</h3>
                    <table className="table table-sm">
                        <tbody>
                            {this.props.question.question.allRespondents.O1 ? <tr><td>Answer A</td><td className="text-right">{this.props.question.question.allRespondents.O1}%</td></tr> : <tr><td></td><td></td></tr>}
                            {this.props.question.question.allRespondents.O2 ? <tr><td>Answer B</td><td className="text-right">{this.props.question.question.allRespondents.O2}%</td></tr> : <tr><td></td><td></td></tr>}
                            {this.props.question.question.allRespondents.O3 ? <tr><td>Answer C</td><td className="text-right">{this.props.question.question.allRespondents.O3}%</td></tr> : <tr><td></td><td></td></tr>}
                            {this.props.question.question.allRespondents.O4 ? <tr><td>Answer D</td><td className="text-right">{this.props.question.question.allRespondents.O4}%</td></tr> : <tr><td></td><td></td></tr>}
                            {this.props.question.question.allRespondents.O5 ? <tr><td>Answer E</td><td className="text-right">{this.props.question.question.allRespondents.O5}%</td></tr> : <tr><td></td><td></td></tr>}
                        </tbody>
                    </table>
                </div>}
                <h4>Other Categories</h4>
                <ul className="unstyled items">
                    {this.props.question.categories && Object.entries(this.props.question.categories).map(x => {console.log(x, this.props); return <li><a href={`/satest/satest_${this.props.question.question.testversion}/questions/?category=${x[1].Category}`}>{x[1].Category}</a> Completed {x[1].numcomplete} of {x[1].numquescat}</li>})}
                </ul>
                <h4>Important Information</h4>
                <ul className="unstyled">
                    <li><SATestModal linkType="accreditation" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SATestModal linkType="description" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SATestModal linkType="subcommittee" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SATestModal linkType="faculty" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SATestModal linkType="technical" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><a href="https://gi.org/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    <li><SATestModal linkType="contactus" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                </ul>
            </div>
        )
    }
}

export default SATestSideBox;
