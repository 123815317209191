import React, { Component } from 'react';

class SATestImages extends Component {
    render() {
        var imageHeader = this.props.question.Image5 ? (<><b>5 images</b><br /></>) : this.props.question.Image4 ? (<><b>4 images</b><br /></>) : this.props.question.Image3 ? (<><b>3 images</b><br /></>) : this.props.question.Image2 ? (<><b>2 images</b><br /></>) : "",
            images = {
                Image: "",
                Image2: "",
                Image3: "",
                Image4: "",
                Image5: ""
            };
            for (var x in images) {
                if (this.props.question[x]) {
                    var lgurl = this.props.question[x].indexOf("mp4") > -1 ? 
                                `https://acgcdn.gi.org/satest/questionimg${this.props.question.testversion}/${this.props.question[x]}` : `https://acgcdn.gi.org/satest/questionimg${this.props.question.testversion}/lg${this.props.question[x]}`,
                        url = this.props.question[x].indexOf("mp4") > -1 ? 
                        `https://acgcdn.gi.org/satest/questionimg${this.props.question.testversion}/${this.props.question[x].replace(".mp4", "_thumb.png").replace("a_thumb.png", "_thumb.png")}` : `https://acgcdn.gi.org/satest/questionimg${this.props.question.testversion}/${this.props.question[x]}`;

                    if (this.props.question["lg" + x.toLowerCase()] === "y") {
                        images[x] = (<><br /><a href={lgurl} target="_blank" rel="noopener noreferrer"><img src={url} vspace="5" border="0" /></a><br />Click the image above to enlarge it.</>)
                    } else {
                        images[x] = (<><br /><img src={url} vspace="5" border="0" /><br /></>)
                    }
                }
            }

        return (
            <div>
                {imageHeader}
                {images.Image}
                {images.Image2}
                {images.Image3}
                {images.Image4}
                {images.Image5}
            </div>
        )
    }
}

export default SATestImages;
