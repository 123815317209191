import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import Icon from "./Icon.js";
import JournalStatus from './JournalStatus.js';
import TestSideBox from './TestSideBox.js';

class SAPMOCEvaluation extends Component {
    constructor (props) {
        super(props);
        const initialState = {
            formValid: false,
            doc: {},
            formErrors: {},
            requiredFields: [
                "overall",
                "content",
                "apply",
                "format",
                "fulfilled",
                "scientific",
                "effectiveness",
                "likely",
                "onechange"
            ],
            fields: [
                "overall",
                "content",
                "apply",
                "format",
                "fulfilled",
                "scientific",
                "effectiveness",
                "likely",
                "onechange",
                "topics",
                "comments"
            ],
            overall: "",
            content: "",
            apply: "",
            format: "",
            fulfilled: "",
            scientific: "",
            effectiveness: "",
            likely: "",
            onechange: "",
            topics: "",
            comments: ""
        };
        this.state = initialState;
        this.selectAnswer = this.selectAnswer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    selectAnswer(question, answer) {
        console.log(question, answer)
        this.setState({[question]: answer});
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.jid,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const data = res.data.result[0];
            this.setState({doc: data});
        })
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        console.log(this.state.requiredFields.indexOf(fieldName));
        if (this.state.requiredFields.indexOf(fieldName) > -1) {
            fieldValidationErrors[fieldName] = !!value ? '' : ' is required';
            console.log(value, !!value, fieldValidationErrors)
        }
        if ((fieldName === "topics" || fieldName === "comments" || fieldName === "onechange") && value && value.length && value.length > 200) {
            fieldValidationErrors[fieldName] = ' is longer than 200 characters';
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm(callback) {
        let valid = true;
        for (var field in this.state.formErrors) {
            if (this.state.formErrors[field].length) {
                valid = false;
            }
        }
        this.setState({
            formValid: valid
        }, () => {
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }
    handleSubmit = event => {
        event.preventDefault();
        console.log(this.state);
        this.state.requiredFields.forEach(field => {
            this.validateField(field, this.state[field]);
        });
        var docBody = {
            "overall": this.state.overall,
            "content": this.state.content,
            "apply": this.state.apply,
            "format": this.state.format,
            "fulfilled": this.state.fulfilled,
            "scientific": this.state.scientific,
            "effectiveness": this.state.effectiveness,
            "likely": this.state.likely,
            "onechange": this.state.onechange,
            "topics": this.state.topics,
            "comments": this.state.comments
        };
        docBody.content_id = this.state.doc.original_id;
        delete docBody.doc;
        delete docBody.fields;
        delete docBody.requiredFields;
        delete docBody.formErrors;
        this.validateForm(() => {
            if (this.state.formValid) {
                axios({
                    method: "post",
                    url: "/api/submitJournalEvaluation",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: docBody
                }).then(res => {
                    window.location.reload();
                }, () => {
                });
            }
        });
    }
    render() {
        if ((this.state.doc.journalrecord && !!!this.state.doc.journalrecord.length) || (this.state.doc.journalrecord && !!this.state.doc.journalrecord.length && !!this.state.doc.journalrecord[0].evalcomplete)) {
            if (!!this.state.doc.parsId) {
                return <Redirect to={`/journals/${this.props.match.params.jid}/mocsubmit`} />
            } else {
                return <Redirect to={`/journals/${this.props.match.params.jid}`} />
            }
        }
        return (
            <div className="row">
                <div className="col-md-8 col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href="/account">
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back</span>
                        </a>
                    </div>
                    <h2>Evaluation for {this.state.doc.title}</h2>
                    <form onSubmit={this.handleSubmit} className="evalform">
                        {!this.state.formValid && Object.entries(this.state.formErrors).length > 0 && <div className="alert alert-info"><ul>{Object.entries(this.state.formErrors).map((key, value) => {if (key[1].length > 0) return (<li>{key}</li>)})}</ul></div>}
                        {this.state.doc.journalrecord && !!this.state.doc.journalrecord.length && <div className="row">
                            <div className="col-xs-12">
                                <JournalStatus doc={this.state.doc} />
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-xs-12">
                                <div class="alert alert-warning">
                                    Please rate each of the following by clicking on your response. A completed evaluation form is required in order to receive CME credit for this activity.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-7">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Excellent</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Very Good</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Good</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Fair</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Poor</strong>
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                Overall evaluation of the activity
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="overall" value="Excellent" className="" onClick={() => this.selectAnswer("overall", "Excellent")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="overall" value="Very Good" className="" onClick={() => this.selectAnswer("overall", "Very Good")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="overall" value="Good" className="" onClick={() => this.selectAnswer("overall", "Good")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="overall" value="Fair" className="" onClick={() => this.selectAnswer("overall", "Fair")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="overall" value="Poor" className="" onClick={() => this.selectAnswer("overall", "Poor")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                Quality of the educational content
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="content" value="Excellent" className="" onClick={() => this.selectAnswer("content", "Excellent")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="content" value="Very Good" className="" onClick={() => this.selectAnswer("content", "Very Good")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="content" value="Good" className="" onClick={() => this.selectAnswer("content", "Good")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="content" value="Fair" className="" onClick={() => this.selectAnswer("content", "Fair")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="content" value="Poor" className="" onClick={() => this.selectAnswer("content", "Poor")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Strongly Agree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Agree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Strongly Disagree</strong>
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                This activity was relevant to my practice
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply" value="5" className="" onClick={() => this.selectAnswer("apply", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply" value="4" className="" onClick={() => this.selectAnswer("apply", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply" value="3" className="" onClick={() => this.selectAnswer("apply", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply" value="2" className="" onClick={() => this.selectAnswer("apply", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply" value="1" className="" onClick={() => this.selectAnswer("apply", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                This activity met the stated objectives.
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="format" value="5" className="" onClick={() => this.selectAnswer("format", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="format" value="4" className="" onClick={() => this.selectAnswer("format", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="format" value="3" className="" onClick={() => this.selectAnswer("format", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="format" value="2" className="" onClick={() => this.selectAnswer("format", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="format" value="1" className="" onClick={() => this.selectAnswer("format", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                This activity fulfilled my educational needs.
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="fulfilled" value="5" className="" onClick={() => this.selectAnswer("fulfilled", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="fulfilled" value="4" className="" onClick={() => this.selectAnswer("fulfilled", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="fulfilled" value="3" className="" onClick={() => this.selectAnswer("fulfilled", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="fulfilled" value="2" className="" onClick={() => this.selectAnswer("fulfilled", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="fulfilled" value="1" className="" onClick={() => this.selectAnswer("fulfilled", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                The activity was objective, scientifically rigorous, balanced, and free of commercial bias.
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scientific" value="5" className="" onClick={() => this.selectAnswer("scientific", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scientific" value="4" className="" onClick={() => this.selectAnswer("scientific", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scientific" value="3" className="" onClick={() => this.selectAnswer("scientific", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scientific" value="2" className="" onClick={() => this.selectAnswer("scientific", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="scientific" value="1" className="" onClick={() => this.selectAnswer("scientific", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                This activity has contributed to my professional effectiveness and improved my ability to treat/manage patients.
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="effectiveness" value="5" className="" onClick={() => this.selectAnswer("effectiveness", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="effectiveness" value="4" className="" onClick={() => this.selectAnswer("effectiveness", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="effectiveness" value="3" className="" onClick={() => this.selectAnswer("effectiveness", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="effectiveness" value="2" className="" onClick={() => this.selectAnswer("effectiveness", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="effectiveness" value="1" className="" onClick={() => this.selectAnswer("effectiveness", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Very likely</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong></strong>
                            </div>
                            <div className="col-xs-1 text-center">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Not at all likely</strong>
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                How likely are you to participate in another AJG Journal CME activity?
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="likely" value="5" className="" onClick={() => this.selectAnswer("likely", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="likely" value="4" className="" onClick={() => this.selectAnswer("likely", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="likely" value="3" className="" onClick={() => this.selectAnswer("likely", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="likely" value="2" className="" onClick={() => this.selectAnswer("likely", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="likely" value="1" className="" onClick={() => this.selectAnswer("likely", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                What change(s) do you plan to make in your practice as a result of reading this article?*
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="onechange" value={this.state.onechange} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                List any topics you would find interesting and professionally relevant for future CME activities
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="topics" value={this.state.topics} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                General Comments
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="comments" value={this.state.comments} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-12">
                                This journal-based CME activity is designated for 1 AMA PRA Category 1 Credit™.
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
                <div className="col-md-4 col-xs-12">
                    <TestSideBox doc={this.state.doc} question={this.state.question} />
                </div>
            </div>
        )
    }
}

export default SAPMOCEvaluation;
