import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import BackButton from "./BackButton.js";
import AccountChooser from './AccountChooser.js';
import PaymentForm from './PaymentForm.js';
import SATestNotice from './SATestNotice.js';
import SATestDoneMessage from './SATestDoneMessage.js';
import Icon from "./Icon.js";
import moment from 'moment';

class SATest extends Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: {},
            justAdded: false,
            multi: true,
            authenticated: false,
            accounts: []
        };
        this.getData = this.getData.bind(this)
        this.addToAccount = this.addToAccount.bind(this);
    }
    addToAccount() {
        if (this.state.doc.inAccount) {
            return false;
        } else {
            axios({
                method: "post",
                url: "/api/addToAccount",
                data: {
                    contentId: this.state.doc.content_id
                }
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.state.doc.inAccount = true;
                this.setState({justAdded: true, doc: this.state.doc});
            })
        }
    }
    getData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.id,
        }).then(res => {
            this.setState({doc: res.data.result[0]}, () => {
                this.addToAccount();
            });
        })
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/satestsession",
        }).then(sares => {
            this.setState({
                authenticated: true,
                user: "known",
                multi: sares.data.multi
            }, () => {
                if (sares.data.multi) {
                    this.setState({accounts: sares.data.result});
                } else {
                    this.getData();
                }
            });
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
                this.setState({authenticated: true});
                this.getData();
            }
        })
    }

    render() {
        let newText = false,
            paid = this.state.doc.original_id && this.state.doc.user && this.state.doc.user["paid" + this.state.doc.original_id] === "yes",
            notice = this.state.doc.original_id && this.state.doc.user && this.state.doc.user["notice" + this.state.doc.original_id] === true,
            complete = this.state.doc.original_id && this.state.doc.user && this.state.doc.user["complete" + this.state.doc.original_id] === "done",
            expired = new Date(this.state.doc.expiration_date).getTime(),
            evalDone = this.state.doc.original_id && this.state.doc.user && this.state.doc.user["eval" + this.state.doc.original_id] === "done",
            now = new Date().getTime(),
            isExpired = now > expired,
            accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="satest" accounts={this.state.accounts} />) : "";
        const dateToFormat = moment(this.state.doc.publish_date).utc();
        const expirationDateFormat = moment(this.state.doc.expiration_date).utc();
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="m-b-md">
                        <BackButton />
                    </div>
                    {this.state.justAdded && <div className="row">
                        <div className="col-xs-12">
                            <div class="alert alert-success center font-weight-bold text-success font-weight-bold">This item has been successfully added to your account</div>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-xs-12">
                            {accountChooser}
                            {!this.state.multi && <><h3>{this.state.doc.title}</h3>
                            {isExpired && paid && !evalDone && <div class="alert alert-danger text-danger">This test has expired for CME and MOC. You may continue the test, but no credits will be awarded.</div>}
                            {isExpired && paid && evalDone && <div class="alert alert-danger text-danger">This test has expired for CME and MOC.</div>}
                            {isExpired && !paid && <div class="alert alert-danger text-danger">This test has expired for CME and MOC.</div>}
                            {this.state.user === null || this.state.user === "anonymous" && <div class="alert alert-warning">You must <a href="/login">login</a> to purchase or complete the test.</div>}
                            {!!this.state.doc.original_id && <SATestDoneMessage welcome={true} doc={this.state.doc} />}
                            {!!this.state.doc.original_id && !complete && <div class="alert alert-warning">
                                To receive CME and MOC credits, you must successfully pass the test with a minimum score of 70%. The link to submit your data to ACCME for the purpose of granting ABIM MOC credit will only be provided if you have achieved a passing score AND complete the activity evaluation to claim your CME credits. MOC points will be awarded equivalent to the amount of CME credits claimed for the activity.
                            </div>}
                            <div className="alert alert-primary">
                                <strong>Release date:</strong> <Moment format="MM/DD/YYYY" date={dateToFormat} /><br />
                                <strong>Expiration date:</strong> <Moment format="MM/DD/YYYY" date={expirationDateFormat} /> (no CME or MOC credit will be awarded after this date)
                                {this.state.user === null || this.state.user === "anonymous" && <span><br />ACG MEMBERS: $99 | NON-MEMBERS: $150</span>}
                                <br /><br />*IMPORTANT: Requests for MOC points MUST be submitted by December 31 of the year you COMPLETE the test. If you complete the test in its final year of release, you must claim credits prior to the expiration date. No retroactive credit will be accepted by ABIM.
                            </div>
                            {!paid && !isExpired && this.state.authenticated && this.state.doc && this.state.doc.original_id && this.state.doc.user && <PaymentForm doctitle={this.state.doc.title} identifier={"satest-" + this.state.doc.original_id} amount={this.state.doc.testcost} />}
                            {paid && !notice && this.state.doc && this.state.doc.original_id && <SATestNotice doc={this.state.doc} testid={this.state.doc.original_id} />}
                            {!complete && paid && notice && this.state.doc && this.state.doc.original_id && <a href={`/satest/satest_${this.state.doc.original_id}/questions`} className="btn btn-sm btn-primary">Continue the Test</a>}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SATest;
