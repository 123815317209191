import React, { Component } from 'react';
import BackButton from "./BackButton.js";
import { Link } from "react-router-dom";
import axios from 'axios';
import Icon from "./Icon.js";
import QuestionAnswersComplete from './QuestionAnswersComplete.js';
import QuestionAnswersIncomplete from './QuestionAnswersIncomplete.js';
import TestSideBox from './TestSideBox.js';
import JournalStatus from './JournalStatus.js';

class Questions extends Component {
    constructor (props) {
        super(props);
        this.state = {
            userData: {},
            credits: null,
            doc: {
                articleComplete: false
            },
            showMessage: false,
            selectedAnswer: null,
            correct: false,
            question: {
                question: {
                    Question: ''
                }
            }
        };
        this.selectAnswer = this.selectAnswer.bind(this)
        this.submitAnswer = this.submitAnswer.bind(this)
        this.getData = this.getData.bind(this)
    }
    selectAnswer(e) {
        this.setState({selectedAnswer: parseInt(e.target.value, 10)});
    }
    submitAnswer(e) {
        if (this.state.selectedAnswer) {
            axios({
                method: "post",
                url: "/api/submitAnswer",
                data: {
                    answer: this.state.selectedAnswer,
                    question: this.state.question.question.actualid,
                    article: this.state.doc.original_id
                }
            }).then(res => {
                this.setState({showMessage: true});
                this.setState({correct: res.data.correct});
                this.getData(this.state.question.question.actualid);
            })
        }
    }
    getData(qid) {
        let questionId = this.props.match.params.qid || qid || null;
        axios({
            method: "get",
            url: !questionId ? "/api/getQuestion/" + this.props.match.params.jid : "/api/getQuestion/" + this.props.match.params.jid + "/" + questionId,
        }).then(res => {
            this.setState({question: res.data.data});
            axios({
                method: "get",
                url: "/api/getContent/" + this.props.match.params.jid,
            }).then(res => {
                res.data.result[0].articleComplete = !!res.data.result[0].journalrecord.length;
                this.setState({doc: res.data.result[0]});
            })
        })
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/journalsession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            this.setState({authenticated: true});
            this.setState({user: "known"});
            this.setState({userData: sares.data.user});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            }
            axios({
                method: "get",
                url: "/api/getTotal",
            }).then(total => {
                this.setState({credits: total.data.total});
            });
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
            }
        })
        this.getData();
    }

    render() {
        let next,
            answers,
            nextPrevious,
            questionStatus = "",
            previous;
        if (!this.state.question.previousQuestion) {
            previous = "";
        } else {
            previous = (<a href={`/journals/${this.props.match.params.jid}/questions/${this.state.question.previousQuestion}`} className="btn btn-sm btn-primary">Previous</a>);
        }
        if (!this.state.question.nextQuestion) {
            next = "";
        } else {
            next = (<a href={`/journals/${this.props.match.params.jid}/questions/${this.state.question.nextQuestion}`} className="btn btn-sm btn-primary">Next</a>);
        }
        questionStatus = this.state.showMessage ? this.state.correct ? "That answer is correct." : 'Sorry, that answer is incorrect.  Please try again.' : ""
        answers = this.state.question.question.complete ? <QuestionAnswersComplete question={this.state.question.question} /> : <QuestionAnswersIncomplete question={this.state.question.question} submitAnswer={this.submitAnswer} selectAnswer={this.selectAnswer} />;
        nextPrevious = (
                <div className="row p-t">
                    <div className="col-md-4 col-xs-12">
                        {previous}
                    </div>
                    <div className="col-xs-4 text-center">
                        {this.state.question.questionPosition} of {this.state.question.totalQuestions}
                    </div>
                    <div className="col-xs-4 text-right">
                        {next}
                    </div>
                </div>
        );
        return (
            <div className="row">
                <div className="col-md-8 col-xs-12">
                    <div className="m-b-md">
                        <BackButton />
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <strong class="text-uc text-muted">Article Name</strong>
                            <h1 class="m-b-md" dangerouslySetInnerHTML={{__html:this.state.doc.title}} />
                        </div>
                    </div>
                    {this.state.doc.articleComplete && <div className="row">
                        <div className="col-xs-12">
                            <JournalStatus userData={this.state.userData} credits={this.state.credits} doc={this.state.doc} />
                        </div>
                    </div>}
                    {!!this.state.showMessage && <div className={`alert alert-top alert alert-${!!this.state.correct ? 'success' : 'danger'}`}>
                        <strong>{questionStatus}</strong>
                    </div>}
                    {nextPrevious}
                    <p className="text-lg m-y" dangerouslySetInnerHTML={{__html: this.state.question.question.Question}} />
                    {answers}
                    {!!this.state.showMessage && <div className={`alert alert-top alert alert-${!!this.state.correct ? 'success' : 'danger'}`}>
                        <strong>{questionStatus}</strong>
                    </div>}
                    {nextPrevious}
                </div>
                <div className="col-md-4 col-xs-12">
                    <TestSideBox doc={this.state.doc} question={this.state.question} />
                </div>
            </div>
        )
    }
}

export default Questions;
