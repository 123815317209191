import React, { Component } from 'react';
import axios from 'axios';

class SATestDoneMessage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            data: {},
            doc: this.props.doc,
            passed: false,
            isExpired: (new Date().getTime()) > (new Date(this.props.doc.expiration_date).getTime()),
            saCompleteReady: typeof this.props.saCompleteReady === "function" ? this.props.saCompleteReady : () => {},
            complete: this.props.doc.original_id && this.props.doc.user && this.props.doc.user["complete" + this.props.doc.original_id] === "done",
            eval: this.props.doc.original_id && this.props.doc.user && this.props.doc.user["eval" + this.props.doc.original_id] === "done",
            mocSubmitted: this.props.doc.original_id && this.props.doc.user && this.props.doc.user["mocsubmitted" + this.props.doc.original_id] === true
        };
        this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        if (this.state.complete) {
            this.getData();
        } else {
            this.state.saCompleteReady();
        }
    }
    getData() {
        axios({
            method: "get",
            url: `/api/getSATestQuestion/${this.state.doc.content_id}`,
        }).then(res => {
            console.log(res)
            this.setState({data: res.data.data, passed: (res.data.data.completeQuestionsCompleted/res.data.data.totalQuestions) >= .7});
            this.state.saCompleteReady();
        })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ doc: nextProps.doc });
    }
    render() {
        return (
            <>
            {1 == 0 && !this.state.complete && <div class="alert alert-top">See your <a href={`/satest/satest_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/satest/satest_${this.state.doc.original_id}/questions`}>review the questions</a> or return to the <a href={`/satest/satest_${this.state.doc.original_id}`}>welcome page</a>.</div>}
            {this.state.complete && !this.state.isExpired && !!this.state.data.totalQuestions && this.state.eval && this.state.passed && <><div class="alert alert-top alert alert-success">
                Congratulations! You have finished the test with a passing score of {Math.round((this.state.data.completeQuestionsCompleted/this.state.data.totalQuestions) * 100)}% and are eligible for CME credits and MOC points. See your <a href={`/api/satest/satest_${this.state.doc.original_id}/certificate.pdf`} target="_blank">certificate of completion</a>, see your <a href={`/satest/satest_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/satest/satest_${this.state.doc.original_id}/questions`}>review the questions</a>.  {this.state.mocSubmitted && <span>MOC Submitted.</span>} {!this.state.mocSubmitted && <span><a href={`/satest/satest_${this.state.doc.original_id}/mocsubmit`}>Submit for MOC points</a> (or try again if previous attempts failed).</span>}
            </div>{!!this.props.welcome && <p><a className="btn btn-danger" href={`/satest/satest_${this.state.doc.original_id}/clear`}>Clear Results and Try Again</a></p>}</>}
            {this.state.complete && !this.state.isExpired && !!this.state.data.totalQuestions && !this.state.eval && this.state.passed && <><div class="alert alert-top alert alert-success">
                Congratulations! You have finished the test with a passing score of {Math.round((this.state.data.completeQuestionsCompleted/this.state.data.totalQuestions) * 100)}% and are eligible for CME credits and MOC points. Please click here to complete a brief <a href={`/satest/satest_${this.state.doc.original_id}/evaluation`}>evaluation</a> (the evaluation must be completed to be able to print your certificate and to submit for MOC points). See your <a href={`/satest/satest_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/satest/satest_${this.state.doc.original_id}/questions`}>review the questions</a>.
            </div>{!!this.props.welcome &&<p><a className="btn btn-danger" href={`/satest/satest_${this.state.doc.original_id}/clear`}>Clear Results and Try Again</a></p>}</>}
            {this.state.complete && this.state.isExpired && this.state.eval && !!this.state.data.totalQuestions && this.state.passed && <><div class="alert alert-top alert alert-success">
                Congratulations! You have finished the test with a passing score of {Math.round((this.state.data.completeQuestionsCompleted/this.state.data.totalQuestions) * 100)}%. See your <a href={`/api/satest/satest_${this.state.doc.original_id}/certificate.pdf`} target="_blank">certificate of completion</a>, see your <a href={`/satest/satest_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/satest/satest_${this.state.doc.original_id}/questions`}>review the questions</a>.
            </div>{!!this.props.welcome &&<p><a className="btn btn-danger" href={`/satest/satest_${this.state.doc.original_id}/clear`}>Clear Results and Try Again</a></p>}</>}
            {this.state.complete && this.state.isExpired && !this.state.eval && !!this.state.data.totalQuestions && this.state.passed && <><div class="alert alert-top alert alert-success">
                Congratulations! You have finished the test with a passing score of {Math.round((this.state.data.completeQuestionsCompleted/this.state.data.totalQuestions) * 100)}%. See your <a href={`/satest/satest_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/satest/satest_${this.state.doc.original_id}/questions`}>review the questions</a>.
            </div>{!!this.props.welcome &&<p><a className="btn btn-danger" href={`/satest/satest_${this.state.doc.original_id}/clear`}>Clear Results and Try Again</a></p>}</>}

            {this.state.complete && !!this.state.data.totalQuestions && !this.state.passed && <><div class="alert alert-top alert alert-warning">
                    You have completed the test but did not achieve the minimum passing score of 70% required for CME and MOC and credit. See your <a href={`/satest/satest_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/satest/satest_${this.state.doc.original_id}/questions`}>review the questions</a>.
            </div>{!!this.props.welcome &&<p><a className="btn btn-danger" href={`/satest/satest_${this.state.doc.original_id}/clear`}>Clear Results and Try Again</a></p>}</>}
            </>
        )
    }
}

export default SATestDoneMessage;
