import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Icon from "./Icon.js";
class Banner extends Component {
    constructor (props) {
        super(props);
        this.state = {
            authenticated: false,
            user: null
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div>
              <section id="heroBanner" className="row-container hero text-inverse" style={{backgroundImage:"url(/images/education-hero.png)", marginTop:"-17px"}}>
                <div className="container container-flush">
                    <div className="row col-count-2">
                      <div className="col bg-primary raised-lg display-flex flex-column col-sm-6 col-xs-12">
                        <div className="p-a p-lg-x-lg p-t-lg p-b-lg">
                          <h1 className="m-b-sm text-webfont-one display-4 text-300">Education Universe</h1>
                          <p className="display-2 text-webfont-one text-300 m-b-md">ACG Online Learning Resource</p>
                        </div>
                      </div>
                    </div>
                </div>
              </section>
              <span id="heroBannerFoot"></span>
            </div>
        );
    }
}

export default Banner;
