import React, { Component } from 'react';
import AccountChooser from './AccountChooser.js';
import axios from 'axios';
import _ from 'lodash';
import SATestDoneMessage from './SATestDoneMessage.js';
import './SATestScores.css';

class SATestScores extends Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: {},
            multi: true,
            stats: [],
            accounts: [],
            scores: [],
            categories:[]
        };
        this.getData = this.getData.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.buildTables = this.buildTables.bind(this);
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/satestsession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            } else {
                this.getData();
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
                this.getData();
            }
        })
    }
    showModal() {
        this.setState({isOpen: true});
    };

    hideModal() {
        this.setState({isOpen: false});
    };
    getData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.tid,
        }).then(res => {
            this.setState({doc: res.data.result[0]});
            axios({
                method: "get",
                url: `/api/satest/scores/${res.data.result[0].original_id}`,
            }).then(res => {
                console.log(res)
                let categories = _.groupBy(res.data.data, "Category")
                this.setState({scores: res.data.data, categories});
                console.log(categories)
                axios({
                    method: "get",
                    url: `/api/satest/stats/${this.state.doc.original_id}`,
                }).then(res2 => {
                    this.setState({stats: res2.data.data});
                })
            })
        })
    }
    buildTables() {
        let tables = [],
            cats = this.state.categories;
        for (var item in cats){
            console.log(item, cats); 
            tables.push(<div className="col-xs-3">
                    {item}
                </div>)
        };
        return tables.join("");
    }
    render() {
        let accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="satest" accounts={this.state.accounts} />) : "";
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-12">
                            {accountChooser}
                            {!this.state.multi && <><h3>{this.state.doc.title}</h3>
                                {!!this.state.doc.original_id && <SATestDoneMessage doc={this.state.doc} />}
                                <div className="row">
                                {!!this.state.scores.length && Object.keys(this.state.categories).map(item => { return (
                                    <div className="col-xs-4">
                                        <h3 className="cap">{item}</h3>
                                        <div className="row stattable">
                                        {this.state.categories[item].map(x => {return <div className={`col-xs-2 ${x.firstanswer === null ? '' : x.firstanswer === true ? 'green' : 'red'}`}><a href={`/satest/satest_${this.state.doc.original_id}/questions/${x.ID}`}>{x.rankcat}</a></div>})}
                                        </div>
                                    </div>
                                )})}
                                </div>
                                <div className="row statstable m-t-lg">
                                {!!this.state.stats.length && this.state.stats.map((item, index) => { return (
                                    <div className="col-xs-4 m-b-lg">
                                        <h3>{item.Category}</h3>
                                        <div className="row data">
                                            <div className="col-xs-11">
                                                Correctly Answered: {item.numquesright} {index == 0 ? `(${item.numquescat * .7} required to pass)` : ``}
                                            </div>
                                        </div>
                                        <div className="row data">
                                            <div className="col-xs-11">
                                                Questions Completed: {item.numquescomplete}
                                            </div>
                                        </div>
                                        <div className="row data">
                                            <div className="col-xs-11">
                                                Score so far: {item.score}% {index === 0 ? `(70% required to pass)` : ``}
                                            </div>
                                        </div>
                                        <div className="row data">
                                            <div className="col-xs-11">
                                                Total Questions in Category: {item.numquescat}
                                            </div>
                                        </div>
                                        <div className="row data">
                                            <div className="col-xs-11">
                                                Remaining Questions to Complete: {index === 0 ? (item.numquescat - item.numquescomplete) : item.remaincomplete}
                                            </div>
                                        </div>
                                    </div>
                                )})}
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SATestScores;
