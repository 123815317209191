import React, { Component } from 'react';

class QuestionAnswersComplete extends Component {
    render() {
        var optionA = this.props.question.Option1 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '1' ? 'success' : 'info'}`}>A</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option1.replace("A.", "")}} className={`${this.props.question.Answer === '1' ? 'text-success' : ''}`} />
                    </label>
                </div>
            ) : "",
            optionB = this.props.question.Option2 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '2' ? 'success' : 'info'}`}>B</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option2.replace("B.", "")}} className={`${this.props.question.Answer === '2' ? 'text-success' : ''}`} />
                    </label>
                </div>
            ) : "",
            optionC = this.props.question.Option3 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '3' ? 'success' : 'info'}`}>C</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option3.replace("C.", "")}} className={`${this.props.question.Answer === '3' ? 'text-success' : ''}`} />
                    </label>
                </div>
            ) : "",
            optionD = this.props.question.Option4 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '4' ? 'success' : 'info'}`}>D</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option4.replace("D.", "")}} className={`${this.props.question.Answer === '4' ? 'text-success' : ''}`} />
                    </label>
                </div>
            ) : "",
            optionE = this.props.question.Option5 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '5' ? 'success' : 'info'}`}>E</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option5.replace("E.", "")}} className={`${this.props.question.Answer === '5' ? 'text-success' : ''}`} />
                    </label>
                </div>
            ) : "";

        return (
            <div>
                {optionA}
                {optionB}
                {optionC}
                {optionD}
                {optionE}
                <div className="card card-block card-sm bg-gray-lightest m-t-md">
                    <strong class="text-uc display-block m-b-xs">Explanation</strong>
                    <span dangerouslySetInnerHTML={{__html: this.props.question.Explanation}}></span>
                </div>
            </div>
        )
    }
}

export default QuestionAnswersComplete;
