import React, { Component } from 'react';
import SATestImages from './SATestImages.js';

class SATestQuestionAnswersIncomplete extends Component {
    render() {
        var optionA = this.props.question.Option1 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <input type="radio" name="answers" value="1" className="m-r" onClick={this.props.selectAnswer} />
                        <span className="label label-pill m-r-xs label-info">A</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option1.replace("A.", "")}} />
                    </label>
                </div>
            ) : "",
            optionB = this.props.question.Option2 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <input type="radio" name="answers" value="2" className="m-r" onClick={this.props.selectAnswer} />
                        <span className="label label-pill m-r-xs label-info">B</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option2.replace("B.", "")}} />
                    </label>
                </div>
            ) : "",
            optionC = this.props.question.Option3 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <input type="radio" name="answers" value="3" className="m-r" onClick={this.props.selectAnswer} />
                        <span className="label label-pill m-r-xs label-info">C</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option3.replace("C.", "")}} />
                    </label>
                </div>
            ) : "",
            optionD = this.props.question.Option4 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <input type="radio" name="answers" value="4" className="m-r" onClick={this.props.selectAnswer} />
                        <span className="label label-pill m-r-xs label-info">D</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option4.replace("D.", "")}} />
                    </label>
                </div>
            ) : "",
            optionE = this.props.question.Option5 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <input type="radio" name="answers" value="5" className="m-r" onClick={this.props.selectAnswer} />
                        <span className="label label-pill m-r-xs label-info">E</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option5.replace("E.", "")}} />
                    </label>
                </div>
            ) : "";

        return (
            <div>
                {optionA}
                {optionB}
                {optionC}
                {optionD}
                {optionE}
                {this.props.question && <SATestImages question={this.props.question} />}
                <div className="bg-gray-lightest p-a center"><button type="submit" className="btn btn-secondary btn-xl" onClick={this.props.submitAnswer}><span className="icon-ok-sign"></span> Submit answer</button></div>
            </div>
        )
    }
}

export default SATestQuestionAnswersIncomplete;
