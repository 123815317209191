import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Tabs from "./Tabs.js";
import Icon from "./Icon.js";
class Help extends Component {
    constructor (props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <>
                <div class="row align-items-center m-t-lg">
                    <div class="col-md-612 col-xs-12 p-lg-r-md">
                        <div class="card raised">
                            <div class="card-block p-a-sm bg-primary-light text-center text-inverse">
                                <h2 class="text-lg m-b-0">Help</h2>
                            </div>
                            <div class="card-block">
            <h2>Frequently Asked Questions</h2>
<p><strong>What will I find in the ACG Education Universe?</strong></p>
<p>The ACG Education Universe contains material from ACG Annual Meetings, Postgraduate Courses, Abstracts, Journals, Self-Assessment Tests, additional material created specifically for the Education Universe, and more.</p>
<p><strong>What is My Portfolio?</strong><br />
<a href="/portfolio.asp"><strong>My Portfolio</strong></a> is a repository of learned content that is accessible only to you.&nbsp; The Portfolio contains all of the activities you undertake in the ACG Education Universe.</p>
<p><strong>How do I move around the Education Universe?</strong><br />
  The menu system at the top is your navigation system. The pages you will find are as follows:</p>

<p><strong>GET CREDITS</strong></p>
<ul type="disc">
  <li><a href="/getcredits.asp"><strong>Get Credits</strong></a><strong> </strong><strong>- </strong>where      you can purchase time to use on the available CME activities, you can also see your <a href="/details.asp" title="Transaction History">&ldquo;<strong>Transaction History</strong></a>&rdquo;</li>
</ul>

<p><strong>CONTENT</strong></p>
<ul type="disc">
  <li><a href="/browse.asp"><strong>Content</strong></a> - where you can browse all the      content available to you </li>
  <li><a href="/contentlist.asp"><strong>Content List</strong></a> - a quick list of ACG      Education Universe content before logging in &nbsp;</li>
</ul>
<p><strong>MY PORTFOLIO</strong></p>
<ul type="disc">
  <li><a href="/portfolio.asp"><strong>My Portfolio</strong></a> - where you will find all of      the content you have purchased</li>

  <li><a href="/cmecredit.asp" title="CME Certificate Generator"><strong>CME      Certificate Generator</strong></a> - print certificates for the CME you have earned</li>
  <li><a href="/cmereport.asp" title="CME Credit Status Report"><strong>CME      Credit Status Report</strong></a> – see which CME activities in your Portfolio are ready      for certificate generation, and those that still need a completed      evaluation</li>

</ul>
<p><strong>What is a content filter?</strong><br />
  Given the amount and variety of content on the site, filters allow you to narrow your search to a more specific list of material.</p>
<p><strong>What is archived content?</strong><br />
  Coming soon. </p>
<p><strong>If I have questions or trouble using the Universe, who can I contact?</strong><br />
Please email <a href="mailto:acguniverse@gi.org">acguniverse@gi.org</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Help;
