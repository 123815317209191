import React from 'react';
import { Link } from "react-router-dom";
import Icon from "./Icon.js";
import axios from 'axios';
import './ProgressListing.css';
export default class ProgressListing extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: this.props.doc,
            key: this.props.key
        };
    }
    render() {
        let title,
            infoBar;

        title = this.state.doc.education_media_type === "journal" ? (<a href={`/journals/${this.state.doc.content_id}`}>{this.state.doc.title}</a>) : this.state.doc.education_media_type === "self-assessment program for maintenance of certification" ? (<a href={`/sapmoc/${this.state.doc.content_id}`}>{this.state.doc.title}</a>) : this.state.doc.education_media_type === "self-assessment test" ? (<a href={`/satest/${this.state.doc.content_id}`}>{this.state.doc.title}</a>) : this.state.doc.education_media_type === "presentation" ? (<a href={`/presentations/${this.state.doc.content_id}`}>{this.state.doc.title}</a>) : (<a href={`/modules/${this.state.doc.content_id}`}>{this.state.doc.title}</a>);
        /*
        if (this.props.mode === "account") {
        } else {
            title = (<span>{this.state.doc.title}</span>);
        }
        */
        if (this.state.doc.education_media_type === "journal") {
            infoBar = (
                <div>
                    <span className="label label-default">Journal</span>
                    <span className="text-uc text-mute text-500 p-l-xs">Volume {this.state.doc.journal_volume}, Issue {this.state.doc.journal_issue}</span>
                    <p className="text-right m-b-sm text-uc">CME Value <span className="circle bg-secondary text-inverse text-700">1</span></p>
                </div>
            )
        } else if (this.state.doc.education_media_type === "module") {
            infoBar = (
                <div>
                    <span className="label label-default">Module</span>
                    <p className="text-right m-b-sm text-uc">CME Value <span className="circle bg-secondary text-inverse text-700">{this.state.doc.actual_cme_value || 0}</span></p>
                </div>
            )
        } else if (this.state.doc.education_media_type === "presentation") {
            infoBar = (
                <div>
                    <span className="label label-default">Presentation</span>
                    <p className="text-right m-b-sm text-uc">CME Value <span className="circle bg-secondary text-inverse text-700">{this.state.doc.actual_cme_value}</span></p>
                    <p className="m-t-sm m-b-0">{this.state.doc.event_name}</p>
                </div>
            )
        } else if (this.state.doc.education_media_type === "self-assessment test") {
            infoBar = (
                <div>
                    <span className="label label-default">Self-Assessment Test</span>
                </div>
            )
        } else if (this.state.doc.education_media_type === "self-assessment program for maintenance of certification") {
            infoBar = (
                <div>
                    <span className="label label-default">Self-Assessment Program for Maintenance of Certification</span>
                </div>
            )
        }
        return (
            <span key={this.state.key}>{title}</span>
        )
    }
}

