import React, { Component } from 'react';

class ContentImages extends Component {
    render() {
        console.log(this.props)

        return (
            <div>
                { !!this.props.question.images && !!this.props.question.images.length && this.props.question.images.map((doc, i) => (
                    <><br />Image {i + 1}<br /><a href={!!doc.legacy ? `https://acgcdn.gi.org/satest/questionimg${doc.imagefolder || ""}/${doc.image}` : doc.url} target="_blank" rel="noopener noreferrer"><img src={!!doc.legacy ? `https://acgcdn.gi.org/satest/questionimg${doc.imagefolder || ""}/${doc.image}` : doc.url} width="300" vspace="5" border="0" /></a><br />Click the image above to enlarge it.</>
                ))}
            </div>
        )
    }
}

export default ContentImages;
