import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, NavLink } from "react-router-dom";
import axios from 'axios';

const Auth = {
  isAuthenticated: true,
  authenticate(cb) {
    console.log("authenticate");
    this.isAuthenticated = true
    setTimeout(cb, 100)
  },
  signout(cb) {
    console.log("signout");
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}

export default Auth;
