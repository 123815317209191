import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import axios from 'axios';
import './Evaluation.css';

class Evaluation extends Component {
    constructor (props) {
        super(props);
        const initialState = {
            doc: {},
            formErrors: {},
            requiredFields: [
                "met_objectives",
                "met_educational_needs",
                "objective",
                "changes_planned"
            ],
            fields: [
                "met_objectives",
                "met_educational_needs",
                "objective",
                "objective_disagree_details",
                "contributed",
                "changes_planned",
                "comments"
            ],
            met_objectives: "",
            met_educational_needs: "",
            objective: "",
            objective_disagree_details: "",
            contributed: "",
            changes_planned: "",
            comments: "",
        };
        this.state = initialState;
        this.selectAnswer = this.selectAnswer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        if (e.target.value.length <= 300) {
            this.setState({[e.target.name]: e.target.value});
        }
    }
    selectAnswer(question, answer) {
        this.setState({[question]: answer});
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.id,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const data = res.data.result[0];
            this.setState({doc: data});
        })
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        if (this.state.requiredFields.indexOf(fieldName) > -1) {
            fieldValidationErrors[fieldName] = !!value ? '' : ' is required';
            console.log(!!value, fieldValidationErrors)
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm(callback) {
        let valid = true;
        for (var field in this.state.formErrors) {
            if (this.state.formErrors[field].length) {
                valid = false;
            }
        }
        this.setState({
            formValid: valid
        }, () => {
            if (!this.state.formValid) {
                if (document.getElementById("formErrors")) {
                    document.getElementById("formErrors").scrollIntoView();
                }
            }
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }
    handleSubmit = event => {
        event.preventDefault();
        console.log(this.state);
        this.state.requiredFields.forEach(field => {
            this.validateField(field, this.state[field]);
        });
        var docBody = Object.assign({}, this.state);
        docBody.content_id = this.state.doc.original_id;
        delete docBody.doc;
        delete docBody.fields;
        delete docBody.requiredFields;
        delete docBody.formErrors;
        this.validateForm(() => {
            if (this.state.formValid) {
                axios({
                    method: "post",
                    url: "/api/submitEvaluation",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: docBody
                }).then(res => {
                    window.location.reload();
                }, () => {
                });
            }
        });
    }
    render() {
        if ((this.state.doc.portfolio && !!!this.state.doc.portfolio.length) || (this.state.doc.portfolio && !!this.state.doc.portfolio.length && !!this.state.doc.portfolio[0].evaluation_complete)) {
            return <Redirect to={`/presentations/${this.props.match.params.id}`} />
        }
        return (
            <div className="row">
                <div className="col-xs-12">
                    <h2>Evaluation for {this.state.doc.title}</h2>
                    <form onSubmit={this.handleSubmit} className="evalform">
                        {!this.state.formValid && Object.entries(this.state.formErrors).length > 0 && <div className="alert alert-danger"><ul id="formErrors">{Object.entries(this.state.formErrors).map((key, value) => {if (key[1].length > 0) return (<li>{key}</li>)})}</ul></div>}
                        <div className="row">
                            <div className="col-xs-12">
                                <div class="alert alert-warning">
                                    Please rate each of the following by clicking on your response. A completed evaluation form is required in order to receive CME credit for this activity. <span class="redbold">* = required</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-7">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Strongly Agree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Agree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Strongly Disagree</strong>
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                This presentation met the stated objectives. <span class="redbold">*</span>
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="5" className="" onClick={() => this.selectAnswer("met_objectives", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="4" className="" onClick={() => this.selectAnswer("met_objectives", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="3" className="" onClick={() => this.selectAnswer("met_objectives", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="2" className="" onClick={() => this.selectAnswer("met_objectives", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives" value="1" className="" onClick={() => this.selectAnswer("met_objectives", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                This presentation met my educational needs. <span class="redbold">*</span>
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_educational_needs" value="5" className="" onClick={() => this.selectAnswer("met_educational_needs", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_educational_needs" value="4" className="" onClick={() => this.selectAnswer("met_educational_needs", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_educational_needs" value="3" className="" onClick={() => this.selectAnswer("met_educational_needs", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_educational_needs" value="2" className="" onClick={() => this.selectAnswer("met_educational_needs", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_educational_needs" value="1" className="" onClick={() => this.selectAnswer("met_educational_needs", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                The presentation was objective, scientifically rigorous, balanced and free of commercial bias. <span class="redbold">*</span>
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="5" className="" onClick={() => this.selectAnswer("objective", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="4" className="" onClick={() => this.selectAnswer("objective", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="3" className="" onClick={() => this.selectAnswer("objective", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="2" className="" onClick={() => this.selectAnswer("objective", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective" value="1" className="" onClick={() => this.selectAnswer("objective", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                If you marked "disagree" or "strongly disagree", please provide us with details on why you cannot agree with this statement. (200 characters max.)
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="objective_disagree_details" value={this.state.objective_disagree_details} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                This presentation has contributed to my professional effectiveness and improved my ability to treat/manage patients.
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="contributed" value="5" className="" onClick={() => this.selectAnswer("contributed", 5)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="contributed" value="4" className="" onClick={() => this.selectAnswer("contributed", 4)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="contributed" value="3" className="" onClick={() => this.selectAnswer("contributed", 3)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="contributed" value="2" className="" onClick={() => this.selectAnswer("contributed", 2)} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="contributed" value="1" className="" onClick={() => this.selectAnswer("contributed", 1)} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                What changes do you plan to make in your practice as a result of this lecture? <span class="redbold">*</span>
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="changes_planned" value={this.state.changes_planned} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                                General Comments
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="comments" value={this.state.comments} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Evaluation;
