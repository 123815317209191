import React, { Component } from 'react';
import { withRouter, BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Auth from './Auth.js';
import PrivateRoute from './PrivateRoute.js';
import Banner from './Banner.js';
import Tabs from './Tabs.js';
import Login from './Login.js';
import About from './About.js';
import Help from './Help.js';
import GetCredits from './GetCredits.js';
import PromoCode from './PromoCode.js';
import Admin from './Admin.js';
import Presentations from './Presentations.js';
import Modules from './Modules.js';
import ModulesQuestions from './ModulesQuestions.js';
import ContentQuestions from './ContentQuestions.js';
import SAPMOC from './SAPMOC.js';
import SATest from './SATest.js';
import Journals from './Journals.js';
import Podcasts from './Podcasts.js';
import SAPMOCQuestions from './SAPMOCQuestions.js';
import SATestQuestions from './SATestQuestions.js';
import SATestEvaluation from './SATestEvaluation.js';
import SATestScores from './SATestScores.js';
import SATestClear from './SATestClear.js';
import ModuleMOCSubmit from './ModuleMOCSubmit.js';
import SATestMOCSubmit from './SATestMOCSubmit.js';
import SAPMOCEvaluation from './SAPMOCEvaluation.js';
import SAPMOCVideos from './SAPMOCVideos.js';
import SAPMOCMOCSubmit from './SAPMOCMOCSubmit.js';
import Questions from './Questions.js';
import CMSTransfer from './CMSTransfer.js';
import Home from './Home.js';
import DocumentList from './DocumentList.js';
import Evaluation from './Evaluation.js';
import JournalEvaluation from './JournalEvaluation.js';
import ModulesEvaluation from './ModulesEvaluation.js';
import MyAccount from './MyAccount.js';
import AdminModule from './AdminModule.js';
import AdminTags from './AdminTags.js';
import AdminContent from './AdminContent.js';
import AdminContentDetail from './AdminContentDetail.js';
import AdminContentDetailQuestion from './AdminContentDetailQuestion.js';
import AdminContentDetailQuestionImage from './AdminContentDetailQuestionImage.js';
import AdminSymposia from './AdminSymposia.js';
import AdminSymposiaForm from './AdminSymposiaForm.js';
import MOCSubmit from './MOCSubmit.js';
import AdminModuleForm from './AdminModuleForm.js';
import AdminModuleFormQuestion from './AdminModuleFormQuestion.js';
import AdminModuleFormQuestionImage from './AdminModuleFormQuestionImage.js';
import axios from 'axios';
import ScrollToTop from './ScrollToTop.js';
import './App.css';

class App extends Component {
    constructor (props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Router>
                <div className="App">
                    <ScrollToTop>
                        <Route exact path={['/', '/login', '/about', '/help']} component={Banner} />
                        <Route exact path={['/', '/account', '/account/complete', '/search', '/about', '/help']} component={Tabs} />
                        <div>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/about" component={About} />
                            <Route exact path="/help" component={Help} />
                            <Route exact path="/getCredits" component={GetCredits} />
                            <Route exact path="/promocode" component={PromoCode} />
                            <Route exact path="/search" component={DocumentList} />
                            <Route exact path="/account" component={MyAccount} />
                            <Route exact path="/account/complete" component={MyAccount} />
                            <Route exact path="/modules/:id" component={Modules} />
                            <Route exact path="/modules/:mid/mocsubmit" component={ModuleMOCSubmit} />
                            <Route exact path="/modules/:mid/evaluation" component={ModulesEvaluation} />
                            <Route exact path="/modules/:mid/questions" component={ModulesQuestions} />
                            <Route exact path="/modules/:mid/questions/:qid" component={ModulesQuestions} />
                            <Route exact path="/presentations/:id" component={Presentations} />
                            <Route exact path="/presentations/:id/evaluation" component={Evaluation} />
                            <Route exact path="/presentations/:id/module/:mid" component={Presentations} />
                            <Route exact path="/content/:contentid/questions" component={ContentQuestions} />
                            <Route exact path="/content/:contentid/questions/:qid" component={ContentQuestions} />
                            <Route exact path="/sapmoc/:id" component={SAPMOC} />
                            <Route exact path="/satest/:id" component={SATest} />
                            <Route exact path="/journals/:id" component={Journals} />
                            <Route exact path="/journals/:id/module/:mid" component={Journals} />
                            <Route exact path="/podcasts/:id" component={Podcasts} />
                            <Route exact path="/podcasts/:id/module/:mid" component={Podcasts} />
                            <Route exact path="/satest/:tid/questions" component={SATestQuestions} />
                            <Route exact path="/satest/:tid/evaluation" component={SATestEvaluation} />
                            <Route exact path="/satest/:tid/clear" component={SATestClear} />
                            <Route exact path="/satest/:tid/scores" component={SATestScores} />
                            <Route exact path="/satest/:tid/mocsubmit" component={SATestMOCSubmit} />
                            <Route exact path="/satest/:id/module/:mid" component={SATest} />
                            <Route exact path="/satest/:tid/questions/:qid" component={SATestQuestions} />
                            <Route exact path="/sapmoc/:tid/questions" component={SAPMOCQuestions} />
                            <Route exact path="/sapmoc/:tid/evaluation" component={SAPMOCEvaluation} />
                            <Route exact path="/sapmoc/:tid/recs" component={SAPMOCVideos} />
                            <Route exact path="/sapmoc/:tid/mocsubmit" component={SAPMOCMOCSubmit} />
                            <Route exact path="/sapmoc/:id/module/:mid" component={SAPMOC} />
                            <Route exact path="/sapmoc/:tid/questions/:qid" component={SAPMOCQuestions} />
                            <Route exact path="/journals/:jid/questions" component={Questions} />
                            <Route exact path="/journals/:jid/questions/:qid" component={Questions} />
                            <Route exact path="/journals/:jid/evaluation" component={JournalEvaluation} />
                            <Route exact path="/journals/:jid/mocsubmit" component={MOCSubmit} />
                            <PrivateRoute exact path="/admin/moduleform" component={AdminModuleForm} />
                            <PrivateRoute exact path="/admin/moduleform/:docid" component={AdminModuleForm} />
                            <PrivateRoute exact path="/admin/moduleform/:docid/question" component={AdminModuleFormQuestion} />
                            <PrivateRoute exact path="/admin/moduleform/:docid/question/:questionid" component={AdminModuleFormQuestion} />
                            <PrivateRoute exact path="/admin/moduleform/:docid/question/:questionid/image" component={AdminModuleFormQuestionImage} />
                            <PrivateRoute exact path="/admin/module" component={AdminModule} />
                            <PrivateRoute exact path="/admin/tags" component={AdminTags} />
                            <PrivateRoute exact path="/admin/content" component={AdminContent} />
                            <PrivateRoute exact path="/admin/content/:contentid" component={AdminContentDetail} />
                            <PrivateRoute exact path="/admin/content/:contentid/question" component={AdminContentDetailQuestion} />
                            <PrivateRoute exact path="/admin/content/:contentid/question/:questionid" component={AdminContentDetailQuestion} />
                            <PrivateRoute exact path="/admin/content/:contentid/question/:questionid/image" component={AdminContentDetailQuestionImage} />
                            <PrivateRoute exact path="/admin/symposia" component={AdminSymposia} />
                            <PrivateRoute exact path="/admin/symposiaform" component={AdminSymposiaForm} />
                            <PrivateRoute exact path="/admin/symposiaform/:docid" component={AdminSymposiaForm} />
                            <PrivateRoute exact path="/admin/CMSTransfer" component={CMSTransfer} />
                            <PrivateRoute exact path="/admin" component={Admin} />
                        </div>
                    </ScrollToTop>
                </div>
            </Router>
        );
    }
}

const AppWithRouter = withRouter(App);
export default App;
