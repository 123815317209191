import React from 'react';
import { Link, Redirect } from "react-router-dom";
//import './Home.css';
import axios from 'axios';
import DocumentList from './DocumentList.js';
import AccountChooser from './AccountChooser.js';
import LoggedIn from './LoggedIn.js';
import Tabs from './Tabs.js';
import Spinner from 'react-spinkit';
export default class Home extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            authenticated: false,
            hasProgram: false,
            multijournals: true,
            multi: true,
            multisatest: true,
            multisapmoc: true,
            accountsSAPMOC: [],
            accountsSATest: [],
            accountsJournals: [],
            accounts: [],
            user: null
        }
        this.getData = this.getData.bind(this)
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.keywordSearch = this.keywordSearch.bind(this);
    }
    handleFilters(event) {
    }
    keywordSearch(event) {
    }
    handleKeywordChange(event) {
        this.setState({keywords: event.target.value});
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/universesession",
        }).then(sares => {
            // check if program
            axios({
                method: "get",
                url: "/api/getProgramStatus",
            }).then(gp => {
                this.setState({hasProgram: gp.data.hasProgram});
            }, error => {
            })
            this.setState({multi: sares.data.multi});
            this.setState({authenticated: true});
            this.setState({user: "known"});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            } else {
                axios({
                    method: "get",
                    url: "/api/journalsession",
                }).then(jores => {
                    this.setState({multijournals: jores.data.multi});
                    if (jores.data.multi) {
                        this.setState({accountsJournals: jores.data.result});
                    } else {
                        axios({
                            method: "get",
                            url: "/api/satestsession",
                        }).then(sares => {
                            this.setState({multisatest: sares.data.multi});
                            if (sares.data.multi) {
                                this.setState({accountsSATest: sares.data.result});
                            } else {
                            }
                        }, error => {
                        })
                    }
                })
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({authenticated: true});
                this.setState({user: "anonymous"});
            }
        })
    }
    getData(page, pageSize) {
    }
    render() {
        let accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="universe" accounts={this.state.accounts} />) : "";
        let accountChooserJournals = this.state.accountsJournals.length > 0 ? (<AccountChooser accountType="journal" accounts={this.state.accountsJournals} />) : "";
        let accountChooserSATest = this.state.accountsSATest.length > 0 ? (<AccountChooser accountType="satest" accounts={this.state.accountsSATest} />) : "";
        let accountChooserSAPMOC = this.state.accountsSAPMOC.length > 0 ? (<AccountChooser accountType="sapmoc" accounts={this.state.accountsSAPMOC} />) : "";
        if (this.state.authenticated && this.state.user === "anonymous") {
            return <Redirect to={`/login`} />
        }
        return (
            <>
                {this.state.hasProgram && <div className="row">
                    <div class="col-md-12">
                        <div class="alert alert-info center text-info">
                            You have Program Trainee or Program Director accounts, if you are looking for the training program please visit <a href="https://universe.gi.org">universe.gi.org</a>.
                        </div>
                    </div>
                </div>}
                <div className="row">
                    {accountChooser}
                    {accountChooserJournals}
                    {accountChooserSATest}
                    {accountChooserSAPMOC}
                </div>
                {1 == 2 && !this.state.authenticated && this.state.user === "anonymous" && <DocumentList />}
                {this.state.authenticated && this.state.user === "known" && <LoggedIn history={this.props.history} />}
            </>
        )
    }
}
