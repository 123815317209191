import React from 'react';
import { Link } from "react-router-dom";
import Icon from "./Icon.js";
import axios from 'axios';
import './DocumentListing.css';
export default class DocumentListing extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: this.props.doc,
            key: this.props.key
        };
        this.addToCMS = this.addToCMS.bind(this);
    }
    addToCMS(e) {
        if (this.state.doc.inCMS) {
            return false;
        } else {
            axios({
                method: "post",
                url: "/api/addToCMS",
                data: {
                    contentId: this.state.doc.id
                }
            }).then(res => {
                this.state.doc.inCMS = true;
                this.setState({doc: this.state.doc});
            })
        }
    }
    render() {
        console.log(this.state)
        let title,
            infoBar;
        title = (<span>{this.state.doc.name}</span>);
        if (this.state.doc.content_type === 2) {
            infoBar = (
                <div>
                    <span className="label label-default">Journal</span><span className="label label-default">Volume {this.state.doc.volume}, Issue {this.state.doc.issue}</span>
                </div>
            )
        } else if (this.state.doc.content_type === 1) {
            infoBar = (
                <div>
                    <span className="label label-default">Presentation</span>
                    <p className="text-muted text-sm m-t-sm m-b-0">{this.state.doc.event_name}</p>
                </div>
            )
        }
        return (
            <div className="col-md-4 col-xs-12 display-flex flex-column">
                <div className="card card-item flex-1 display-flex flex-column" key={this.state.key}>
                    <div className="card-block">
                        <h2 className="text-lg m-b-sm">{title}</h2>
                        {infoBar}
                    </div>
                    <div class="m-t-auto card-footer text-right p-r-sm">
                        {this.props.mode !== "account" && <button onClick={(event) => this.addToCMS(event)} className={`${this.state.doc.inCMS ? 'btn-success-outline': 'btn-outline'} btn btn-sm btn-pill`}>{this.state.doc.inCMS ? <Icon passedClass="icon-check" /> : <Icon passedClass="icon-plus" />} {`${this.state.doc.inCMS ? "Added to CMS" : "Add to CMS"}`}</button>}
                    </div>
                </div>
            </div>
        )
    }
}
