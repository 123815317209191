import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FormErrors } from './FormErrors';
import Spinner from 'react-spinkit';
import axios from 'axios';

const initialState = {
    title: '',
    qid: null,
    question: '',
    Option1: '',
    Option2: '',
    Option3: '',
    Option4: '',
    Option5: '',
    Answer: '',
    Explanation: '',
    Ref1: '',
    Ref1PMID: '',
    Ref2: '',
    Ref2PMID: '',
    Ref3: '',
    Ref3PMID: '',
    Ref4: '',
    Ref4PMID: '',
    Ref5: '',
    Ref5PMID: '',
    cme_value: 0,
    active: false,
    description: '',
    expiration_date: '',
    publish_date: '',
    formErrors: {
        question: '',
        title: '',
        Option1: '',
        Option2: '',
        Option3: '',
        Option4: '',
        Option5: '',
        Ref1: '',
        Ref1PMID: '',
        Ref2: '',
        Ref2PMID: '',
        Ref3: '',
        Ref3PMID: '',
        Ref4: '',
        Ref4PMID: '',
        Ref5: '',
        Ref5PMID: '',
        Explanation: '',
        Answer: '',
    },
    formValid: false,
    loading: false,
    docs: [],
    total: 0,
    keywords: "",
    moduleDocs: [],
    questionImages: [],
    page: 1,
    pageSize: 100
};
class AdminModuleFormQuestion extends Component {
    constructor (props) {
        super(props);
        console.log(this.props.match.params.docid);
        this.state = initialState;
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.getModuleData = this.getModuleData.bind(this);
        this.keywordSearch = this.keywordSearch.bind(this);
    }
    keywordSearch(event) {
        this.setState({ page: 1 });
        this.getData(this.state.page, this.state.pageSize);
    }
    componentDidMount() {
        if (this.props.match.params.questionid) {
            axios({
                method: "get",
                url: "/api/admin/getModule/" + this.props.match.params.docid + "/question/" + this.props.match.params.questionid,
            }).then(res => {
                console.log(res);
                console.log(res.data);
                const data = res.data.result;
                data.question = data.Question;
                let state = Object.assign(initialState, data);
                this.setState(state);
                axios({
                    method: "get",
                    url: "/api/admin/getModuleQuestionsImages/" + this.props.match.params.questionid,
                }).then(resq => {
                    console.log(resq);
                    console.log(resq.data);
                    const questions = resq.data.result;
                    this.setState({questionImages: questions});
                    this.setState({loading: false});
                })
            })
        }
        if (this.props.match.params.docid) {
            axios({
                method: "get",
                url: "/api/admin/getModule/" + this.props.match.params.docid,
            }).then(res => {
                console.log(res);
                console.log(res.data);
                const data = res.data.result;
                let state = Object.assign(initialState, data);
                this.setState(state);
            })
        }
        this.getData(this.state.page, this.state.pageSize);
        this.getModuleData();
    }
    getModuleData() {
        this.setState({loading: true});
        axios({
            method: "get",
            url: "/api/admin/getModuleData/" + this.props.match.params.docid,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            let ids = [];
            const data = res.data.result;
            data.forEach((item) => {
                ids.push(item.content_id);
            });
            let docs = this.state.docs.map((item) => {
                item.inModule = ids.indexOf(item.content_id) > -1;
                return item;
            });
            this.setState({moduleDocs: data});
            this.setState({docs: docs});
            this.setState({loading: false});
        })
    }
    getData(page, pageSize) {
        this.setState({loading: true});
        const requestParams = {
            method: "get",
            url: "/api/getContent",
            params: {
                page: page,
                pageSize: pageSize
            }
        };
        if (this.state.keywords.length) {
            requestParams.params.keywords = this.state.keywords.split(" ").join(",");
        }
        axios(requestParams).then(res => {
            console.log(res);
            console.log(res.data);
            const docs = res.data.result;
            const total = res.data.total;
            this.setState({loading: false});
            this.setState({docs: []});
            this.setState({ docs, total, page, pageSize }, this.getModuleData);
            console.log(this.state)
        })
    }
    resetForm() {
        this.setState(initialState);
    }

    handleCheck (e) {
        const name = e.target.name;
        const checked = e.target.checked;
        const state = {};
        state[name] = checked;
        this.setState(state);
    }

    handleTagInput (field, tags) {
        console.log(tags)
        this.setState({[field]: tags});
    }
    handleUserInput (e) {
        console.log(e)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        const question = {
            question: this.state.question,
            Option1: this.state.Option1,
            Option2: this.state.Option2,
            Option3: this.state.Option3,
            Option4: this.state.Option4,
            Option5: this.state.Option5,
            Ref1: this.state.Ref1,
            Ref2: this.state.Ref2,
            Ref3: this.state.Ref3,
            Ref4: this.state.Ref4,
            Ref5: this.state.Ref5,
            Ref1PMID: this.state.Ref1PMID,
            Ref2PMID: this.state.Ref2PMID,
            Ref3PMID: this.state.Ref3PMID,
            Ref4PMID: this.state.Ref4PMID,
            Ref5PMID: this.state.Ref5PMID,
            Answer: this.state.Answer,
            Explanation: this.state.Explanation,
            moduleID: this.props.match.params.docid
        };

        if (this.props.match.params.questionid) {
            question.questionid = this.props.match.params.questionid;
            axios({
                method: "put",
                url: "/api/admin/module/question",
                data: question
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.resetForm();
                this.props.history.push('/admin/moduleform/' + question.moduleID);
            })
        } else {
            axios({
                method: "post",
                url: "/api/admin/module/question",
                data: question
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.resetForm();
                this.props.history.push('/admin/moduleform/' + question.moduleID);
            })
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors,
            questionValid = !!!this.state.formErrors.question;

        switch(fieldName) {
            case 'question':
                questionValid = value.length >= 6;
                fieldValidationErrors.question = questionValid ? '' : ' is too short';
                break;
            case 'title':
                titleValid = value.length >= 6;
                fieldValidationErrors.title = titleValid ? '' : ' is too short';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: !this.state.formErrors.question
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    handleKeywordChange(event) {
        this.setState({keywords: event.target.value});
    }
    render() {
        console.log(this.state)
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <form onSubmit={this.handleSubmit} className="demoForm">
                            <h2>Module: {this.state.title}</h2>
                            <h3>Add a Question</h3>
                            <p><a href={`/admin/moduleform/${this.props.match.params.docid}`}>Back to Module</a></p>
                            <div className="panel panel-default">
                                <FormErrors formErrors={this.state.formErrors} />
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.question)}`}>
                                        <label htmlFor="question">Question</label>
                                        <textarea onChange={(event) => this.handleUserInput(event)} value={this.state.question} type="text" className="form-control" name="question" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Option1)}`}>
                                        <label htmlFor="Option1">Option1</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Option1} type="text" className="form-control" name="Option1" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Option2)}`}>
                                        <label htmlFor="Option2">Option2</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Option2} type="text" className="form-control" name="Option2" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Option3)}`}>
                                        <label htmlFor="Option3">Option3</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Option3} type="text" className="form-control" name="Option3" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Option4)}`}>
                                        <label htmlFor="Option4">Option4</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Option4} type="text" className="form-control" name="Option4" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Option5)}`}>
                                        <label htmlFor="Option5">Option5</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Option5} type="text" className="form-control" name="Option5" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Answer)}`}>
                                        <label htmlFor="Answer">Answer</label>
                                        <select onChange={(event) => this.handleUserInput(event)} value={this.state.Answer} name="Answer">
                                            <option value="">Select Which Answer is Correct</option>
                                            <option value="1">A</option>
                                            <option value="2">B</option>
                                            <option value="3">C</option>
                                            <option value="4">D</option>
                                            <option value="5">E</option>
                                        </select>
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref1)}`}>
                                        <label htmlFor="Ref1">Ref1</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref1} type="text" className="form-control" name="Ref1" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref1PMID)}`}>
                                        <label htmlFor="Ref1PMID">Ref1PMID</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref1PMID} type="text" className="form-control" name="Ref1PMID" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref2)}`}>
                                        <label htmlFor="Ref2">Ref2</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref2} type="text" className="form-control" name="Ref2" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref2PMID)}`}>
                                        <label htmlFor="Ref2PMID">Ref2PMID</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref2PMID} type="text" className="form-control" name="Ref2PMID" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref3)}`}>
                                        <label htmlFor="Ref3">Ref3</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref3} type="text" className="form-control" name="Ref3" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref3PMID)}`}>
                                        <label htmlFor="Ref3PMID">Ref3PMID</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref3PMID} type="text" className="form-control" name="Ref3PMID" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref4)}`}>
                                        <label htmlFor="Ref4">Ref4</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref4} type="text" className="form-control" name="Ref4" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref4PMID)}`}>
                                        <label htmlFor="Ref4PMID">Ref4PMID</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref4PMID} type="text" className="form-control" name="Ref4PMID" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref5)}`}>
                                        <label htmlFor="Ref5">Ref5</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref5} type="text" className="form-control" name="Ref5" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Ref5PMID)}`}>
                                        <label htmlFor="Ref5PMID">Ref5PMID</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.Ref5PMID} type="text" className="form-control" name="Ref5PMID" />
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.Explanation)}`}>
                                        <label htmlFor="Explanation">Explanation</label>
                                        <textarea onChange={(event) => this.handleUserInput(event)} value={this.state.Explanation} type="text" className="form-control" name="Explanation" />
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        {this.props.match.params.questionid ? "Edit Question" : "Add Question"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.props.match.params.questionid && <>
                <div className="row">
                    <div className="col-xs-12">
                        <h3 className="p-t-lg">Images</h3>
                        <p><Link to={`/admin/moduleform/${this.props.match.params.docid}/question/${this.props.match.params.questionid}/image`}>Add New Question Image</Link></p>
                        <ul class="list-unstyled">
                        { this.state.questionImages.map((image, i) => (<li><a href={`${image.url}`} target="_blank"><img src={`${image.url}`} width="200" /></a></li>))}
                        </ul>
                    </div>
                </div></>}
            </div>
        )
    }
}

export default AdminModuleFormQuestion;
