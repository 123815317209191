import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

class AdminSymposia extends Component {
    constructor (props) {
        super(props);
        this.state = {
            symposia: []
        };
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/admin/getSymposia"
        }).then(res => {
            console.log(res);
            console.log(res.data);
            this.setState({ symposia: res.data.result });
        })
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <h2>Symposia Organizations</h2>
                        <p><Link to="/admin/symposiaform">Add New Symposia Organization</Link></p>
                        <ul>
                            {this.state.symposia.map(item => (<li><Link to={`/admin/symposiaform/${item.id}`}>{item.organizationName}</Link></li>))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminSymposia;
