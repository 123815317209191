import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Icon from "./Icon.js";
class PromoCode extends Component {
    constructor (props) {
        super(props);
        this.state = {
            promocode: "",
            errorMessage: ""
        }
        this.handlePromoCodeChange = this.handlePromoCodeChange.bind(this);
        this.submitPromoCode = this.submitPromoCode.bind(this);
    }
    componentDidMount() {
    }
    handlePromoCodeChange(event) {
        this.setState({promocode: event.target.value});
    }
    submitPromoCode(event) {
        event.preventDefault();
        if (this.state.promocode) {
            axios({
                method: "post",
                url: "/api/submitPromoCode",
                data: {
                    promocode: this.state.promocode
                }
            }).then(res => {
                this.setState({errorMessage: res.data.message});
            }, () => {
                this.setState({errorMessage: "Invalid promo code."});
            });
        }
    }
    render() {
        return (
            <div class="row align-items-center m-t-lg">
                <div class="col-md-12 col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href="/account">
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back</span>
                        </a>
                    </div>
                    <div class="card raised">
                        <div class="card-block p-a-sm bg-primary-light text-center text-inverse">
                            <h2 class="text-lg m-b-0">Enter Promo Code</h2>
                        </div>
                        <div class="card-block">
                            <div class="form-group text-lg">
                                <input type="text" name="promocode" class="form-control form-control-lg" id="promocode" placeholder="Promo Code" value={this.state.keywords} onChange={this.handlePromoCodeChange} />
                                {!!this.state.errorMessage && <p>{this.state.errorMessage}</p>}
                            </div>
                            <button type="submit" class="btn btn-orange btn-lg btn-block" onClick={this.submitPromoCode}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PromoCode;
