import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import Icon from "./Icon.js";

class SAPMOCVideos extends Component {
    constructor (props) {
        super(props);
        const initialState = {
            toQuestions: false,
            noticeContent: "",
            sapmocid: this.props.match.params.tid
        };
        this.state = initialState;
    }
    componentDidMount() {
        axios({
            method: "get",
            url: `/api/recs/${this.state.sapmocid}`,
        }).then(res => {
            console.log(res)
            this.setState({noticeContent: res.data});
        })
    }
    render() {
        return (
            <>
            <div className="row">
                <div className="col-xs-12" dangerouslySetInnerHTML={{__html: this.state.noticeContent}}>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <a href={`/sapmoc/${this.state.sapmocid}/questions`} className="btn btn-sm btn-primary">go to the test</a>
                </div>
            </div>
            </>
        )
    }
}

export default SAPMOCVideos;
