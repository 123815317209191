import React, { Component } from 'react';
import Icon from "./Icon.js";

class TestSideBoxIntro extends Component {
    constructor (props) {
        super(props);
        this.state = {
            recordView: this.props.recordView
        };
        console.log(this.state.recordView)
    }
    render() {
        return (
            <div className="bg-gray-lightest p-a">
                <div className="btn-group btn-group-justified">
                    <a className="btn btn-primary btn-lg" onClick={this.state.recordView} href={this.props.doc.url} target="_blank" rel="noopener noreferrer">
                        <span className="p-r-sm">Read Article</span>
                        <Icon passedClass="icon-open_in_new" />
                    </a>
                </div>
            </div>
        )
    }
}

export default TestSideBoxIntro;
