import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import AccountChooser from './AccountChooser.js';
import PaymentForm from './PaymentForm.js';
import SAPMOCNotice from './SAPMOCNotice.js';
import moment from 'moment';

class SAPMOC extends Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: {},
            multi: true,
            accounts: []
        };
        this.getData = this.getData.bind(this)
    }
    getData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.id,
        }).then(res => {
            this.setState({doc: res.data.result[0]});
        })
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/sapmocsession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            } else {
                this.getData();
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
                this.getData();
            }
        })
    }

    render() {
        let newText = false,
            paid = this.state.doc.original_id && this.state.doc.user["paid" + this.state.doc.original_id] === "yes",
            submitted = this.state.doc.original_id && this.state.doc.user["submitted" + this.state.doc.original_id] === "yes",
            notice = this.state.doc.original_id && this.state.doc.user["notice" + this.state.doc.original_id] === true,
            mocsubmitted = this.state.doc.original_id && this.state.doc.user["mocsubmitted" + this.state.doc.original_id] === true,
            complete = this.state.doc.original_id && this.state.doc.user["complete" + this.state.doc.original_id] === "done",
            timescomplete = this.state.doc.original_id && this.state.doc.user["timescomplete" + this.state.doc.original_id] ? this.state.doc.user["timescomplete" + this.state.doc.original_id] : 0,
            evalcomplete = this.state.doc.original_id && this.state.doc.user["eval" + this.state.doc.original_id] === "done",
            expired = new Date(this.state.doc.expiration_date).getTime(),
            now = new Date().getTime(),
            isExpired = now > expired,
            showRecs = timescomplete === 1 || timescomplete === 2,
            accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="sapmoc" accounts={this.state.accounts} />) : "";

        const dateToFormat = moment(this.state.doc.publish_date).utc();
        const expirationDateFormat = moment(this.state.doc.expiration_date).utc();
        console.log("Complete", complete, timescomplete)

        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-12">
                            {accountChooser}
                            {!this.state.multi && <><h3>{this.state.doc.title}</h3>
                            {complete && isExpired && <div class="alert alert-top alert-success">Congratulations! You have successfully completed ACG&apos;s Self-Assessment Program for Maintenance of Certification (ACG SAP-MOC). <strong>This test is no longer eligible for MOC or CME credit.</strong> </div>}
                            {complete && !isExpired && evalcomplete && mocsubmitted && <div class="alert alert-top alert-success">Congratulations! You have successfully completed  ACG&apos;s Self-Assessment Program for Maintenance of Certification (ACG SAP-MOC) and are eligible for CME credits and MOC points. See your <a href={`/api/sapmoc/sapmoc_${this.state.doc.original_id}/certificate.pdf`} target="_blank">CME certificate</a>, see your <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/questions`}>review the questions</a>. MOC Submitted.</div>}
                            {complete && !isExpired && evalcomplete && !mocsubmitted && <div class="alert alert-top alert-success">Congratulations! You have successfully completed  ACG&apos;s Self-Assessment Program for Maintenance of Certification (ACG SAP-MOC) and are eligible for CME credits and MOC points. See your <a href={`/api/sapmoc/sapmoc_${this.state.doc.original_id}/certificate.pdf`} target="_blank">CME certificate</a>, see your <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/questions`}>review the questions</a>. <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/submit`}>submit your information to ACCME for the purpose of granting ABIM MOC credit</a>.</div>}
                            {complete && !isExpired && !evalcomplete && <div class="alert alert-top alert-success">Congratulations! You have successfully completed ACG&apos;s Self-Assessment Program for Maintenance of Certification (ACG SAP-MOC) and are eligible for CME credits and MOC points. Please <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/evaluation`}>click here</a> to complete a brief evaluation (the evaluation must be completed to be able to print your certificate and to submit for MOC points). You may also see your <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/scores`}>scores</a> or <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/questions`}>review the questions</a>.</div>}
                            {!complete && isExpired && <p>This test has now expired for MOC and CME credits.  You may continue the test, but no credits will be awarded.</p>}
                            {!complete && !isExpired && <div class="alert alert-warning">To receive MOC and CME credit, you must successfully pass the test with a minimum score of 70% (a maximum of 3 attempts permitted).  The link to submit your data to ACCME for the purpose of granting ABIM MOC credit will only be provided if you have achieved a passing score.   Your attempts: {timescomplete}.
                            {showRecs && <p><a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/recs`}>Review these recommendations before attempting again</a></p>}
                            </div>}
                            {!paid && this.state.doc && this.state.doc.original_id && <PaymentForm doctitle={this.state.doc.title} identifier={"sapmoc-" + this.state.doc.original_id} amount={this.state.doc.testcost} />}
                            {!complete && paid && notice && this.state.doc && this.state.doc.original_id && <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/questions`} className="btn btn-sm btn-primary m-b-lg">Continue the Test</a>}
                            <div className="alert alert-primary">
                                <strong>Release date:</strong> <Moment format="MM/DD/YYYY" date={dateToFormat} /><br />
                                <strong>Expiration date:</strong> <Moment format="MM/DD/YYYY" date={expirationDateFormat} /> (no MOC points can be awarded after this date)
                                <br /><br /><a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/recs`}>Video Recommendations</a>
                                <br /><br />*IMPORTANT: Requests for MOC points MUST be submitted by December 31 of the year you COMPLETE the test. If you complete the test in its final year of release, you must claim credits prior to the expiration date. No retroactive credit will be accepted by ABIM.
                            </div>
                            {!complete && paid && !notice && this.state.doc && this.state.doc.original_id && <SAPMOCNotice testid={this.state.doc.original_id} />}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SAPMOC;
