import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import { SelectOptions } from './SelectOptions';
import moment from 'moment';
import ReCaptchaComponent from './GoogleReCaptcha';

class PaymentForm extends Component {
    constructor (props) {
        super(props);
        const initialState = {
            captcha: null,
            showCaptcha: true,
            formValid: true,
            cb: this.props.cb,
            doctitle: this.props.doctitle,
            creditCardTypes : [{
                code: "001",
                name: "Visa"
            },{
                code: "002",
                name: "MasterCard"
            },{
                code: "003",
                name: "American Express"
            }],
            identifier: this.props.identifier,
            isCredits: this.props.identifier.indexOf("getCredits") === 0,
            expireMonths: [1,2,3,4,5,6,7,8,9,10,11,12],
            expireYears: (function getYears(){
                var year = new Date().getFullYear(),
                    years = [];

                for (var i = 0; i != 20; ++i) {
                    years.push(i + year)
                }
                return years;
            })(),
            requiredFields: [
                "ecom_billto_postal_name_first",
                "ecom_billto_postal_name_last",
                "ecom_billto_online_email",
                "ecom_billto_postal_street_line1",
                "ecom_billto_postal_countrycode",
                "ecom_billto_postal_city",
                "ecom_billto_postal_stateprov",
                "ecom_billto_postal_postalcode",
                "ecom_payment_card_type",
                "ecom_payment_card_number",
                "ecom_cvn_number",
                "ecom_payment_card_expdate_month",
                "ecom_payment_card_expdate_year"
            ],
            amount: this.props.hours ? this.props.hours * 25 : this.props.amount,
            hours: this.props.hours,
            states: [],
            provinces: [],
            duesError: "",
            formErrors: {},
            payError: false,
            countries: [],
            ecom_billto_postal_name_first: "",
            ecom_billto_postal_name_last: "",
            ecom_billto_online_email: "",
            ecom_billto_postal_street_line1: "",
            ecom_billto_postal_street_line2: "",
            ecom_billto_postal_countrycode: "",
            ecom_billto_postal_city: "",
            ecom_billto_postal_stateprov: "",
            ecom_billto_postal_postalcode: "",
            ecom_payment_card_type: "",
            ecom_payment_card_number: "",
            ecom_cvn_number: "",
            ecom_payment_card_expdate_month: "",
            ecom_payment_card_expdate_year: ""
        };
        initialState.requiredFields.forEach(field => {
            initialState.formErrors[field] = '';
        });
        this.state = initialState;
        this.setCaptcha = this.setCaptcha.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        return {
            hours: nextProps.hours,
            amount: nextProps.hours ? nextProps.hours * 25 : nextProps.amount,
            // ... other derived state properties
        };
    }
    setCaptcha (captcha) {
        this.setState({ captcha },() => {
        });
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        if (this.state.requiredFields.indexOf(fieldName) > -1) {
            fieldValidationErrors[fieldName] = value.length > 0 ? '' : ' is required';
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm(callback) {
        let valid = true;
        for (var field in this.state.formErrors) {
            if (this.state.formErrors[field].length) {
                valid = false;
            }
        }
        this.setState({
            formValid: valid
        }, () => {
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    componentDidMount() {
        axios({
            method: "get",
            url: "/api/states",
        }).then(res => {
            this.setState({states: res.data.result});
        })
        axios({
            method: "get",
            url: "/api/provinces",
        }).then(res => {
            this.setState({provinces: res.data.result});
        })
        axios({
            method: "get",
            url: "/api/countries",
        }).then(res => {
            this.setState({countries: res.data.result});
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        this.state.requiredFields.forEach(field => {
            this.validateField(field, this.state[field]);
        });
        var docBody = Object.assign({}, this.state);
        delete docBody.cb;
        delete docBody.states;
        delete docBody.provinces;
        delete docBody.formErrors;
        delete docBody.countries;
        this.validateForm(() => {
            if (this.state.formValid) {
                this.setState({"payError" : false});
                axios({
                    method: "post",
                    url: "/api/payment", 
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: docBody
                }).then(res => {
                    if (this.state.isCredits) {
                        if (typeof this.state.cb === "function") {
                            this.state.cb();
                        }
                    } else {
                        window.location.reload();
                    }
                }, (err) => {
                    this.setState({"payError" : true, "showCaptcha": false}, () => {
                        this.setState({"showCaptcha": true});
                    });
                });
            }
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-xs-12">
                    {this.state.showCaptcha && <ReCaptchaComponent setCaptcha={this.setCaptcha} />}
                    {!!this.state.captcha && <form onSubmit={this.handleSubmit} className="demoForm">
                    <div class="modal-body">
                        <p><em>Required Fields <sup>*</sup></em></p>
                        {!!this.state.payError && <div className={`alert alert-top alert alert-danger`}>
                            <strong>There was an error with the payment.</strong>
                        </div>}
                        <fieldset class="form-group">
                            <label for="Dues">Amount of Payment to Charge Your Credit Card</label>
                            <div>${this.state.amount}</div>
                        </fieldset>

                        <h5>Billing Address</h5>
                        <div class="row">
                            <fieldset class="form-group col-xs-6">
                                <label for="ecom_billto_postal_name_first">First Name<sup>*</sup></label>
                                <input id="ecom_billto_postal_name_first" name="ecom_billto_postal_name_first" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.ecom_billto_postal_name_first} />
                                {this.state.formErrors.ecom_billto_postal_name_first && <div role="alert" class="text-danger">
                                    <div ng-message="required">Please enter required information.</div>
                                </div>}
                            </fieldset>

                            <fieldset class="form-group col-xs-6">
                                <label for="ecom_billto_postal_name_last">Last Name<sup>*</sup></label>
                                <input id="ecom_billto_postal_name_last" name="ecom_billto_postal_name_last" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.ecom_billto_postal_name_last} />
                                {this.state.formErrors.ecom_billto_postal_name_last && <div role="alert" class="text-danger">
                                    <div ng-message="required">Please enter required information.</div>
                                </div>}
                            </fieldset>
                        </div>

                        <fieldset class="form-group">
                            <label for="ecom_billto_online_email">Email Address<sup>*</sup></label>
                            <input name="ecom_billto_online_email" id="ecom_billto_online_email" type="email" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.ecom_billto_online_email} />
                            {this.state.formErrors.ecom_billto_online_email && <div role="alert" class="text-danger">
                                <div ng-message="required">Please enter required information.</div>
                                { /*<div ng-message="email">This field must be a valid email address.</div>
                                <div ng-message="maxlength">This field can be at most 15 characters long.</div> */}
                            </div>}
                        </fieldset>

                        <fieldset class="form-group">
                            <label for="ecom_billto_postal_street_line1">Address 1<sup>*</sup></label>
                            <input id="ecom_billto_postal_street_line1" name="ecom_billto_postal_street_line1" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.ecom_billto_postal_street_line1} />
                            {this.state.formErrors.ecom_billto_postal_street_line1 && <div role="alert" class="text-danger">
                                <div ng-message="required">Please enter required information.</div>
                            </div>}
                        </fieldset>

                        <fieldset class="form-group">
                            <label for="ecom_billto_postal_street_line2">Address 2</label>
                            <input id="ecom_billto_postal_street_line2" name="ecom_billto_postal_street_line2" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.ecom_billto_postal_street_line2} />
                        </fieldset>

                        <fieldset class="form-group">
                            <label for="Country">Country<sup>*</sup></label>
                            <select onChange={(event) => this.handleUserInput(event)} className="form-control" name="ecom_billto_postal_countrycode" value={this.state.ecom_billto_postal_countrycode}>
                                <option value="">Country...</option>
                                {this.state.countries.map((fieldName, i) => <SelectOptions key={i+fieldName} fieldName={fieldName} />)}
                            </select>
                            {this.state.formErrors.ecom_billto_postal_countrycode && <div role="alert" class="text-danger">
                                <div ng-message="required">Please enter required information.</div>
                            </div>}
                        </fieldset>

                        <div class="row">
                            <fieldset class="form-group col-xs-6">
                                <label for="ecom_billto_postal_city">City<sup>*</sup></label>
                                <input id="ecom_billto_postal_city" name="ecom_billto_postal_city" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.ecom_billto_postal_city} />
                                {this.state.formErrors.ecom_billto_postal_city && <div role="alert" class="text-danger">
                                    <div ng-message="required">Please enter required information.</div>
                                </div>}
                            </fieldset>

                            <fieldset class="form-group col-xs-6">
                                <label for="State">State/Province<sup>*</sup></label>
                                {this.state.ecom_billto_postal_countrycode  == 'United States' && <select onChange={(event) => this.handleUserInput(event)} name="ecom_billto_postal_stateprov" class="form-control" value={this.state.ecom_billto_postal_stateprov}>
                                    <option value="">State...</option>
                                    {this.state.states.map((fieldName, i) => <SelectOptions key={i+fieldName} fieldName={fieldName} />)}
                                </select>}
                                {this.state.ecom_billto_postal_countrycode  == 'Canada' && <select onChange={(event) => this.handleUserInput(event)} name="ecom_billto_postal_stateprov" class="form-control" value={this.state.ecom_billto_postal_stateprov}>
                                    <option value="">Province...</option>
                                    {this.state.provinces.map((fieldName, i) => <SelectOptions key={i+fieldName} fieldName={fieldName} />)}
                                </select>}
                                {(this.state.ecom_billto_postal_countrycode != 'United States' && this.state.ecom_billto_postal_countrycode != 'Canada') && <input name="ecom_billto_postal_stateprov" type="text" class="form-control form-control-sm" value={this.state.ecom_billto_postal_stateprov} onChange={(event) => this.handleUserInput(event)} />}
                                {this.state.formErrors.ecom_billto_postal_stateprov && <div role="alert" class="text-danger">
                                    <div ng-message="required">Please enter required information.</div>
                                </div>}
                            </fieldset>
                        </div>

                        <div class="row">
                            <fieldset class="form-group col-xs-6">
                                <label for="ecom_billto_postal_postalcode">Zip/Postal Code<sup>*</sup></label>
                                <input id="ecom_billto_postal_postalcode" name="ecom_billto_postal_postalcode" type="text" class="form-control form-control-sm" size="40" value={this.state.ecom_billto_postal_postalcode} onChange={(event) => this.handleUserInput(event)} />
                                {this.state.formErrors.ecom_billto_postal_postalcode && <div role="alert" class="text-danger">
                                    <div ng-message="required">Please enter required information.</div>
                                </div>}
                            </fieldset>
                        </div>

                        <div class="row">
                            <fieldset class="form-group col-xs-6">
                                <label for="ecom_payment_card_type">Credit Card Type<sup>*</sup></label>
                                <select onChange={(event) => this.handleUserInput(event)} name="ecom_payment_card_type" class="form-control" value={this.state.ecom_payment_card_type}>
                                    <option value="">Credit Card Type...</option>
                                    {this.state.creditCardTypes.map((credit, i) => <SelectOptions key={i+credit.name} fieldName={credit.name} value={credit.code} />)}
                                </select>
                                {this.state.formErrors.ecom_payment_card_type && <div role="alert" class="text-danger">
                                    <div ng-message="required">Please enter required information.</div>
                                </div>}
                            </fieldset>
                        </div>

                        <div class="row">
                            <fieldset class="form-group col-xs-6">
                                <label for="ecom_payment_card_number">Credit Card Number<sup>*</sup></label>
                                <input onChange={(event) => this.handleUserInput(event)} value={this.state.ecom_payment_card_number} id="ecom_payment_card_number" name="ecom_payment_card_number" type="text" class="form-control form-control-sm" size="40" autocomplete="off" />
                                {this.state.formErrors.ecom_payment_card_number && <div role="alert" class="text-danger">
                                    <div ng-message="required">Please enter required information.</div>
                                </div>}
                            </fieldset>

                            <fieldset class="form-group col-xs-6">
                                <label for="ecom_cvn_number">Security Code<sup>*</sup> <span class="info-sign" data-toggle="tooltip" data-placement="right" title="The card security code is located on the back of MasterCard and Visa credit or debit cards and is typically a separate group of 3 digits to the right of the signature strip. On American Express cards, the card security code is a printed, not embossed, group of four digits on the front towards the right."></span></label>
                                <input id="ecom_cvn_number" name="ecom_cvn_number" type="text" class="form-control form-control-sm" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.ecom_cvn_number} autocomplete="off" />
                                {this.state.formErrors.ecom_cvn_number && <div role="alert" class="text-danger">
                                    <div ng-message="required">Please enter required information.</div>
                                </div>}
                            </fieldset>
                        </div>
                    </div>

                    <fieldset class="form-group" ng-show="!duesThanks">
                        <div class="col-sm-6">
                            <label>Credit Card Expiration Month<sup>*</sup></label>
                            <select onChange={(event) => this.handleUserInput(event)} name="ecom_payment_card_expdate_month" class="form-control" value={this.state.ecom_payment_card_expdate_month}>
                                <option value="">Month...</option>
                                {this.state.expireMonths.map((month, i) => <SelectOptions key={i+month} fieldName={month} />)}
                            </select>
                            {this.state.formErrors.ecom_payment_card_expdate_month && <div role="alert" class="text-danger">
                                <div ng-message="required">Please enter required information.</div>
                            </div>}
                        </div>
                        <div class="col-sm-6">
                            <label>Credit Card Expiration Year<sup>*</sup></label>
                            <select onChange={(event) => this.handleUserInput(event)} name="ecom_payment_card_expdate_year" class="form-control" value={this.state.ecom_payment_card_expdate_year}>
                                <option value="">Year...</option>
                                {this.state.expireYears.map((year, i) => <SelectOptions key={i+year} fieldName={year} />)}
                            </select>
                            {this.state.formErrors.ecom_payment_card_expdate_year && <div role="alert" class="text-danger">
                                <div ng-message="required">Please enter required information.</div>
                            </div>}
                        </div>
                    </fieldset>

                    <div class="modal-footer" ng-show="!duesThanks">
                        {!!this.state.payError && <div className={`alert alert-top alert alert-danger`}>
                            <strong>There was an error with the payment.</strong>
                        </div>}
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button ng-disabled="payingDues" type="submit" class="btn btn-primary">Pay Now</button>
                    </div>
                    </form>}
                </div>
            </div>
        )
    }
}

export default PaymentForm;
