import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, NavLink } from "react-router-dom";
import axios from 'axios';
import Auth from './Auth.js';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

export default PrivateRoute;
