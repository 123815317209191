import React from 'react';
import { Modal } from 'react-bootstrap';
import DocumentListing from './DocumentListing.js';
import './DocumentList.css';
import axios from 'axios';
import AccountChooser from './AccountChooser.js';
import CTAs from './CTAs.js';
import Icon from "./Icon.js";
import Spinner from 'react-spinkit';
import qs from 'query-string';
import { SelectOptions } from './SelectOptions';
import StickyBox from "react-sticky-box";
import _ from "lodash";

export default class DocumentList extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            credits: 0,
            sessionChecked: false,
            tagFilterName: null,
            userData: {},
            user: "known",
            authenticated: false,
            loading: false,
            passedCME: this.props.location && this.props.location.search ? qs.parse(this.props.location.search).cmeonly ? qs.parse(this.props.location.search).cmeonly === "yes" : false : false,
            passedMOC: this.props.location && this.props.location.search ? qs.parse(this.props.location.search).moconly ? qs.parse(this.props.location.search).moconly === "yes" : false : false,
            passedEvents: this.props.location && this.props.location.search && this.props.location.search.indexOf("events") > -1 ? qs.parse(this.props.location.search).events.split(",") : [],
            passedTags: this.props.location && this.props.location.search && this.props.location.search.indexOf("tag") > -1 ? [qs.parse(this.props.location.search).tag] : [],
            passedType: this.props.location && this.props.location.search && this.props.location.search.indexOf("filter") > -1 ? qs.parse(this.props.location.search).filter.split(",") : [],
            passedSpecial: this.props.location && this.props.location.search && this.props.location.search.indexOf("special") > -1 ? qs.parse(this.props.location.search).special : [],
            passedKeywords: this.props.location && this.props.location.search && this.props.location.search.indexOf("keyword") > -1 ? qs.parse(this.props.location.search).keyword.split(",") : [],
            passedAuthors: this.props.location && this.props.location.search && this.props.location.search.indexOf("authors") > -1 ? qs.parse(this.props.location.search).authors.split(",") : [],
            format: "grid",
            filtered: false,
            counts: {},
            docs: [],
            total: 0,
            authors: "",
            keywords: "",
            usedKeyword: "",
            usedAuthor: "",
            page: 1,
            displayAll: false,
            pageSize: 120,
            sort: (this.props.mode === "complete" || this.props.mode === "account") ? "aiadded" : "datero",
            events: [],
            tags: [],
            filters: [],
            cmeonly: false,
            moconly: false,
            docTypes: [
                "Presentation",
                "Module",
                "Self-Assessment Test",
                "Self-Assessment Program for Maintenance of Certification",
                "Journal",
                "Podcast"
            ],
            multijournals: true,
            multi: true,
            accountsSATest: [],
            accountsJournals: [],
            accounts: [],
            tagLibrary: {},
            tagLibraryCreated: false,
            usedTags: []
        }
        this.getData = this.getData.bind(this)
        this.handleFormat = this.handleFormat.bind(this);
        this.handleDisplayAll = this.handleDisplayAll.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleAuthorsChange = this.handleAuthorsChange.bind(this);
        this.authorSearch = this.authorSearch.bind(this);
        this.keywordSearch = this.keywordSearch.bind(this);
        this.handleEventLink = this.handleEventLink.bind(this);
        this.handleTagLink = this.handleTagLink.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.handleCME = this.handleCME.bind(this);
        this.handleMOC = this.handleMOC.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }
    handleFormat(e, format) {
        e.preventDefault();
        this.setState({format}, console.log(e, format, this.state));
    }
    handleDisplayAll(e, state) {
        e.preventDefault();
        this.setState({displayAll: !this.state.displayAll});
    }
    clearFilters(event) {
        event.preventDefault();
        this.setState({
            tags: [],
            filters: [],
            events: [],
            authors: "",
            moconly: false,
            cmeonly: false,
            keywords: "",
            usedKeyword: "",
            usedAuthor: "",
            page: 1,
            filtered: false
        }, () => {
            this.getData(this.state.page, this.state.pageSize);
        });
    }
    handleMOC(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({moconly: target.checked}, () => {
            this.setState({ page: 1 });
            this.getData(this.state.page, this.state.pageSize);
        });
    }
    handleCME(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({cmeonly: target.checked}, () => {
            this.setState({ page: 1 });
            this.getData(this.state.page, this.state.pageSize);
        });
    }
    handleSort(event) {
        if (event.target && event.target.value) {
            this.setState({ page: 1, sort: event.target.value }, () => {
                this.getData(this.state.page, this.state.pageSize);
            });
        }
    }
    handlePage(event) {
        if (event.target && event.target.value) {
            this.setState({ page: 1, pageSize: event.target.value }, () => {
                this.getData(this.state.page, this.state.pageSize);
            });
        }
    }
    handleTagLink (tag) {
        this.handleTag({
            target: {
                name: null,
                value: tag.tag + ""
            }
        });
    }
    handleTag (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(state => {
            const tags = [value];

            return {
                tags
            };
        }, () => {
            this.setState({ page: 1 });
            this.getData(this.state.page, this.state.pageSize);
        });
    }
    handleEventLink (o) {
        this.handleEvent({
            target: {
                name: null,
                value: o + ""
            }
        });
    }
    handleEvent (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(state => {
            const events = [value];

            return {
                events
            };
        }, () => {
            this.setState({ page: 1 });
            this.getData(this.state.page, this.state.pageSize);
        });
    }
    handleFilters(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.checked) {
            this.setState(state => {
                const filters = state.filters.concat(name);

                return {
                    filters
                };
            }, () => {
                this.setState({ page: 1 }, () => {
                    this.getData(this.state.page, this.state.pageSize);
                });
            });
        } else {
            this.setState(state => {
                const filters = state.filters.filter(item => item !== name);

                return {
                    filters
                };
            }, () => {
                this.setState({ page: 1 }, () => {
                    this.getData(this.state.page, this.state.pageSize);
                });
            });
        }
    }
    authorSearch(event) {
        this.setState({ usedAuthor: "", page: 1 }, () => {
            this.getData(this.state.page, this.state.pageSize);
        });
    }
    keywordSearch(event) {
        this.setState({ usedKeyword: "", page: 1 }, () => {
            this.getData(this.state.page, this.state.pageSize);
        });
    }
    handleAuthorsChange(event) {
        this.setState({authors: event.target.value});
    }
    handleKeywordChange(event) {
        this.setState({keywords: event.target.value});
    }
    componentDidMount() {
        let that = this;
        window.addEventListener( "pageshow", function ( event ) {
            var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );
            if ( historyTraversal ) {
                setTimeout(() => that.getData(null, null, true), 50);
            }
        });
        this.setState({filtered: !!(this.props.mode === "complete" || this.state.passedMOC || this.state.passedCME || this.state.passedEvents.length || this.state.passedTags.length || this.state.passedSpecial.length || this.state.passedType.length || this.state.passedKeywords.length || this.state.passedAuthors.length), displayAll: !!(this.state.passedMOC || this.state.passedCME || this.state.passedEvents.length || this.state.passedTags.length || this.state.passedSpecial.length || this.state.passedType.length || this.state.passedKeywords.length || this.state.passedAuthors.length)});
        axios({
            method: "get",
            url: "/api/universesession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            this.setState({authenticated: true}, () => {
                this.setState({sessionChecked: true});
            });
            this.setState({userData: sares.data.user});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            } else {
                axios({
                    method: "get",
                    url: "/api/journalsession",
                }).then(jores => {
                    this.setState({multijournals: jores.data.multi});
                    if (jores.data.multi) {
                        this.setState({accountsJournals: jores.data.result});
                    } else {
                        axios({
                            method: "get",
                            url: "/api/satestsession",
                        }).then(sares => {
                            this.setState({multisatest: sares.data.multi});
                            if (sares.data.multi) {
                                this.setState({accountsSATest: sares.data.result});
                            } else {
                                axios({
                                    method: "get",
                                    url: "/api/getTotal",
                                }).then(total => {
                                    this.setState({credits: total.data.total});
                                });
                                if (this.state.passedType.length || this.state.passedSpecial.length || this.state.passedTags.length || this.state.passedEvents.length || this.state.passedAuthors.length || this.state.passedKeywords.length || this.state.passedCME || this.state.passedMOC) {
                                    let filters = [],
                                        tags = [],
                                        events = [],
                                        authors = [],
                                        keywords = [];

                                    this.state.passedEvents.forEach(item => {
                                        events.push(item);
                                    })
                                    this.state.passedTags.forEach(item => {
                                        tags.push(item);
                                    })
                                    this.state.passedType.forEach(item => {
                                        if (this.state.docTypes.indexOf(item) > -1) {
                                            filters.push(item);
                                        }
                                    })
                                    this.state.passedAuthors.forEach(item => {
                                        authors.push(item);
                                    })
                                    this.state.passedKeywords.forEach(item => {
                                        keywords.push(item);
                                    })
                                    this.setState(state => {
                                        return {
                                            filters,
                                            tags,
                                            moconly: this.state.passedMOC,
                                            cmeonly: this.state.passedCME,
                                            events,
                                            authors: authors.join(" "),
                                            keywords: keywords.join(" ")
                                        };
                                    }, () => {
                                        this.setState({ page: 1 });
                                        this.getData(this.state.page, this.state.pageSize);
                                    });
                                } else {
                                    this.getData(this.state.page, this.state.pageSize);
                                }
                            }
                        })
                    }
                })
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                if (this.state.passedTags.length || this.state.passedSpecial.length || this.state.passedType.length || this.state.passedEvents.length || this.state.passedKeywords.length || this.state.passedCME || this.state.passedMOC) {
                    let filters = [],
                        tags = [],
                        authors = [],
                        events = [],
                        keywords = [];

                    this.state.passedEvents.forEach(item => {
                        events.push(item);
                    })
                    this.state.passedTags.forEach(item => {
                        tags.push(item);
                    })
                    this.state.passedType.forEach(item => {
                        if (this.state.docTypes.indexOf(item) > -1) {
                            filters.push(item);
                        }
                    })
                    this.state.passedAuthors.forEach(item => {
                        authors.push(item);
                    })
                    this.state.passedKeywords.forEach(item => {
                        keywords.push(item);
                    })
                    this.setState(state => {
                        return {
                            filters,
                            tags,
                            events,
                            moconly: this.state.passedMOC,
                            cmeonly: this.state.passedCME,
                            authors: authors.join(" "),
                            keywords: keywords.join(" ")
                        };
                    }, () => {
                        this.setState({ page: 1 });
                        // this.getData(this.state.page, this.state.pageSize);
                    });
                } else {
                    // this.getData(this.state.page, this.state.pageSize);
                }
                this.setState({multi: false});
                this.setState({user: "anonymous"}, () => {
                    this.setState({sessionChecked: true});
                });
                this.getData(this.state.page, this.state.pageSize);
            }
        })
    }
    getData(page, pageSize, dontScroll) {
        this.setState({loading: true});
        if (!!!page) page = this.state.page;
        if (!!!pageSize) pageSize = this.state.pageSize;
        const requestParams = {
            method: "get",
            url: this.props.mode === "account" ? "/api/getAccountContent" : this.props.mode === "complete" ? "/api/getAccountContentComplete" : "/api/getContent",
            params: {
                _: (new Date().getTime()),
                page: page,
                sort: this.state.sort,
                pageSize: pageSize
            }
        };
        if (this.state.moconly) {
            requestParams.params.moconly = "yes";
            this.setState({filtered: true, displayAll: true});
        }
        if (this.state.cmeonly) {
            requestParams.params.cmeonly = "yes";
            this.setState({filtered: true, displayAll: true});
        }
        if (this.state.authors.length) {
            this.setState({usedAuthor: this.state.authors});
            requestParams.params.authorsExact = this.state.authors;
            requestParams.params.authors = this.state.authors.split(" ").join(",");
            this.setState({filtered: true, displayAll: true});
        }
        if (this.state.keywords.length) {
            requestParams.params.keywords = this.state.keywords.split(" ").join(",");
            requestParams.params.keywordsExact = this.state.keywords;
            this.setState({usedKeyword: this.state.keywords});
            this.setState({filtered: true, displayAll: true});
        }
        if (this.state.passedSpecial.length) {
            requestParams.params.special = this.state.passedSpecial;
            this.setState({filtered: true, displayAll: true});
        }
        if (this.state.filters.length) {
            requestParams.params.filters = this.state.filters.join(",").toLowerCase();
            this.setState({filtered: true, displayAll: true});
        }
        if (this.state.tags.length) {
            requestParams.params.tags = this.state.tags.join(",");
            this.setState({filtered: true, displayAll: true});
        }
        if (this.state.events.length) {
            requestParams.params.events = this.state.events.join(",").toLowerCase();
            this.setState({filtered: true, displayAll: true});
        }
        axios(requestParams).then(res => {
            console.log(res);
            console.log(res.data);
            const docs = res.data.result;
            const total = res.data.total;
            const counts = res.data.counts;
            const tagFilterName = res.data.tagFilterName;
            let tagColl = [],
                  tagLibrary = {};

            _.forEach(docs, (item) => {
                if (item.tags) {
                    tagColl = tagColl.concat(item.tags);
                }
            });
            _.forEach(tagColl, (tag) => {
                tagLibrary[tag.tag] = tag;
            });
            this.setState({tagLibrary: Object.assign(this.state.tagLibrary, tagLibrary)});
            this.setState({tagLibraryCreated: true});
            if (!!!dontScroll) window.scrollTo(0, 0);
            this.setState({loading: false});
            this.setState({docs: []});
            this.setState({counts: {}});
            this.setState({ docs, counts, total, page, pageSize, tagFilterName });
        }, err => {
            console.log(err);
        });
    }
    render() {
        let displayClass = this.state.displayAll ? " is-toggled" : "";
        let accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="universe" accounts={this.state.accounts} />) : "";
        let accountChooserJournals = this.state.accountsJournals.length > 0 ? (<AccountChooser accountType="journal" accounts={this.state.accountsJournals} />) : "";
        let accountChooserSATest = this.state.accountsSATest.length > 0 ? (<AccountChooser accountType="satest" accounts={this.state.accountsSATest} />) : "";
        let pageControls = (<div class="pagination-controls">
                                <div>
                                    <div class="text-xs">Showing {!!this.state.docs.length && this.state.docs[0]["__RN"]}-{!!this.state.docs.length && this.state.docs[this.state.docs.length - 1]["__RN"]} of {this.state.total && <span>{this.state.total}</span>}
                                        <div class="form-select form-select-sm m-l-xs">
                                            <select onChange={(event) => this.handlePage(event)}>
                                                <option value="48">48 items</option>
                                                <option value="72">72 items</option>
                                                <option value="96">96 items</option>
                                                <option value="120" selected>120 items</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ul className="pagination pagination-xs m-b-0 m-t-sm m-lg-a-0 text-system">
                                    { [...Array(Math.ceil(this.state.total/this.state.pageSize))].map((_, i) => (<li className={this.state.page === i+1 ? 'active' : ''}><a className="void" href="#" onClick={(event) => {this.getData(i+1, this.state.pageSize, true);event.preventDefault();}}>{i + 1}</a></li>))}
                                    </ul>
                                </div>
                                <div>
                                    <div class="form-select form-select-sm m-l-xs">
                                        {this.props.mode !== "account" && this.props.mode !== "complete" && <select onChange={(event) => this.handleSort(event)}>
                                            <option value="nameaz">Name (A-Z)</option>
                                            <option value="nameza">Name (Z-A)</option>
                                            <option value="datero" selected>Date: Recent - Oldest</option>
                                            <option value="dateor">Date: Oldest - Recent</option>
                                            <option value="cme"># of credits</option>
                                        </select>}
                                    </div>
                                </div>
                                <div>
                                    <div class="btn-group">
                                        <a class={`btn btn-sm btn-white ${this.state.format === "list" ? "active" : ""}`} href="#" onClick={(event) => {this.handleFormat(event, "list")}} title="List View" id="list"><svg class="icon icon-normal" arial-label="List View"><Icon passedClass="icon-menu2" /></svg></a>
                                        <a class={`btn btn-sm btn-white ${this.state.format === "grid" ? "active" : ""}`} href="#" onClick={(event) => {this.handleFormat(event, "grid")}} title="Grid View" id="grid"><svg class="icon icon-normal" arial-label="Grid View"><Icon passedClass="icon-grid" /></svg></a>
                                    </div>
                                </div>
                            </div>);
        let wrapClass = this.state.format === "list" ? "m-t-md p-t border-t border-1 border-gray-light" : "row m-t-md grid-view";
        return (
            <div>
                <div id="top"></div>
                {this.state.loading && <div class="spinner-wrap"><Spinner name='double-bounce' /></div>}
                <div className="row">
                    {accountChooser}
                    {accountChooserJournals}
                    {accountChooserSATest}
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <StickyBox className="sticky-parent" offsetTop={20} offsetBottom={20}>
                        {this.state.authenticated && <div class="br-a-sm border-a border-1 border-gray-lighter card-block card-sm text-xs m-b-md card-no-hover m-t m-md-t-0">
                            <h3 class="text-sm display-flex align-items-flex-start">
                                Welcome {this.state.userData.firstName} {this.state.userData.lastName}
                                <a href="/logout" class="m-l-auto btn btn-xs btn-primary">Logout</a>
                            </h3>
                            {!this.state.userData.isACGMember && <hr />}
                            {!this.state.userData.isACGMember && <div class="display-flex">
                                <h4 class="text-xs text-muted m-b-0">
                                CME Bank
                                </h4>
                                <span class="text-secondary m-l-auto text-700 text-right">
                                {(this.state.credits / 60).toFixed(2)} hours
                                </span>
                            </div>}
                            <div class="m-t-sm text-700">
                                {!this.state.userData.isACGMember && <><a href="/getCredits" class="text-underline">Get credits</a></>}
                            </div>
                        </div>}
                        <div class="card overflow-hidden">
                            <div class="card-header bg-search p-y border-b border-1 brder-b border-gray-lighter">
                                <h3 class="text-md text-uppercase m-a-0 text-400 item-flex">
                                    <span class="circle circle-md bg-orange m-r-sm text-inverse raised-sm">
                                        <svg aria-hidden="true" class="icon"><Icon passedClass="icon-search" /></svg>
                                    </span>
                                    {this.props.mode !== "complete" && this.props.mode !== "account" && <span className={`small`}><strong>Search All Content</strong></span>}
                                    {this.props.mode === "complete" && <span className={`small`}><strong>Search Completed Content</strong></span>}
                                    {this.props.mode === "account" && <span className={`small`}><strong>Search Account Content</strong></span>}
                                </h3>
                            </div>
                            {this.props.mode !== "complete" && <div class="card-block bg-search">
                                <label class="m-b-xs text-sm text-uppercase text-400">Search by Keyword</label>
                                <div className="input-group input-group-search">
                                    <input type="text" name="filter" class="form-control" value={this.state.keywords} onChange={this.handleKeywordChange} onKeyDown={(e) => {if (e.keyCode === 13) {this.keywordSearch()}}} />
                                    <span class="input-group-btn"><button type="submit" class="btn btn-orange p-x-sm" onClick={this.keywordSearch}>Search</button></span>
                                </div>
                                <label class="m-b-xs text-sm text-uppercase text-400 m-t-md">Search by Author</label>
                                <div className="input-group input-group-search">
                                    <input type="text" name="authors" class="form-control" value={this.state.authors} onChange={this.handleAuthorsChange} onKeyDown={(e) => {if (e.keyCode === 13) {this.authorSearch()}}} />
                                    <span class="input-group-btn"><button type="submit" class="btn btn-orange p-x-sm" onClick={this.authorSearch}>Search</button></span>
                                </div>
                            </div>}
                            <div class="card-header border-b border-1 brder-b border-gray-lighter p-t">
                                <h4 class="text-sm text-uppercase m-a-0 text-400" id="filterHdr">
                                    Filter Content <small><a href="#" onClick={(event) => this.clearFilters(event)}>Clear Filters</a></small>
                                </h4>
                            </div>
                            <div class="card-block p-y-sm">
                                <div role="gropu" arial-labelledby="filterHdr">
                                    <div class="bg-white">
                                        {!!this.state.counts && this.state.counts.cme > 0 && <div class="p-y-xs">
                                            <input type="checkbox" className="form-checkbox form-checkbox-sm" id={`cmeonly`} onChange={this.handleCME} name="cmeonly" checked={this.state.cmeonly} />
                                            <label className="form-checkbox-label item-flex m-b-0 text-sm" for="cmeonly">CME Only <span class="label label-gray label-pill m-l-xs">{this.state.counts.cme}</span></label>
                                        </div>}
                                        {!!this.state.counts && this.state.counts.moc > 0 && <div class="p-y-xs">
                                            <input type="checkbox" className="form-checkbox form-checkbox-sm" id={`moconly`} onChange={this.handleMOC} name="moconly" checked={this.state.moconly} />
                                            <label className="form-checkbox-label item-flex m-b-0 text-sm" for="moconly">MOC Only <span class="label label-gray label-pill m-l-xs">{this.state.counts.moc}</span></label>
                                        </div>}
                                { this.state.docTypes.map((type, i) => (
                                        !!this.state.counts && !!this.state.counts[type] && this.state.counts[type] > 0 && <div class="p-y-xs">
                                            <input type="checkbox" className="form-checkbox form-checkbox-sm" id={`filter_${type}`} onChange={this.handleFilters} name={type} checked={this.state.filters.indexOf(type) > -1} />
                                            <label className="form-checkbox-label item-flex m-b-0 text-sm" for={`filter_${type}`}>{type}{type === "CME Only" ? "" : "s"} <span class="label label-gray label-pill m-l-xs">{this.state.counts[type]}</span></label>
                                        </div>
                                ))}
                                        {!!this.state.counts && !!this.state.counts.events && !!this.state.counts.events.length && <div class="p-y-xs">
                                            <label className="text-xs m-b-0">Events</label>
                                            <select onChange={(event) => this.handleEvent(event)} className="form-control" name="event" value={this.state.events[0]}>
                                                <option value="">Select Event</option>
                                                {this.state.counts.events.length && this.state.counts.events.map((o, i) => { return (<option key={i} value={o.event_id + ""}>{o.event}</option>)})}
                                            </select>

                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </StickyBox>
                    </div>
                    <div className="col-md-9">
                        {!this.state.filtered && this.props.mode !== "complete" && this.props.mode !== "account" && <>
                        <CTAs />
                        <div class="text-center m-t-md">
                            <a href="#" className={`btn btn-secondary-outline btn-more${displayClass}`} onClick={(event) => this.handleDisplayAll(event)}>
                                <span class="item-flex">
                                    Show All Content
                                    <svg class="icon icon-md m-l-xs"><Icon passedClass="icon-chevron-down" /></svg>
                                </span>
                            </a>
                        </div></>}
                        {(this.state.displayAll || this.props.mode === "account" || this.props.mode === "complete") && <div class="content-all">
                            <div class="m-b p-b border-b border-1 border-gray-lightest">
                                {!!this.state.tags.length && this.state.tagLibraryCreated && this.state.tags.map((tag, i) => (<button class="btn btn-white btn-pill btn-sm" onClick={(event) => {this.setState({tags: []}, () => {this.setState({ page: 1 });this.getData(this.state.page, this.state.pageSize);})}}>{this.state.tagLibrary[tag + ""].name} <span class="circle circle-sm bg-primary text-inverse close-circle"><svg class="icon"><Icon passedClass="icon-cross" /></svg></span></button>))}
                                {this.state.cmeonly && <button class="btn btn-white btn-pill btn-sm" onClick={(event) => {this.setState({cmeonly: false}, () => {this.setState({ page: 1 });this.getData(this.state.page, this.state.pageSize);})}}>
                                    CME Only <span class="circle circle-sm bg-primary text-inverse close-circle"><svg class="icon"><Icon passedClass="icon-cross" /></svg>
                                </span></button>}
                                {this.state.moconly && <button class="btn btn-white btn-pill btn-sm" onClick={(event) => {this.setState({moconly: false}, () => {this.setState({ page: 1 });this.getData(this.state.page, this.state.pageSize);})}}>
                                    MOC Only <span class="circle circle-sm bg-primary text-inverse close-circle"><svg class="icon"><Icon passedClass="icon-cross" /></svg>
                                </span></button>}
                                { this.state.docTypes.map((type, i) => (
                                        this.state.filters.indexOf(type) > -1 && <button class="btn btn-white btn-pill btn-sm" onClick={(event) => {this.setState(state => {const filters = state.filters.filter(item => item !== type);return {filters};}, () => {this.setState({ page: 1 });this.getData(this.state.page, this.state.pageSize);})}}>
                                            {type} <span class="circle circle-sm bg-primary text-inverse close-circle"><svg class="icon"><Icon passedClass="icon-cross" /></svg></span>
                                        </button>
                                ))}
                                {!!this.state.counts && !!this.state.counts.events && !!this.state.counts.events.length && this.state.counts.events.map((o, i) => { if (!!this.state.events.length && ((o.event_id + "") === this.state.events[0])) return (<button class="btn btn-white btn-pill btn-sm" onClick={(event) => {this.setState({events: []}, () => {this.setState({ page: 1 });this.getData(this.state.page, this.state.pageSize);})}}>{o.event} <span class="circle circle-sm bg-primary text-inverse close-circle"><svg class="icon"><Icon passedClass="icon-cross" /></svg></span></button>)})}
                                {!!this.state.usedKeyword && <button class="btn btn-white btn-pill btn-sm" onClick={(event) => {this.setState({keywords: ""}, () => {this.keywordSearch()})}}>Keyword: {this.state.usedKeyword} <span class="circle circle-sm bg-primary text-inverse close-circle"><svg class="icon"><Icon passedClass="icon-cross" /></svg></span></button>}
                                {!!this.state.usedAuthor && <button class="btn btn-white btn-pill btn-sm" onClick={(event) => {this.setState({authors: ""}, () => {this.authorSearch()})}}>Author: {this.state.usedAuthor} <span class="circle circle-sm bg-primary text-inverse close-circle"><svg class="icon"><Icon passedClass="icon-cross" /></svg></span></button>}
                            </div>
                            {pageControls}
                            {!!this.state.counts && this.state.counts.noResults && <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-danger center text-lg text-600">No search results, please try again.</div>
                                </div>
                            </div>}
                            <div className={wrapClass}>
                                { !this.state.multi && this.state.docs && this.state.docs.map((doc, i) => (<DocumentListing doc={doc} getData={() => {this.getData(this.state.page, this.state.pageSize, true)}} key={i} handleTagLink={this.handleTagLink} handleEventLink={this.handleEventLink} format={this.state.format} mode={this.props.mode} user={this.state.user} />))}
                            </div>
                            {pageControls}
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}
