import React, { Component } from 'react';

class ModuleImages extends Component {
    render() {

        return (
            <div>
                { !!this.props.question.images && !!this.props.question.images.length && this.props.question.images.map((doc, i) => (
                    <><br />Image {i + 1}<br /><a href={doc.url} target="_blank" rel="noopener noreferrer"><img src={doc.url} width="300" vspace="5" border="0" /></a><br />Click the image above to enlarge it.</>
                ))}
            </div>
        )
    }
}

export default ModuleImages;
