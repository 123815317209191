import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FormErrors } from './FormErrors';
import DatePicker from "react-datepicker";
import DocumentModuleListing from './DocumentModuleListing.js';
import Spinner from 'react-spinkit';
import axios from 'axios';

const initialState = {
    title: '',
    cme_value: 0,
    active: false,
    description: '',
    startDate: null,
    endDate: null,
    objectives: '',
    approve_1: '',
    approve_2: '',
    approve_3: '',
    approve_4: '',
    approve_5: '',
    nonMemberPrice: null,
    parsId: null,
    memberPrice: null,
    formErrors: {
        title: '',
    },
    formValid: false,
    filters: [],
    docTypes: [
        "Self-Assessment Program for Maintenance of Certification",
        "Presentation",
        "Module",
        "Self-Assessment Test",
        "Journal",
        "Podcast"
    ],
    loading: false,
    docs: [],
    cmesum: 0,
    mocsum: 0,
    total: 0,
    keywords: "",
    moduleQuestions: [],
    moduleDocs: [],
    page: 1,
    pageSize: 100
};
class AdminModuleForm extends Component {
    constructor (props) {
        super(props);
        this.state = Object.assign({}, JSON.parse(JSON.stringify(initialState)));
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.getModuleData = this.getModuleData.bind(this);
        this.keywordSearch = this.keywordSearch.bind(this);
    }
    handleDateChange(date, field) {
        if (field === "startDate") {
            this.setState({startDate: date}, () => {console.log(this.state)});
        }
        if (field === "endDate") {
            this.setState({endDate: date}, () => {console.log(this.state)});
        }
    }
    handleFilters(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.checked) {
            this.setState(state => {
                const filters = state.filters.concat(name);

                return {
                    filters
                };
            }, () => {
                this.setState({ page: 1 });
                this.getData(this.state.page, this.state.pageSize);
            });
        } else {
            this.setState(state => {
                const filters = state.filters.filter(item => item !== name);

                return {
                    filters
                };
            }, () => {
                this.setState({ page: 1 });
                this.getData(this.state.page, this.state.pageSize);
            });
        }
    }
    keywordSearch(event) {
        this.setState({ page: 1 });
        this.getData(this.state.page, this.state.pageSize);
    }
    componentDidMount() {
        if (this.props.match.params.docid) {
            axios({
                method: "get",
                url: "/api/admin/getModule/" + this.props.match.params.docid,
            }).then(res => {
                const data = res.data.result;
                if (data.startDate) data.startDate = new Date(data.startDate);
                if (data.endDate) data.endDate = new Date(data.endDate);
                let state = Object.assign(JSON.parse(JSON.stringify(initialState)), data);
                this.setState(state, () => {console.log("mount", this.state)});
            })
        }
        this.getData(this.state.page, this.state.pageSize);
        this.getModuleData();
    }
    getModuleData() {
        this.setState({loading: true});
        axios({
            method: "get",
            url: "/api/admin/getModule/" + this.props.match.params.docid,
        }).then(resa => {
            const dataa = resa.data.result;
            if (dataa.startDate) dataa.startDate = new Date(dataa.startDate);
            if (dataa.endDate) dataa.endDate = new Date(dataa.endDate);
            let state = Object.assign(this.state, dataa);
            this.setState(state, () => {console.log("get", this.state)});
            axios({
                method: "get",
                url: "/api/admin/getModuleData/" + this.props.match.params.docid,
            }).then(res => {
                let ids = [];
                const data = res.data.result;
                data.forEach((item) => {
                    ids.push(item.content_id);
                });
                let docs = this.state.docs.map((item) => {
                    item.inModule = ids.indexOf(item.content_id) > -1;
                    return item;
                });
                this.setState({moduleDocs: data});
                this.setState({docs: docs});
                axios({
                    method: "get",
                    url: "/api/admin/getModuleQuestions/" + this.props.match.params.docid,
                }).then(resq => {
                    const questions = resq.data.result;
                    this.setState({moduleQuestions: questions});
                    this.setState({loading: false});
                })
            })
        })
    }
    getData(page, pageSize) {
        this.setState({loading: true});
        const requestParams = {
            method: "get",
            url: "/api/getContent",
            params: {
                page: page,
                pageSize: pageSize
            }
        };
        if (this.state.filters.length) {
            requestParams.params.filters = this.state.filters.join(",").toLowerCase();
            this.setState({filtered: true});
        }
        if (this.state.keywords.length) {
            requestParams.params.keywords = this.state.keywords.split(" ").join(",");
        }
        axios(requestParams).then(res => {
            const docs = res.data.result;
            const total = res.data.total;
            this.setState({loading: false});
            this.setState({docs: []});
            this.setState({ docs, total, page, pageSize }, this.getModuleData);
        })
    }
    resetForm() {
        this.setState(JSON.parse(JSON.stringify(initialState)));
    }

    handleCheck (e) {
        const name = e.target.name;
        const checked = e.target.checked;
        const state = {};
        state[name] = checked;
        this.setState(state);
    }

    handleTagInput (field, tags) {
        this.setState({[field]: tags});
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        const doc = {
            title: this.state.title,
            description: this.state.description,
            objectives: this.state.objectives,
            endDate: this.state.endDate,
            startDate: this.state.startDate,
            parsId: this.state.parsId,
            memberPrice: this.state.memberPrice,
            nonMemberPrice: this.state.nonMemberPrice,
            active: this.state.active,
            approve_1: this.state.approve_1,
            approve_2: this.state.approve_2,
            approve_3: this.state.approve_3,
            approve_4: this.state.approve_4,
            approve_5: this.state.approve_5,
        };

        if (this.props.match.params.docid) {
            doc.docid = this.props.match.params.docid;
            axios({
                method: "put",
                url: "/api/admin/module", 
                data: doc
            }).then(res => {
                this.resetForm();
                this.props.history.push('/admin/module');
            })
        } else {
            axios({
                method: "post",
                url: "/api/admin/module", 
                data: doc
            }).then(res => {
                this.resetForm();
                this.props.history.push('/admin/module');
            })
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors,
            titleValid = !!!this.state.formErrors.title;

        switch(fieldName) {
            case 'title':
                titleValid = value.length >= 6;
                fieldValidationErrors.title = titleValid ? '' : ' is too short';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: !this.state.formErrors.title
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    handleKeywordChange(event) {
        this.setState({keywords: event.target.value});
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <form onSubmit={this.handleSubmit} className="demoForm">
                            <h2>Add Module</h2>
                            <p><a href="/admin/module">Back to Modules</a></p>
                            <div className="panel panel-default">
                                <FormErrors formErrors={this.state.formErrors} />
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="checkbox">
                                        <label>
                                            <input onChange={(event) => this.handleCheck(event)} type="checkbox" name="active" checked={this.state.active} /> Active
                                        </label>
                                    </div>
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.title)}`}>
                                        <label htmlFor="title">Title</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.title} type="text" className="form-control" name="title" />
                                    </div>
                                    <div className={`form-group`}>
                                        <label htmlFor="description">Description</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.description} type="text" className="form-control" name="description" />
                                    </div>
                                    <div className={`form-group`}>
                                        <label htmlFor="objectives">Objectives</label>
                                        <div class="text-sm bg-gray-light text-info">The module is structured to maximize learning and allow the participants to </div>
                                        <textarea onChange={(event) => this.handleUserInput(event)} value={this.state.objectives} className="form-control" name="objectives" />
                                    </div>
                                    <div className={`form-group`}>
                                        <label htmlFor="startDate">Start Date</label>
                                        <div><DatePicker selected={this.state.startDate} onChange={date => this.handleDateChange(date, "startDate")} /></div>
                                    </div>
                                    <div className={`form-group`}>
                                        <label htmlFor="description">End Date</label>
                                        <div><DatePicker selected={this.state.endDate} onChange={date => this.handleDateChange(date, "endDate")} /></div>
                                    </div>
                                    <div className={`form-group`}>
                                        <label htmlFor="parsId">PARS ID</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.parsId} type="text" className="form-control form-control-sm" name="parsId" />
                                    </div>
                                    <div className={`form-group`}>
                                        <label htmlFor="memberPrice">Member Price</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.memberPrice} type="text" className="form-control" name="memberPrice" />
                                    </div>
                                    <div className={`form-group`}>
                                        <label htmlFor="nonMemberPrice">Non-Member Price</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.nonMemberPrice} type="text" className="form-control" name="nonMemberPrice" />
                                    </div>
                                    {[1,2,3,4,5].map(x => (
                                        <div className={`form-group`}>
                                            <label htmlFor={`approve_${x}`}>Notice {x}</label>
                                            <textarea onChange={(event) => this.handleUserInput(event)} value={this.state[`approve_${x}`]} className="form-control" name={`approve_${x}`} />
                                        </div>
                                    ))}
                                    <button type="submit" className="btn btn-primary">
                                        {this.props.match.params.docid ? "Edit Module" : "Add Module"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.props.match.params.docid && <>
                <div className="row">
                    <div className="col-xs-12">
                        <hr />
                        <h3 className="p-t-lg">Questions (shown to user after all items complete)</h3>
                        <p><Link to={`/admin/moduleform/${this.props.match.params.docid}/question`}>Add New Module Question</Link></p>
                        <ul>
                        { this.state.moduleQuestions.map((question, i) => (<li><Link to={`/admin/moduleform/${this.props.match.params.docid}/question/${question.ID}`} dangerouslySetInnerHTML={{__html: question.Question}} /></li>))}
                        </ul>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h3 className="p-t-lg">Chosen Items</h3>
                        <p>Items are saved as soon as you add them. To remove the item just remove it from this list.</p>
                        <p>MOC Total: {this.state.mocsum} | CME Total: {this.state.cmesum}</p>
                    </div>
                </div>
                {this.state.loading && <Spinner name='double-bounce' />}
                <div className="row">
                    { this.state.moduleDocs.map((doc, i) => (<DocumentModuleListing getModuleData={this.getModuleData} moduleId={this.props.match.params.docid} doc={doc} id={'remover' + doc.content_id} key={'remover' + doc.content_id} mode={'remover'} />))}
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h3 className="p-t-lg">Choose Items to Add to the Module</h3>
                    </div>
                </div>
                {this.state.loading && <Spinner name='double-bounce' />}
                {this.props.mode !== "account" && <div className="container-sm text-center">
                    <div className="input-group m-b-md">
                        <input type="text" name="filter" class="form-control display-inline-block" value={this.state.keywords} onChange={this.handleKeywordChange} placeholder="keywords" />
                        <span class="input-group-btn"><button type="submit" class="btn btn-primary" onClick={this.keywordSearch}>Search</button></span>
                    </div>
                </div>}
                <div className="row">
                        { this.state.docTypes.map((type, i) => (
                            <div className="p-y-sm">
                                <input type="checkbox" className="form-checkbox" id={`filter_${type}`} onChange={this.handleFilters} name={type} checked={this.state.filters.indexOf(type) > -1} />
                                <label className="form-checkbox-label item-flex m-b-0" for={`filter_${type}`}>{type}</label>
                            </div>
                        ))}
                </div>
                <div className="row">
                        { this.state.docs.map((doc, i) => (<DocumentModuleListing getModuleData={this.getModuleData} moduleId={this.props.match.params.docid} doc={doc} id={'chooser' + doc.content_id} key={'chooser' + doc.content_id} mode={'chooser'} />))}
                </div>
                <div className="text-center">
                        <ul className="pagination">
                        { [...Array(Math.ceil(this.state.total/this.state.pageSize))].map((_, i) => (<li className={this.state.page === i+1 ? 'active' : ''}><a className="void" href="#" onClick={(event) => this.getData(i+1, this.state.pageSize)}>{i + 1}</a></li>))}
                        </ul>
                </div>
                </>}    
            </div>
        )
    }
}

export default AdminModuleForm;
