import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class MyAccountComplete extends React.Component {
    constructor (props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="p-a-lg bg-secondary text-inverse text-center">Coming Soon</div>
        )
    }
}
