import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

class JournalStatus extends Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: this.props.doc,
            userData: this.props.userData,
            credits: this.props.credits
        };
        this.makePurchase = this.makePurchase.bind(this);
    }
    makePurchase = event => {
        event.preventDefault();
        console.log("make purchase");
        axios({
            method: "post",
            url: "/api/purchase",
            data: {
                contentId: this.state.doc.content_id
            }
        }).then(res => {
            console.log(res);
            console.log(res.data);
            window.location.reload();
        }, err => console.log(err))
    }
    render() {
        let needsToPay = false;
        // let needsToPay = !!this.state.userData && !!this.state.doc && !this.state.doc.journalrecord[0].paid && !this.state.userData.isACGMember;
        return (
            <div className="alert alert-success">
                {!!!this.state.doc.journalrecord[0].evalcomplete && <div>
                You have finished the test. Please click here to complete a brief <Link className="alert-link text-underline text-600" to={`/journals/${this.state.doc.content_id}/evaluation`}>evaluation</Link>. Return to <Link className="alert-link text-underline text-600" to={`/journals/${this.state.doc.content_id}`}>welcome</Link> page or see your certificate of completion once your evaluation is complete.</div>}
                {!!this.state.doc.journalrecord[0].evalcomplete && !!!this.state.doc.journalrecord[0].mocsubmitted && !needsToPay && <div>
                You have finished the test. Return to <Link className="alert-link text-underline text-600" to={`/journals/${this.state.doc.content_id}`}>welcome</Link> page or <a className="alert-link text-underline text-600" href={`/api/journal/${this.state.doc.content_id}/certificate.pdf`} target="_blank" rel="noopener noreferrer">see your certificate of completion</a> or <Link className="alert-link text-underline text-600" to={`/journals/${this.state.doc.content_id}/mocsubmit`}>submit for ABIM MOC credit</Link>.</div>}
                {!!this.state.doc.journalrecord[0].evalcomplete && !!this.state.doc.journalrecord[0].mocsubmitted && !needsToPay && <div>
                You have finished the test. Return to <Link className="alert-link text-underline text-600" to={`/journals/${this.state.doc.content_id}`}>welcome</Link> page or <a className="alert-link text-underline text-600" href={`/api/journal/${this.state.doc.content_id}/certificate.pdf`} target="_blank" rel="noopener noreferrer">see your certificate of completion</a>. MOC Submitted.</div>}
                {!!this.state.doc.journalrecord[0].evalcomplete && needsToPay && this.state.credits < 60 && <div>
                You have finished the test. Return to <Link className="alert-link text-underline text-600" to={`/journals/${this.state.doc.content_id}`}>welcome</Link> page or <a rel="noopener noreferrer" href="/getCredits" className="alert-link text-underline text-600">buy at least 1 credit in order to purchase a certificate.</a></div>}
                {!!this.state.doc.journalrecord[0].evalcomplete && needsToPay && this.state.credits > 60 && <div>
                You have finished the test. Return to <Link className="alert-link text-underline text-600" to={`/journals/${this.state.doc.content_id}`}>welcome</Link> page or <a rel="noopener noreferrer" href="#" onClick={this.makePurchase} className="alert-link text-underline text-600">pay for certificate with 1 of your {(this.state.credits / 60).toFixed(2)} credits.</a></div>}
            </div>
        )
    }
}

export default JournalStatus;
