import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class MyAccountInProgress extends React.Component {
    constructor (props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>foo</div>
        )
    }
}
