import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Link } from "react-router-dom";

class Admin extends Component {
    constructor (props) {
        super(props);
        this.state = {
            authenticated: false,
            redirect: false,
        };
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/isAdmin",
        }).then(sares => {
            this.setState({authenticated: true});
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({redirect: true});
            }
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={`/`} />
        }
        return (
            this.state.authenticated && <div>
                <div className="row">
                    <div className="col-md-8 col-xs-12">
                        <h2>Admin</h2>
                        <div className="row">
                            <div className="col-xs-12">
                                <ul>
                                    <li><Link to="/admin/module">Modules</Link></li>
                                    <li><Link to="/admin/tags">Tags</Link></li>
                                    <li><Link to="/admin/symposia">Symposia Organizations</Link></li>
                                    <li><Link to="/admin/content">Content</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                    </div>
                </div>
            </div>
        )
    }
}

export default Admin;
