'use strict';

import React from 'react';
export default class Icon extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            outerClass: this.props.outerClass,
            passedClass: this.props.passedClass
        };
    }
    render() {
        return (
            <svg className={`icon ${this.state.passedClass} ${this.state.outerClass}`}><use xlinkHref={`#${this.state.passedClass}`}></use></svg>
        )
    }
}
