import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink, withRouter } from "react-router-dom";
import Icon from "./Icon.js";

class BackButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
            <button className="btn btn-primary-outline btn-pill" onClick={() => this.props.history.goBack()}>
                <Icon passedClass="icon-arrow_back" />
                <span className="p-l-sm">Back</span>
            </button>
            <a className="m-l-sm btn btn-primary-outline btn-pill" href="/">
                <Icon passedClass="icon-arrow_back" />
                <span className="p-l-sm">Back to Education Home</span>
            </a>
            </>
        )
    }

}

export default withRouter(BackButton);
