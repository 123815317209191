import React, { Component } from 'react';
import axios from 'axios';
import Icon from "./Icon.js";
import ModuleQuestionAnswersComplete from './ModuleQuestionAnswersComplete.js';
import ModuleQuestionAnswersIncomplete from './ModuleQuestionAnswersIncomplete.js';
import ModuleSideBox from './ModuleSideBox.js';

class ModuleQuestions extends Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: {},
            showMessage: false,
            selectedAnswer: null,
            submitting: false,
            correct: false,
            testComplete: false,
            question: {
                question: {
                    Question: ''
                }
            }
        };
        this.selectAnswer = this.selectAnswer.bind(this)
        this.submitAnswer = this.submitAnswer.bind(this)
        this.getData = this.getData.bind(this)
    }
    selectAnswer(e) {
        this.setState({selectedAnswer: parseInt(e.target.value, 10)});
    }
    submitAnswer(e) {
        if (this.state.selectedAnswer) {
            this.setState({submitting: true});
            axios({
                method: "post",
                url: "/api/submitModuleAnswer",
                data: {
                    answer: this.state.selectedAnswer,
                    question: this.state.question.question.actualid,
                    moduleId: this.state.doc.original_id
                }
            }).then(res => {
                this.setState({submitting: false});
                this.setState({showMessage: true}, () => {
                    if (document.getElementById("answerStatus")) {
                        document.getElementById("answerStatus").scrollIntoView();
                    }
                });
                this.setState({correct: res.data.correct});
                this.getData(this.state.question.question.actualid);
            })
        }
    }
    getData(qid) {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.mid,
        }).then(res => {
            this.setState({doc: res.data.result[0]});
        })
        let questionId = this.props.match.params.qid || qid || null;
        axios({
            method: "get",
            url: !questionId ? "/api/getModuleQuestion/" + this.props.match.params.mid : "/api/getModuleQuestion/" + this.props.match.params.mid + "/" + questionId,
        }).then(res => {
            this.setState({testComplete: res.data.data.completeQuestions === res.data.data.totalQuestions, question: res.data.data});
        })
    }
    componentDidMount() {
        this.getData();
    }

    render() {
        let next,
            answers,
            nextPrevious,
            questionStatus = "",
            previous;
        if (!this.state.question.previousQuestion) {
            previous = "";
        } else {
            previous = (<a href={`/modules/${this.props.match.params.mid}/questions/${this.state.question.previousQuestion}`} className="btn btn-sm btn-primary">Previous</a>);
        }
        if (!this.state.question.nextQuestion) {
            next = "";
        } else {
            next = (<a href={`/modules/${this.props.match.params.mid}/questions/${this.state.question.nextQuestion}`} className="btn btn-sm btn-primary">Next</a>);
        }
        questionStatus = this.state.showMessage ? this.state.correct ? "That answer is correct." : "Sorry, that answer is incorrect." : ""
        answers = this.state.question.question.complete ? <ModuleQuestionAnswersComplete question={this.state.question.question} /> : <ModuleQuestionAnswersIncomplete submitting={this.state.submitting} question={this.state.question.question} submitAnswer={this.submitAnswer} selectAnswer={this.selectAnswer} />;
        nextPrevious = (
                <div className="row p-t">
                    <div className="col-md-6 col-xs-12">
                        {previous}
                    </div>
                    <div className="col-xs-6 text-right">
                        {!!this.state.question.question.complete && next}
                    </div>
                </div>
        );
        return (
            <div className="row">
                <div className="col-md-8 col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href={`/modules/${this.props.match.params.mid}`}>
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back to Module</span>
                        </a>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <h1 class="m-b-md">{this.state.doc.title}</h1>
                        </div>
                    </div>
                    {this.state.testComplete && <div class="alert alert-success">The test is complete, <a href={`/modules/${this.props.match.params.mid}`}>Return to the Module</a></div>}
                    {nextPrevious}
                    <p className="text-lg m-y" dangerouslySetInnerHTML={{__html: this.state.question.question.Question}} />
                    {this.state.showMessage && <div id="answerStatus" className="m-b text-600 text-md">
                        <div className={`alert ${this.state.correct ? "alert-success" : "alert-danger"}`}>{questionStatus}</div>
                    </div>}
                    {answers}
                    {this.state.testComplete && <div class="alert alert-success">The test is complete, <a href={`/modules/${this.props.match.params.mid}`}>Return to the Module</a></div>}
                    {nextPrevious}
                </div>
                <div className="col-md-4 col-xs-12">
                    <ModuleSideBox doc={this.state.doc} question={this.state.question} />
                </div>
            </div>
        )
    }
}

export default ModuleQuestions;
