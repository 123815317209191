import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Tabs from "./Tabs.js";
import Icon from "./Icon.js";
class About extends Component {
    constructor (props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <>
                <div class="row align-items-center m-t-lg">
                    <div class="col-md-612 col-xs-12 p-lg-r-md">
                        <div class="card raised">
                            <div class="card-block p-a-sm bg-primary-light text-center text-inverse">
                                <h2 class="text-lg m-b-0">About</h2>
                            </div>
                            <div class="card-block">

<h2><strong>Accreditation</strong></h2>
<p>The American College of Gastroenterology is accredited by the Accreditation Council for Continuing Medical Education to provide continuing medical education for physicians.</p>
<p>The American College of Gastroenterology designates these enduring materials for&nbsp;<em>AMA PRA Category 1 Credits&trade;</em>. Physicians should claim only the credit commensurate with the extent of their participation in the activity.</p>
<p>To obtain CME credit, you must thoroughly watch and listen to the recorded presentation, reflect on the activity, answer the assessment questions (all questions must be answered correctly), and complete the evaluation for each activity.&nbsp; The designated credit hours for each activity are indicated prior to the beginning of the content.</p>
<p>The ACG Education Universe was released by ACG for web-based learning on April 1, 2006. The dates of original release, termination (after which the content is no longer valid for CME), and most recent review date are posted with each item in the ACG Education Universe.</p>
<p>This CME activity was planned and produced in accordance with ACCME Standards and Essentials.</p>
<h2><strong>Maintenance of Certification</strong></h2>
<p>ABIM MOC points are ONLY available at this time for select Modules.</p>
<p>If you would like ABIM MOC points for these modules, please list your ABIM ID number and Date of Birth in the evaluation form. The designated CME and MOC credits are indicated prior to beginning the content.</p>
<p>Successful completion of select CME modules, which includes participation in the evaluation component, enables the participant to earn Medical Knowledge MOC points in the ABIM&rsquo;s MOC program. Participants will earn MOC points equivalent to the amount of CME credits claimed for the activity. It is the CME activity provider&rsquo;s responsibility to submit participant completion information to ACCME for the purpose of granting ABIM MOC credit.</p>
<h2><strong>Learning Objectives</strong></h2>
<p>The ACG Education Universe contains a wide variety of educational content including lectures, journal articles, self-assessment test questions, and more with topics that address all aspects of gastroenterology. Users are able to meet their needs and interests based on a wide variety of search parameters including subject matter, date, and content type.</p>
<p>After studying materials in the Education Universe, participants should be able to use the self-directed learning format to:</p>
<ul type="disc">
  <li>Describe options for diagnosis,      management, and therapy of GI diseases.</li>
  <li>Implement strategies for screening      and prevention of GI diseases.</li>
</ul>
<p>Specific learning objectives, where appropriate, are included with materials in the site.</p>
<h2><strong>Target Audience</strong></h2>

<p>Enduring materials on ACG’s Education Universe are designed for gastroenterologists, gastroenterology trainees, primary care physicians, nurses, physician assistants, nurse practitioners, and other members of the healthcare team with an interest in the latest information on diagnostic gastroenterology and state-of-the-art treatment of GI and hepatic illnesses.</p>

<h2><strong>Disclosure Information</strong></h2>
<p>The American College of Gastroenterology (ACG) is accredited by the Accreditation Council for Continuing Medical Education (ACCME) to provide continuing medical education for physicians. ACG, as an ACCME accredited provider, ensures balance, independence, objectivity, and scientific rigor in all of its directly and jointly provided education activities. All who are in a position to control the content of an accredited activity are required to disclose to ACG all financial relationships in any amount occurring in the past 24 months with ineligible companies. Ineligible companies (formerly, &ldquo;commercial interests&rdquo;) are those whose primary business is producing, marketing, selling, re‐selling, or distributing healthcare products used by or on patients. The ACCME Standards for Integrity and Independence require that ACG disqualify individuals who refuse to provide this information from involvement in the planning and implementation of accredited continuing education. For all those in control of CME content, ACG will identify and mitigate relevant financial relationships prior to the individuals assuming their roles and will disclose all relevant financial relationships to the learners prior to the beginning of an education activity.</p>
<p>Oversight of all educational programming content is conducted by the ACG Board of Trustees through a review and approval process.</p>
<p>To mitigate relevant financial relationships for speakers, the educational content was peer reviewed by a member of the ACG Educational Affairs Peer Review Subcommittee. Subcommittee members have each indicated they have no relevant financial relationships.</p>
<p>All individuals involved in planning or presenting this activity have disclosed to ACG their financial relationships (or have indicated that none exist). All of the relevant financial relationships listed for these individuals (on the following pages) have been mitigated.</p>

<h2><strong>Investigational Use Disclosures</strong></h2>
<p>ACG&rsquo;s disclosure policy maintains that if any unapproved or off‐label use of a product is to be referenced in a CME program, the faculty member is required to disclose that the product is either investigational or it is not labeled for the usage being discussed. Faculty members who have indicated they may reference an off‐label use in their presentation(s) are noted on the speaker disclosure pages.</p>
<h3><u>Editors</u> </h3>
<p><a href="https://acgcdn.gi.org/education/EdUniverseCOI-2023.pdf" target="_blank">ACG 2022-2023 ACG Education Universe – Editorial Board</a></p>
<h3><u>ACG Board of Trustees</u> </h3>
<p>Oversight of all educational programming content is conducted by the ACG Board of Trustees through a review and approval process. View the ACG Board of Trustees disclosure information by clicking on the link below.</p>
<p><a href="https://acgcdn.gi.org/education/BOT20222023.pdf" target="_blank">ACG 2022-2023 Board of Trustees</a></p>
<h3><u>ACG Staff</u></h3>
<p>All ACG staff in a position to control the content of a CME activity are required to complete ACG&rsquo;s COI form annually. As of January 1, 2023, all ACG staff in a position to control CME content have nothing to disclose.</p>
<h2><strong>Using the Education Universe</strong></h2>
<p>The ACG Education Universe provides access to ACG&rsquo;s high-quality educational materials in a convenient and customizable online package. By setting parameters regarding what you want to learn and the type of material you want to review, the Education Universe develops a specialized program just for you. Educational content comes from a wide variety of materials, including:</p>
<ul type="disc">
  <li>Audio/video lectures (with slides)      from nationally-recognized experts</li>
  <li>Postgraduate Course, Annual      Meeting, and Regional Course materials</li>
  <li>Journal articles</li>
  <li>And more</li>
</ul>
<p>The Education Universe is designed to allow you to start an activity, stop at any time, and resume the activity where you left off. Your progress will be saved each time you login to the Education Universe. The Universe keeps track of how much CME you have earned, and you can print certificates or a statement of your CME credits when needed.</p>
<h2><strong>Browsing Content on the ACG Education Universe</strong></h2>
<p>To fully access content on the Education Universe, you must first log in using your single sign-on account. Once you have successfully logged on, search for content and add it to your portfolio. You can add as much content to your portfolio as you like, and you will be able to access it as long as it is available on the Education Universe.</p>
<p>Step 1) Log in using your single sign-on account. If you do not have an account, click&nbsp;<a href="https://accounts.gi.org/Account/Create?app=universe">here to create one</a>.<br />
  Step 2) To search for content, click on the&nbsp;any of the content boxes on the home page <br />
  Step 3) You may search for specific criteria by using the Keyword or Author Search. Once you have set your search parameters, press the Search button. <br />
  Step 4) Once you locate the content you would like to review, click the&nbsp;<strong>+Add to account </strong>button. Or click the title of a content item to start reviewing the content right away.&nbsp; You can access the content later by going to the <strong>My Account</strong>&nbsp;tab at the top of the page.</p>
<p><strong><em>NOTE</em></strong><em>:&nbsp;Once you access content that you have added to your account, you will not be able to delete it.&nbsp;</em><br />
  <strong><em>Credit may only be claimed once for an original presentation; credit may not be claimed for subsequent presentations of the same material.</em></strong><em>&nbsp;For example, if you attended the 2021 Annual Meeting and claimed CME for attending, you cannot claim additional credits for watching the same videos from the 2021 Annual Meeting.</em></p>
<h2><strong>Obtaining CME Credits – Free to ACG Members</strong></h2>
<p>To obtain&nbsp;<em>AMA PRA Category 1 Credit</em>™, learners complete an activity and answer the self-assessment questions. Links to appropriate bibliographic sources (e.g., PubMed) allow the learner to further study the material. To be eligible for credit, all questions must be answered correctly and the learner must complete an evaluation for each content item.<br />
  </p>
<p>Step 1) Once on the &ldquo;My Account&rdquo; tab, you may use the filters on the left to select for content with CME (CME Only)<br />
  Step 2) Click on the green continue button and review the information (e.g., learning objectives and disclosures) of the material for which you want to receive credit.<br />
  Step 3) Click play to watch the video.<br />
  Step 4) Once you complete a content item with CME, click on the &ldquo;evaluation&rdquo; link that will appear in a green bar and answer the questions.<br />
  Step 5) A &ldquo;Get Certificate&rdquo; button will appear once the content item is complete. This can be downloaded or printed. <br />
  Step 6) If ABIM MOC points are offered for an activity, a link to submit for MOC points will appear. Learner data will be submitted directly to ABIM in 2-3 business days. <br />
</p>
<p><strong>NOTE</strong>: You may also return later to obtain a CME certificates for your completed activities. Click on &ldquo;My Account&rdquo; and you can click on any content items that have CME and show as &ldquo;Completed&rdquo;.</p>
<h2><strong>Privacy Policy</strong></h2>
<p>The American College of Gastroenterology respects your privacy. Personal information collected from participants of our online continuing medical education activities will not be shared with any third party, although if these materials are being presented/completed by those involved in fellowship/training programs, details may be shared with the applicable program/training director, consistent with responsible compliance with training requirements. Personal information is collected through our online CME activities when an online user completes registration and provides a name, address, e-mail address, and other personally identifying information. This information is stored in a database so that the College may track the people using the web site to obtain CME credit, as required by the Accreditation Council for Continuing Medical Education and the American Medical Association. Personal information including names and e-mail addresses may be requested in order to receive e-mail alerts or other information from the College about the website or other educational activities. We do not distribute nor can we assure that we can respond to unsolicited e-mails, nor do we share the names of our subscribers with any third party, subject to the commentary of training/fellowship requirements noted above. We analyze our computer log file to determine which pages are visited most often and duration of use, and which external web sites refer the most visitors to www.gi.org. The College performs periodic basic demographic analyses, including optional web site user surveys, for the express purpose of tailoring future online CME activities to meet the greatest need and interest.</p>
<h2><strong>Copyright Policy</strong></h2>
<p>It is the policy of the ACG that each presenter and contributor sign and agree to the following copyright statement prior to their participation in the event:</p>
<p>I acknowledge that my presentation may be videotaped, audiotaped and photographed, and that it may be reproduced in a number of different media, including audiocassette, videocassette, CD-ROM, Internet and print media. I agree to any such reproduction and release ACG with respect thereto in consideration of my participation on the faculty. Previously published materials including, but not limited to, articles, book chapters, talks, etc. are not acceptable for inclusion in ACG meeting materials. By signing this form, presenters agree to obtain any necessary copyright permission(s), provide them to ACG, and to indemnify ACG for any inadvertent unauthorized use if any portion of the presenter's CME activity materials are not the original work of the presenter and/or if the presenter does not hold the copyright for the materials in question.</p>
<h2><strong>Technical Requirements</strong></h2>
<h3>Browsers </h3>
<p>The latest stable version of any of the following browsers:</p>
<ul>
  <li>Internet Explorer</li>
  <li>Edge</li>
  <li>Firefox</li>
  <li>Safari</li>
  <li>Google Chrome</li>
</ul>
<div></div>
<h3>Javascript</h3>
<p>Javascript must be enabled in your browser.</p>
<h3>Cookies</h3>
<p>Cookies must be enabled in your browser.</p>
<h2>Acrobat Reader</h2>
<p>You will need Adobe Acrobat Reader, you can download it by clicking the image below.<br />
  <a href="https://get.adobe.com/reader/" target="_blank"><img border="0" width="84" src="https://get.adobe.com/reader/7c44e8f/images/adobe_logo.png" alt="Get Adobe Reader" /></a></p>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default About;
