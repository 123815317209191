import React, { Component } from 'react';
import AccountChooser from './AccountChooser.js';
import BackButton from "./BackButton.js";
import axios from 'axios';
import _ from 'lodash';
import { Link, Redirect } from "react-router-dom";
import SATestDoneMessage from './SATestDoneMessage.js';

class SATestClear extends Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: {},
            multi: true,
            redirect: false,
            accounts: [],
            categories:[]
        };
        this.getData = this.getData.bind(this);
        this.clearTest = this.clearTest.bind(this);
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/satestsession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            } else {
                this.getData();
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
                this.getData();
            }
        })
    }
    clearTest(event) {
        event.preventDefault();
        axios({
            method: "delete",
            url: "/api/resetSATest/" + this.props.match.params.tid
        }).then(res => {
            this.setState({redirect: true});
        }, () => {
            this.setState({redirect: true});
        });
    }
    getData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.tid,
        }).then(res => {
            this.setState({doc: res.data.result[0]});
        })
    }
    render() {
        let accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="satest" accounts={this.state.accounts} />) : "";
        if (this.state.redirect) {
            return <Redirect to={`/satest/satest_${this.state.doc.original_id}`} />
        }
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="m-b-md">
                        {this.state.user === "known" && !!!this.props.match.params.mid && <BackButton />}
                        {(this.state.user === null || this.state.user === "anonymous") && !!!this.props.match.params.mid && <a className="btn btn-primary-outline btn-pill" href="/">
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back to Education Home</span>
                        </a>}
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            {accountChooser}
                            {!this.state.multi && <><h3>{this.state.doc.title}</h3>
                                    <p>Are you sure you want to clear your answers and try again?  <span style={{color: "red", "font-weight": "bold"}}>WARNING:</span> <strong>ALL</strong> of your work will be erased and you will <strong>NOT</strong> have access to it again. If you successfully completed the test, you will be awarded CME and MOC credit one time only and can <strong>ONLY</strong> claim credits/points when you have an <strong>ACTIVE PASSED TEST</strong>- the system <strong>DOES NOT ARCHIVE</strong> previous tests and the staff will <strong>NOT</strong> be able to assist in retroactive submissions. <em style={{color: "red"}}>Be certain you have completed your evaluation, printed your CME certificate, and successfully claimed your MOC points <strong>BEFORE</strong> clicking Yes below</em>.</p><p><a href="#" className="btn btn-success" onClick={this.clearTest}>Yes</a> <a className="btn btn-danger" href={`/satest/satest_${this.state.doc.original_id}`}>No</a></p>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SATestClear;
