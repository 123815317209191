import React, { Component } from 'react';
import axios from 'axios';
import Icon from "./Icon.js";
import SAPMOCQuestionAnswersComplete from './SAPMOCQuestionAnswersComplete.js';
import SAPMOCQuestionAnswersIncomplete from './SAPMOCQuestionAnswersIncomplete.js';
import SAPMOCSideBox from './SAPMOCSideBox.js';

const initialState = {
    doc: {},
    showMessage: false,
    selectedAnswer: null,
    correct: false,
    question: {
        question: {
            Question: ''
        }
    }
};

class SAPMOCQuestions extends Component {
    constructor (props) {
        super(props);
        this.state = initialState;
        this.selectAnswer = this.selectAnswer.bind(this)
        this.submitAnswer = this.submitAnswer.bind(this)
        this.getData = this.getData.bind(this)
    }
    selectAnswer(e) {
        this.setState({selectedAnswer: parseInt(e.target.value, 10)});
    }
    submitAnswer(e) {
        if (this.state.selectedAnswer) {
            axios({
                method: "post",
                url: "/api/submitSAPMOCAnswer",
                data: {
                    answer: this.state.selectedAnswer,
                    question: this.state.question.question.actualid,
                    testid: this.state.doc.original_id
                }
            }).then(res => {
                this.setState(initialState);
                this.getData();
            })
        }
    }
    getData(qid) {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.tid,
        }).then(res => {
            this.setState({doc: res.data.result[0]});
        })
        let questionId = this.props.match.params.qid || qid || null;
        axios({
            method: "get",
            url: !questionId ? "/api/getSAPMOCQuestion/" + this.props.match.params.tid : "/api/getSAPMOCQuestion/" + this.props.match.params.tid + "/" + questionId,
        }).then(res => {
            this.setState({question: res.data.data});
        })
    }
    componentDidMount() {
        this.getData();
    }

    render() {
        let next,
            answers,
            nextPrevious,
            questionStatus = "",
            paid = this.state.doc.original_id && this.state.doc.user["paid" + this.state.doc.original_id] === "yes",
            submitted = this.state.doc.original_id && this.state.doc.user["submitted" + this.state.doc.original_id] === "yes",
            notice = this.state.doc.original_id && this.state.doc.user["notice" + this.state.doc.original_id] === true,
            mocsubmitted = this.state.doc.original_id && this.state.doc.user["mocsubmitted" + this.state.doc.original_id] === true,
            complete = this.state.doc.original_id && this.state.doc.user["complete" + this.state.doc.original_id] === "done",
            timescomplete = this.state.doc.original_id && this.state.doc.user["timescomplete" + this.state.doc.original_id] ? this.state.doc.user["timescomplete" + this.state.doc.original_id] : 0,
            evalcomplete = this.state.doc.original_id && this.state.doc.user["eval" + this.state.doc.original_id] === "done",
            expired = new Date(this.state.doc.expiration_date).getTime(),
            now = new Date().getTime(),
            isExpired = now > expired,
            showRecs = timescomplete === 1 || timescomplete === 2,
            previous;

        if (!this.state.question.previousQuestion) {
            previous = "";
        } else {
            previous = (<a href={`/sapmoc/${this.props.match.params.tid}/questions/${this.state.question.previousQuestion}`} className="btn btn-sm btn-primary">Previous</a>);
        }
        if (!this.state.question.nextQuestion) {
            next = "";
        } else {
            next = (<a href={`/sapmoc/${this.props.match.params.tid}/questions/${this.state.question.nextQuestion}`} className="btn btn-sm btn-primary">Next</a>);
        }
        answers = this.state.question.question.complete ? <SAPMOCQuestionAnswersComplete reccs={this.state.question.reccs} question={this.state.question.question} /> : <SAPMOCQuestionAnswersIncomplete question={this.state.question.question} submitAnswer={this.submitAnswer} selectAnswer={this.selectAnswer} />;
        nextPrevious = (
                <div className="row p-t">
                    <div className="col-md-4 col-xs-12">
                        {complete && previous}
                    </div>
                    {this.state.question && <div className="col-md-4 col-xs-12 text-center text-uppercase">
                        Question {this.state.question.questionPosition} of {this.state.question.totalQuestions}
                    </div>}
                    <div className="col-xs-4 text-right">
                        {complete && next}
                    </div>
                </div>
        );
        return (
            <div className="row">
                <div className="col-md-8 col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href="/account">
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back</span>
                        </a>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <h1 class="m-b-md">{this.state.doc.title}</h1>
                        </div>
                    </div>
                    {complete && isExpired && <div class="alert alert-top alert-success">Congratulations! You have successfully completed ACG&apos;s Self-Assessment Program for Maintenance of Certification (ACG SAP-MOC). <strong>This test is no longer eligible for MOC or CME credit.</strong> </div>}
                    {complete && !isExpired && evalcomplete && mocsubmitted && <div class="alert alert-top alert-success">Congratulations! You have successfully completed  ACG&apos;s Self-Assessment Program for Maintenance of Certification (ACG SAP-MOC) and are eligible for CME credits and MOC points. See your <a href={`/api/sapmoc/sapmoc_${this.state.doc.original_id}/certificate.pdf`} target="_blank">CME certificate</a>, see your <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/questions`}>review the questions</a>. MOC Submitted.</div>}
                    {complete && !isExpired && evalcomplete && !mocsubmitted && <div class="alert alert-top alert-success">Congratulations! You have successfully completed  ACG&apos;s Self-Assessment Program for Maintenance of Certification (ACG SAP-MOC) and are eligible for CME credits and MOC points. See your <a href={`/api/sapmoc/sapmoc_${this.state.doc.original_id}/certificate.pdf`}>CME certificate</a>, see your <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/scores`}>scores</a>, <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/questions`}>review the questions</a>. <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/submit`}>submit your information to ACCME for the purpose of granting ABIM MOC credit</a>.</div>}
                    {complete && !isExpired && !evalcomplete && <div class="alert alert-top alert-success">Congratulations! You have successfully completed ACG&apos;s Self-Assessment Program for Maintenance of Certification (ACG SAP-MOC) and are eligible for CME credits and MOC points. Please <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/evaluation`}>click here</a> to complete a brief evaluation (the evaluation must be completed to be able to print your certificate and to submit for MOC points). You may also see your <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/scores`}>scores</a> or <a href={`/sapmoc/sapmoc_${this.state.doc.original_id}/questions`}>review the questions</a>.</div>}
                    {nextPrevious}
                    <p className="text-lg m-y" dangerouslySetInnerHTML={{__html: this.state.question.question.Question}} />
                    {answers}
                    {nextPrevious}
                </div>
                <div className="col-md-4 col-xs-12">
                    <SAPMOCSideBox doc={this.state.doc} question={this.state.question} />
                </div>
            </div>
        )
    }
}

export default SAPMOCQuestions;
