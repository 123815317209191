import React, { Component } from 'react';
import { withRouter, Redirect, BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import axios from 'axios';
import Moment from 'react-moment';
import { SelectOptions } from './SelectOptions';
import SATestModal from './SATestModal.js';
import moment from 'moment';

class SATestNotice extends Component {
    constructor (props) {
        super(props);
        this.state = {
            toQuestions: false,
            noticeContent: "",
            satestid: this.props.testid
        };
        this.submitNotice = this.submitNotice.bind(this)
    }
    componentDidMount() {
        axios({
            method: "get",
            url: `/api/notice/satest_${this.state.satestid}`,
        }).then(res => {
            console.log(res)
            this.setState({noticeContent: res.data});
        })
        /*
        axios({
            method: "get",
            url: "/api/countries",
        }).then(res => {
            this.setState({countries: res.data.result});
        })
        */
    }
    submitNotice = event => {
        event.preventDefault();
        if (this.state.satestid) {
            axios({
                method: "post",
                url: "/api/submitNotice",
                data: {
                    testid: this.state.satestid
                }
            }).then(res => {
                //window.location.reload();
                this.setState({toQuestions: true}, () => {});
            })
        }
    }

    render() {
        if (this.state.toQuestions === true) {
             return <Redirect to={`/satest/satest_${this.state.satestid}/questions`} />
        }
        return (
            <>
            <div className="row">
                <div className="col-xs-12" dangerouslySetInnerHTML={{__html: this.state.noticeContent}}>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <p><SATestModal linkType="subcommittee" doc={this.props.doc} testid={this.props.doc.original_id} /></p>
                    <p><SATestModal linkType="faculty" doc={this.props.doc} testid={this.props.doc.original_id} /></p>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <a href={`#`} onClick={this.submitNotice} className="btn btn-sm btn-primary">Begin Test</a>
                </div>
            </div>
            </>
        )
    }
}

export default SATestNotice;
