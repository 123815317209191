import React, { Component } from 'react';
import SAPMOCModal from './SAPMOCModal.js';
import './SAPMOCSideBox.css';

class SAPMOCSideBox extends Component {
    render() {
        return (
            <div className="bg-gray-lightest p-a">
                <div className="testprogress card card-highlight card-sm card-block text-sm sg-usage">
                    <h3 className="text-lg">Test Progress</h3>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <td>Questions</td>
                                <td className="text-right"><span className="label label-pill label-info">{this.props.question.totalQuestions}</span></td>
                            </tr>
                            <tr>
                                <td>Complete</td>
                                <td className="text-right"><span className="label label-pill label-info">{this.props.question.completeQuestions}</span></td>
                            </tr>
                            <tr>
                                <td>Remaining</td>
                                <td className="text-right"><span className="label label-pill label-warning">{this.props.question.totalQuestions - this.props.question.completeQuestions}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {this.props.question.question.complete && <div className="card card-highlight card-sm card-block text-sm sg-usage">
                    <h3>All Respondents</h3>
                    <table className="table table-sm">
                        <tbody>
                        </tbody>
                    </table>
                </div>}
                <h4>Important Information</h4>
                {!!this.props.doc && !!this.props.doc.original_id && <ul className="unstyled">
                    <li><SAPMOCModal linkType="accreditation" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SAPMOCModal linkType="objectives" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SAPMOCModal linkType="editors" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SAPMOCModal linkType="faq" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SAPMOCModal linkType="technical" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SAPMOCModal linkType="privacypolicy" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                    <li><SAPMOCModal linkType="contactus" doc={this.props.doc} testid={this.props.doc.original_id} /></li>
                </ul>}
            </div>
        )
    }
}

export default SAPMOCSideBox;
