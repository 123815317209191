import React, { Component } from 'react';
import AccountChooser from './AccountChooser.js';
import axios from 'axios';
import BackButton from "./BackButton.js";
import Icon from "./Icon.js";
import VideoPlayer from './VideoPlayer.js';
import { Link, Redirect } from "react-router-dom";
import './Presentations.css';
import 'video.js/dist/video-js.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Moment from 'react-moment';
import { Carousel } from 'react-responsive-carousel';

class Presentations extends Component {
    constructor (props) {
        super(props);
        this.state = {
            userData: {},
            completed: false,
            credits: 0,
            justAdded: false,
            authenticated: false,
            symposia: {},
            hasSymposia: false,
            initted: false,
            multi: true,
            accounts: [],
            user: null,
            timer: null,
            selectedListItem: 0,
            videoFirstTimeUpdate: true,
            currentSlide: 0,
            noCuePoints: false,
            postTestIsDone: false,
            postTestQuestions: 0,
            preTestQuestions: 0,
            showVideo: false,
            testProgress: {},
            testQuestions: 0,
            doc: {},
            player: null,
            cuePoints: {
                cuePoints: []
            }
        };
        this.getTestData = this.getTestData.bind(this);
        this.sendTime = this.sendTime.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.checkPosition = this.checkPosition.bind(this);
        this.sendPosition = this.sendPosition.bind(this);
        this.setPlayer = this.setPlayer.bind(this);
        this.updateCurrentSlide = this.updateCurrentSlide.bind(this);
        this.findCuePoint = this.findCuePoint.bind(this);
        this.listClick = this.listClick.bind(this);
        this.makePurchase = this.makePurchase.bind(this);
        this.clickThumb = this.clickThumb.bind(this);
        this.addToAccount = this.addToAccount.bind(this);
    }
    makePurchase = event => {
        event.preventDefault();
        console.log("make purchase");
        axios({
            method: "post",
            url: "/api/purchase",
            data: {
                contentId: this.state.doc.content_id
            }
        }).then(res => {
            console.log(res);
            console.log(res.data);
            this.getTestData();
        }, err => console.log(err))
    }
    clickThumb(index) {
        this.state.player.currentTime(this.state.cuePoints.cuePoints[index].time);
    }
    listClick(e, cue, index) {
        this.setState({selectedListItem: index});
        this.state.player.currentTime(cue.time);
    }
    findCuePoint(time) {
        var nearest,
            cuePoints = this.state.cuePoints.cuePoints,
            totalCuePoints = cuePoints.length;

        if (time < cuePoints[0].time) {
            return 0;
        } else if (time > cuePoints[totalCuePoints - 1].time) {
            return totalCuePoints - 1;
        } else {
            for (var i = 0; i < totalCuePoints; i++) {
                if (time === cuePoints[i].time) {
                    return i;
                } else {
                    if (time > cuePoints[i].time) {
                        nearest = i;
                    }
                }
            }
            return nearest;
        }
    }

    sendTime(amount) {
        axios({
            method: "post",
            url: "/api/sendTime",
            data: {
                contentId: this.state.doc.original_id,
                totalTime: amount || 60
            }
        }).then(res => {
            if (res && res.data && res.data.completed && !this.state.completed) {
                this.refreshData();
                this.setState({completed: true}, () => console.log(this.state));
            }
        }, err => console.log(err))
    }
    sendPosition(index) {
        axios({
            method: "post",
            url: "/api/sendPosition",
            data: {
                contentId: this.state.doc.original_id,
                position: index + 1,
                totalTime: this.state.cuePoints.cuePoints[index].time
            }
        }).then(res => {
            console.log(res);
            console.log(res.data);
        }, err => console.log(err))
    }
    updateCurrentSlide(index) {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
            this.setState({selectedListItem: index});
            this.setState({
                currentSlide: index
            }, () => {this.sendPosition(index)});
        }
    }
    setPlayer(player) {
        this.setState({player: player});
        player.on("seeked", (event) => {
            this.sendTime(parseInt(player.currentTime(), 10));
        });
        player.on("timeupdate", () => {
            if (this.state.videoFirstTimeUpdate) {
                this.setState({timer: setInterval(this.sendTime, 60000)});
                this.setState({videoFirstTimeUpdate: false});
                this.checkPosition();
                setTimeout(() => {this.setState({initted: true})}, 1000);
            } else {
                if (!this.state.noCuePoints && this.state.initted) {
                    let newCuePoint = this.findCuePoint(player.currentTime());
                    // console.log("this.updateCurrentSlide", "setPlayer", newCuePoint, player.currentTime())
                    this.updateCurrentSlide(newCuePoint);
                }
            }
        });
        player.on("fullscreenchange", (e) => {
            console.log("fullscreenchange", e.target, player);
        });
    }
    addToAccount() {
        if (this.state.doc.inAccount) {
            return false;
        } else {
            axios({
                method: "post",
                url: "/api/addToAccount",
                data: {
                    contentId: this.state.doc.content_id
                }
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.state.doc.inAccount = true;
                this.setState({justAdded: true, doc: this.state.doc});
            }, err => {
            })
        }
    }
    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }
    checkPosition() {
        let cuePoints = this.state.cuePoints.cuePoints;
        if (this.state.doc && this.state.doc.portfolio && this.state.doc.portfolio.length && this.state.doc.portfolio[0].content_position) {
            this.state.player.currentTime(cuePoints[this.state.doc.portfolio[0].content_position - 1].time);
        } else {
            if (this.state.doc && this.state.doc.portfolio && this.state.doc.portfolio.length && this.state.doc.portfolio[0].timeopen) {
                this.state.player.currentTime(this.state.doc.portfolio[0].timeopen * 60);
            }
        }
    }
    getTestData() {
        axios({
            method: "get",
            url: "/api/hasContentTestQuestions",
            params: {
                contentid: this.state.doc.content_id
            }
        }).then(res => {
            if (!!!this.props.match.params.mid) {
                this.setState({preTestQuestions: res.data.result.preTestQuestions, testProgress: res.data.result.testProgress});
                if (!!res.data.result.preTestQuestions) {
                    if (res.data.result.testProgress.questions_complete) {
                        this.setState({showVideo: true});
                    }
                } else {
                    this.setState({showVideo: true});
                }
            } else {
                this.setState({showVideo: true});
            }
        }, err => {
        });
        axios({
            method: "get",
            url: "/api/hasContentTestQuestions",
            params: {
                type: "post",
                contentid: this.state.doc.content_id
            }
        }).then(res => {
            if (!!!this.props.match.params.mid) {
                this.setState({postTestQuestions: res.data.result.postTestQuestions, testProgress: res.data.result.testProgress});
                if (!!res.data.result.postTestQuestions) {
                    if (res.data.result.testProgress.post_questions_complete) {
                        this.setState({postTestIsDone: true});
                    }
                } else {
                    this.setState({postTestIsDone: true});
                }
            }
        }, err => {
        });
    }
    refreshData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.id,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const data = res.data.result[0];
            data.video_human_id = data.human_id.replace("_v1", "");
            this.setState({doc: data}, () => {
            });
        })
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/universesession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            this.setState({authenticated: true});
            this.setState({user: "known"});
            this.setState({userData: sares.data.user});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            }
            axios({
                method: "get",
                url: "/api/getTotal",
            }).then(total => {
                this.setState({credits: total.data.total});
            });
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
            }
        })
        if (this.props.match.params.mid) {
            axios({
                method: "get",
                url: "/api/moduleview/" + this.props.match.params.mid + "/" + this.props.match.params.id,
            });
        }
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.id,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const data = res.data.result[0];
            data.video_human_id = data.human_id.replace("_v1", "");
            this.setState({doc: data}, () => {
                if (this.state.doc && this.state.doc.portfolio[0] && this.state.doc.portfolio[0].percentage_complete && this.state.doc.portfolio[0].percentage_complete >= 90) {
                    this.setState({completed: true});
                }
            });
            if (!!!this.props.match.params.mid) {
                this.addToAccount();
            }
            if (!!data.symposia_organization) {
                this.setState({hasSymposia: true});
            }
            this.getTestData();
            axios({
                method: "get",
                url: "/api/hasTestQuestions",
                params: {
                    contentid: this.state.doc.original_id
                }
            }).then(res => {
                if (!!!this.props.match.params.mid) {
                    this.setState({testQuestions: res.data.result});
                }
                axios({
                    method: "get",
                    url: "/api/getCuePoints/" + this.state.doc.human_id,
                }).then(res => {
                    /*
                    console.log(res)
                    res.data.result.cuePoints.map((cue, i) => {
                        var img = new Image();
                        img.src = 'https://universe-slides.gi.org/' + this.state.doc.human_id + '/slide' + (i+1) + '.jpg';
                    });
                    */
                    this.setState({cuePoints: res.data.result});
                }, err => {
                    this.setState({noCuePoints: true});
                })
            });
        })
    }

    render() {
        let accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="universe" accounts={this.state.accounts} />) : "",
            showCertificate = !!this.state.doc && !!this.state.doc.cme_value && this.state.doc.cme_value > 0 && !!this.state.doc.actual_cme_value && this.state.doc.actual_cme_value > 0,
            needsToPay = !!this.state.userData && !!this.state.testProgress && !!this.state.doc && this.state.doc.cme_value > 0 && this.state.doc.actual_cme_value > 0 && !this.state.userData.isACGMember && !this.state.testProgress.paid,
            instructions =  (<>{this.state.completed && !!!this.props.match.params.mid && showCertificate && !!this.state.userData && !!this.state.testProgress && needsToPay && !!this.state.doc.portfolio && !!this.state.doc.portfolio.length && !this.state.hasSymposia && !!this.state.credits && this.state.credits > this.state.doc.actual_cme_value && this.state.postTestIsDone && <div className="m-t-lg">
                                <p><a rel="noopener noreferrer" href="#" onClick={this.makePurchase} class="btn btn-primary">Pay for certificate with {this.state.doc.actual_cme_value} of your {(this.state.credits / 60).toFixed(2)} credits.</a></p>
                            </div>} 
                            {this.state.completed && !!!this.props.match.params.mid && showCertificate && !!this.state.userData && !!this.state.testProgress && needsToPay && !!this.state.doc.portfolio && !!this.state.doc.portfolio.length && !this.state.hasSymposia && this.state.credits < this.state.doc.actual_cme_value && this.state.postTestIsDone && <div className="m-t-lg">
                                <p><a rel="noopener noreferrer" href="/getCredits" class="btn btn-primary">You have insufficient credits to purchase certificate, buy at least {this.state.doc.actual_cme_value / 60} credits.</a></p>
                            </div>} 
                            {this.state.completed && !!!this.props.match.params.mid && showCertificate && !!this.state.userData && !!this.state.testProgress && !needsToPay && !!this.state.doc.portfolio && !!this.state.doc.portfolio.length && !!this.state.doc.portfolio[0].evaluation_complete && !this.state.hasSymposia && this.state.postTestIsDone && <div className="m-t-lg">
                                <p><a target="_blank" rel="noopener noreferrer" href={`/api/presentations/${this.state.doc.content_id}/certificate.pdf`} class="btn btn-primary">Get Certificate</a></p>
                            </div>} 
                            {this.state.completed && !!!this.props.match.params.mid && showCertificate && !!this.state.userData && !!this.state.testProgress && !needsToPay && !!this.state.doc.portfolio && !!this.state.doc.portfolio.length && !!this.state.doc.portfolio[0].evaluation_complete && this.state.hasSymposia && this.state.postTestIsDone && <div className="m-t-lg">
                                <p><a target="_blank" rel="noopener noreferrer" href={`/api/presentations/${this.state.doc.content_id}/certificate_symposia_physician.pdf`} class="btn btn-primary">Physician Certificate</a> <a target="_blank" rel="noopener noreferrer" href={`/api/presentations/${this.state.doc.content_id}/certificate_symposia_nurse.pdf`} class="btn btn-primary">Nurse Certificate</a></p>
                            </div>} 
                            {this.state.completed && !!!this.props.match.params.mid && !!this.state.doc.portfolio && !!this.state.doc.portfolio.length && this.state.doc.portfolio[0].percentage_complete >= 90 && !!!this.state.doc.portfolio[0].evaluation_complete && this.state.postTestIsDone && !needsToPay && (this.state.doc.actual_cme_value > 0 || this.state.doc.moc_value > 0) && <div>
                                <div className="p-a-sm alert-success m-y">
                                    You are now eligible to fill out the <Link to={`/presentations/${this.state.doc.content_id}/evaluation`} className="text-underline text-600" replace>evaluation</Link> in order to earn credit.
                                </div>
                            </div>}
                            {this.state.completed && !!!this.props.match.params.mid && this.state.postTestQuestions > 0 && !!this.state.doc.portfolio && !!this.state.doc.portfolio.length && this.state.doc.portfolio[0].percentage_complete < 90 && <div>
                                <div className="p-a-sm alert-info m-y">
                                    There is a test to take when this presentation is done.
                                </div>
                            </div>}
                            {this.state.completed && !!!this.props.match.params.mid && this.state.postTestQuestions > 0 && !!this.state.doc.portfolio && !!this.state.doc.portfolio.length && this.state.doc.portfolio[0].percentage_complete >= 90 && !this.state.postTestIsDone && this.state.showVideo && <div>
                                <div className="p-a-sm alert-info m-y">
                                    <Link to={`/content/${this.state.doc.content_id}/questions?type=post`} className="text-underline text-600">Must answer these questions</Link>.
                                </div>
                            </div>}
                            {this.state.completed && !!!this.props.match.params.mid && this.state.postTestQuestions > 0 && this.state.postTestIsDone && !!this.state.doc.portfolio && !!this.state.doc.portfolio.length && this.state.doc.portfolio[0].percentage_complete >= 90 && this.state.showVideo &&<div>
                                <div className="p-a-sm alert-info m-y">
                                    <Link to={`/content/${this.state.doc.content_id}/questions?type=post`} className="text-underline text-600">Review test questions</Link>.
                                </div>
                            </div>}
                            {!!!this.props.match.params.mid && this.state.preTestQuestions > 0 && this.state.testProgress && !this.state.testProgress.questions_complete && <div>
                                <div className="p-a-sm alert-info m-y">
                                    <Link to={`/content/${this.state.doc.content_id}/questions`} className="text-underline text-600">Must answer these questions</Link> before viewing the presentation.
                                </div>
                            </div>}
                            {!!!this.props.match.params.mid && this.state.preTestQuestions > 0 && this.state.testProgress && this.state.testProgress.questions_complete && <div>
                                <div className="p-a-sm alert-info m-y">
                                    <Link to={`/content/${this.state.doc.content_id}/questions`} className="text-underline text-600">Review the pre-test questions</Link>.
                                </div>
                            </div>}</>);
        const videoJsOptions = {
            autoplay: false,
            controls: true,
            inactivityTimeout: 500,
            sources: [{
                src: `https://universe-iphonevideos.gi.org/${this.state.doc.video_human_id}_iphone.mp4`,
                type: 'video/mp4'
            }]
        }
        return (
            <div>
                <div className="row">
                    {accountChooser}
                </div>
                <div className="m-b-md">
                    <BackButton />
                </div>
                {this.state.justAdded && <div className="row">
                    <div className="col-xs-12">
                        <div class="alert alert-success center font-weight-bold text-success font-weight-bold">This item has been successfully added to your account</div>
                    </div>
                </div>}
                {this.state.hasSymposia && <div className="row">
                    <div className="col-xs-12">
                        <h3>Symposia: {this.state.doc.symposia.organizationName}</h3>
                    </div>
                </div>}
                <div className="row m-b-md align-items-flex-end">
                    <div className="col-md-12">
                        {this.state.user === null || this.state.user === "anonymous" && <div class="alert alert-warning">You must <a href="/login">login</a> to purchase or complete the presentation.</div>}
                    </div>
                </div>
                <div className="row m-b-md align-items-flex-end">
                    <div className="col-md-8 col-xs-12">
                        <strong class="text-uc text-muted">Presentation Name</strong>
                        <h1 class="m-b-0" dangerouslySetInnerHTML={{__html: this.state.doc.title}} />
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className="item-flex">
                            {this.state.doc.speakerpicturepath && <img width="60" height="60" className="img-circle m-r" src={`https://universe.gi.org${this.state.doc.speakerpicturepath}`} />}
                            <div>
                                <h2 class="text-uc m-b-xs">Faculty</h2>
                                <p class="m-b-0" dangerouslySetInnerHTML={{__html: this.state.doc.authors}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row ${this.state.noCuePoints ? "noCuePoints" : "hasCuePoints"}`}>
                    <div className={`col-md-${this.state.noCuePoints ? "12" : "8"} col-xs-12`}>
                        <div>
                            {instructions}
                        </div>
                        <div class={this.state.noCuePoints ? "noCuePoints" : "hasCuePoints"}>
                            {this.state.showVideo && this.state.doc.human_id && <VideoPlayer setPlayer={this.setPlayer} { ...videoJsOptions } />}
                        </div>
                        <div>
                            {instructions}
                            {(!!this.state.doc.publish_date || !!this.state.doc.expiration_date) && <div className="row p-t-md">
                                <div className="col-md-6 col-xs-12">
                                    <h4 className="text-uc m-b-xs">Release Date</h4>
                                    <p><Moment format="MM/DD/YYYY" date={this.state.doc.publish_date} /></p>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <h4 className="text-uc m-b-xs">Expiration Date</h4>
                                    <p><Moment format="MM/DD/YYYY" date={this.state.doc.expiration_date} /></p>
                                </div>
                            </div>}
                            <div>
                                <div className="p-t-md">
                                    <h4 className="text-uc m-b-xs">Objective</h4>
                                    <span dangerouslySetInnerHTML={{__html: this.state.doc.objective}} />
                                </div>
                            </div>
                            <div className="row p-t-md">
                                <div className="col-md-6 col-xs-12">
                                    <h4 className="text-uc m-b-xs">Presenter</h4>
                                    <p dangerouslySetInnerHTML={{__html: this.state.doc.authors}} />
                                    <h4 className="text-uc m-b-xs">Presenter Disclosure</h4>
                                    <p dangerouslySetInnerHTML={{__html: this.state.doc.author_disclosure}} />
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <h4 className="text-uc m-b-xs">Event</h4>
                                    <p>{this.state.doc.event_name}</p>
                                    <h4 className="text-uc m-b-xs">Times Saved</h4>
                                    <p>{this.state.doc.accountCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-${this.state.noCuePoints ? "12" : "4"} col-xs-12`}>
                        { this.state.showVideo && <ul className="bg-gray-lightest list-items list-items border-a border-1 slide-links border-gray-light m-b-0 overflow-auto-custom">
                            { this.state.cuePoints.cuePoints.map((doc, i) => (<li className={`text-sm p-x p-y-sm ${this.state.selectedListItem === i ? "bg-primary-light text-inverse" : ""}`} key={i} onClick={(e) => {this.listClick(e, doc, i)}}>{doc.name}</li>))}
                        </ul>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Presentations;
