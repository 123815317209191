import React, { Component } from 'react';
import axios from 'axios';
import Icon from "./Icon.js";
import Moment from 'react-moment';
import BackButton from "./BackButton.js";
import TestSideBoxIntro from './TestSideBoxIntro.js';
import AccountChooser from './AccountChooser.js';
import moment from 'moment';

class Journals extends Component {
    constructor (props) {
        super(props);
        this.state = {
            doc: {},
            justAdded: false,
            multi: true,
            authenticated: false,
            accounts: []
        };
        this.recordView = this.recordView.bind(this)
        this.getData = this.getData.bind(this)
        this.addToAccount = this.addToAccount.bind(this);
    }
    addToAccount() {
        if (this.state.doc.inAccount) {
            return false;
        } else {
            axios({
                method: "post",
                url: "/api/addToAccount",
                data: {
                    contentId: this.state.doc.content_id
                }
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.state.doc.inAccount = true;
                this.setState({justAdded: true, doc: this.state.doc});
            })
        }
    }
    getData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.id,
        }).then(res => {
            console.log(this.props.match.params.id)
            console.log(res);
            console.log(res.data);
            this.setState({doc: res.data.result[0]}, () => {
                if (!!!this.props.match.params.mid) {
                    this.addToAccount();
                }
            });
        })
    }
    recordView() {
        if (this.props.match.params.mid) {
            axios({
                method: "get",
                url: "/api/moduleview/" + this.props.match.params.mid + "/" + this.props.match.params.id,
            });
        }
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/journalsession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            this.setState({authenticated: true});
            this.setState({user: "known"});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            } else {
                this.getData();
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
                this.getData();
            }
        })
    }

    render() {
        let newText = false,
            accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="journal" accounts={this.state.accounts} />) : "";
        const dateToFormat = moment(this.state.doc.publish_date).utc();
        const dateToFormatEnd = moment(this.state.doc.publish_date).utc().add(1, "y");
        return (
            <div className="row">
                <div className="col-md-12 col-xs-12">
                    <div className="m-b-md">
                        <BackButton />
                    </div>
                    {this.state.justAdded && <div className="row">
                        <div className="col-xs-12">
                            <div class="alert alert-success center font-weight-bold text-success font-weight-bold">This item has been successfully added to your account</div>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-xs-12">
                            {accountChooser}
                            {!this.state.multi && <><strong class="text-uc text-muted">Article Name</strong>
                            <h1 class="m-b-md" dangerouslySetInnerHTML={{__html:this.state.doc.title}} />
                            {this.state.user === null || this.state.user === "anonymous" && <div class="alert alert-warning">You must <a href="/login">login</a> to purchase or complete the journal article.</div>}
                            <div className="alert alert-warning">
                                <strong>Release date:</strong> <Moment format="MM/DD/YYYY" date={dateToFormat} /><br />
                                <strong>Expiration date</strong>: <Moment format="MM/DD/YYYY" date={dateToFormatEnd} /> (no CME or MOC credit will be awarded after this date)
                            </div>
                            {!!this.state.authenticated && this.state.user === "known" && <div><a className="btn btn-primary btn-lg m-b-sm" onClick={this.state.recordView} href={this.state.doc.url} target="_blank" rel="noopener noreferrer">
                                <span className="p-r-sm">Read Article</span>
                                <Icon passedClass="icon-open_in_new" />
                            </a></div>}
                            {!!this.state.authenticated && !!!this.props.match.params.mid && <a class="btn btn-primary btn-hero btn-hero-right m-b-md" href={`/journals/${this.state.doc.content_id}/questions`}><strong><i class="icon-circle-arrow-right"></i>Begin Activity</strong></a>}
                            <h4>Learning Objective</h4>
                            <p dangerouslySetInnerHTML={{__html:this.state.doc.objective}} />
                            <p>After continual participation in the <em>American Journal of Gastroenterology</em> continuing medical education activities, the participant should be better able  to:</p>
                            <ul>
                              <li>Formulate diagnostic and treatment approaches  for various liver diseases, including but not limited to hepatitis B, hepatitis  C, NAFLD, cirrhosis.</li>
                              <li>Specify a treatment plan for the various gastric  and esophageal diseases, including but not limited to GERD, Barrett's  esophagus, eosinophilic esophagitis, gastroparesis, IBS, chronic diarrhea.</li>
                              <li>Apply colorectal cancer screening methods and  the most current screening guidelines.</li>
                              <li>Describe nutritional issues related to GI  illness and the complications facing these GI patients.</li>
                              <li>Evaluate new and emerging endoscopic methods in  the management of GI disease.</li>
                              <li>Implement appropriate treatment strategies for  the various GI illnesses facing the pediatric patient.</li>
                              <li>Provide resources and applicable training  information to GI fellows.</li>
                            </ul>
                            <h3>Authors</h3>
                            <p dangerouslySetInnerHTML={{__html:this.state.doc.author_info}} />
                            <h3>Author Disclosures</h3>
                            <p dangerouslySetInnerHTML={{__html:this.state.doc.author_disclosure}} />
                            <h3>Journal CME Committee Disclosure</h3>
                            <span dangerouslySetInnerHTML={{__html:this.state.doc.committee}} />
                            <h3>Target Audience</h3>
                            <p>This program is designed for physicians in gastroenterology and hepatology  as well as physician assistants, nurse practitioners and other advanced  practice healthcare professionals interested in the latest information on  diagnostic GI and state of the art treatment of these illnesses.&nbsp; </p>
                            <h3>Accreditation and Designation Statement</h3>
                            <p>The American College of Gastroenterology is accredited by the Accreditation  Council for Continuing Medical Education (ACCME) to provide continuing medical  education for physicians.</p>
                            <p>The American College of Gastroenterology designates this journal-based CME activity for a maximum of 1 <em>AMA PRA Category 1 Credit</em>&#8482;.&nbsp; Physicians should  claim only the credit  commensurate with the extent of their participation in the activity.</p>
                            <p>Successful completion of this CME activity, which includes participation in the evaluation component, enables the participant to earn 1 Medical Knowledge MOC point in the American Board of Internal Medicine's (ABIM) Maintenance of Certification (MOC) program. Participants will earn MOC points equivalent to the amount of CME credits claimed for the activity. It is the CME activity provider's responsibility to submit participant completion information to ACCME for the purpose of granting ABIM MOC credit.</p>
                            <h3>Instructions for Participation and Credit</h3>
                            <p>There are no fees for participating in or receiving credit for this  educational activity. For information on applicability and acceptance of  continuing medical education credit for this activity, please consult your  professional licensing board.</p>
                            <p>To successfully earn credit, participants must complete the activity online during the valid credit period.  One year from the release date, tests will expire and credit will no longer be offered.</p>
                            <p>Follow these steps to earn <em>AMA PRA Category 1 Credit</em>&trade; and MOC points:</p>
                            <ul>
                            <li>Read the target audience, learning objectives, and author disclosures.</li>
                            <li>Read the article in print or online format.</li>
                            <li>Reflect on the article. </li>
                            <li>Access the CME Exam for the article online, choosing the best answer to each test question. </li>
                            <li>All questions must be answered correctly to obtain credit. </li>
                            <li>Complete the required evaluation component of the online activity, and the required fields for the creation of your CME certificate. </li>
                            </ul>
                            <p>To submit for MOC, you must FIRST complete the CME evaluation and claim your CME credit prior to accessing the MOC site. The MOC link will only be displayed AFTER the CME evaluation has been completed. If you have any questions or concerns regarding this activity, please  contact the ACG at (301) 263-9000 or <a href="mailto:education@gi.org">education@gi.org</a>.</p>
                            <h3>Hardware and Software Requirements</h3>
                            <p>You must have javascript enabled to use this site and also must have Adobe Acrobat Reader installed to view  your certificate once you have finished an activity. You can download Acrobat  Reader <a href="http://www.adobe.com/products/acrobat/readstep2.html" target="_blank" rel="noopener noreferrer">here</a>.</p>
                            <h3>Privacy and Confidentiality</h3>
                            <p>Click <a target="_blank" href="https://gi.org/privacy-policy/">here</a> to review ACG’s privacy policy.</p>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Journals;
