import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import TestSideBoxIntro from './TestSideBoxIntro.js';
import moment from 'moment';
import './AccountChooser.css';

class AccountChooser extends Component {
    constructor (props) {
        super(props);
        this.state = {
            accounts: this.props.accounts,
            accountType: this.props.accountType
        };
        this.chooseAccount = this.chooseAccount.bind(this);
    }
    chooseAccount(id) {
        axios({
            method: "post",
            url: "/api/chooseAccount",
            data: {
                accountType: this.state.accountType,
                account: id
            }
        }).then(res => {
            window.location.reload();
            //this.state.doc.inAccount = true;
            //this.setState({doc: this.state.doc});
        })
    }
    render() {
        let accountTypeText = this.state.accountType === "satest" ? "Self Assessment Test" : this.state.accountType === "sapmoc" ? "Self-Assessment Program for Maintenance of Certification" : this.state.accountType === "journal" ? "ACG Journal CME" : "Universe";
        return (
            <div className="row" id="accountChooserContainer">
                <div className="col-xs-12 m-b-md">
                    <h2>We found several {accountTypeText} Accounts for you, please select the one you would like to use.</h2>
                    <div className="row">
                        { this.state.accounts.map((doc, i) => (<div onClick={(event) => this.chooseAccount(doc.ID)} className="accountchoice col-xs-6">
                            <div class="m-t-sm p-b-xs bg-secondary-pale br-a-sm">
                                <div class="card-header bg-secondary-light">
                                    <h3 class="text-normal m-a-0 text-inverse">{doc.first_name} {doc.last_name}<br /><strong>Created:</strong> - {doc.whenjoined}</h3>
                                </div>
                                <div class="card-block p-t-0">
                                    {(this.state.accountType === "satest" || this.state.accountType === "sapmoc") && <ul>
                                        {doc.tests.map((test, ii) => (<li>
                                            {test.testname} - {test.paid === 'yes' ? '' : 'not ' }purchased
                                        </li>))}
                                    </ul>}
                                    {this.state.accountType === "universe" && <div style={{paddingBottom: 1 + 'em'}}>{doc.usertypename}</div>}
                                </div>
                            </div>
                        </div>))}
                    </div>
                </div>
            </div>
        )
    }
}

export default AccountChooser;
