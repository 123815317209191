import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LeA0ygiAAAAANsXTf_n76YS0W4JNGuwtFdXNmXw">
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
