import React from 'react';
import { Link } from "react-router-dom";
import Icon from "./Icon.js";
import axios from 'axios';
import './DocumentModuleListing.css';
export default class DocumentModuleListing extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            id: this.props.id,
            doc: this.props.doc,
            key: this.props.key
        };
        this.editCME = this.editCME.bind(this);
        this.removeFromModule = this.removeFromModule.bind(this);
        this.addToModule = this.addToModule.bind(this);
    }
    handleUserInput (e) {
        console.log(e)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ doc: { ...this.state.doc, [name]: value} }, ()=>{console.log(this.state)});
    }
    removeFromModule(e) {
        axios({
            method: "post",
            url: "/api/admin/removeFromModule",
            data: {
                contentId: this.state.doc.content_id,
                moduleId: this.props.moduleId
            }
        }).then(res => {
            console.log(res);
            console.log(res.data);
            this.props.getModuleData();
        })
    }
    editCME(e) {
        if (!this.state.doc.inModule) {
            axios({
                method: "post",
                url: "/api/admin/editCME",
                data: {
                    contentId: this.state.doc.content_id,
                    moc_value: this.state.doc.module_moc_value,
                    cme_value: this.state.doc.module_cme_value,
                    moduleId: this.props.moduleId
                }
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.props.getModuleData();
            })
        }
    }
    addToModule(e) {
        if (!this.state.doc.inModule) {
            axios({
                method: "post",
                url: "/api/admin/addToModule",
                data: {
                    contentId: this.state.doc.content_id,
                    moc_value: this.state.doc.actual_cme_value,
                    cme_value: this.state.doc.actual_cme_value,
                    moduleId: this.props.moduleId
                }
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.state.doc.inAccount = true;
                this.setState({doc: this.state.doc});
                this.props.getModuleData();
            })
        }
    }
    render() {
        let title,
            infoBar;

        title = this.state.doc.education_media_type === "journal" ? (<a href={`/journals/${this.state.doc.content_id}`}>{this.state.doc.title}</a>) : this.state.doc.education_media_type === "self-assessment test" ? (<a href={`/satest/${this.state.doc.content_id}`}>{this.state.doc.title}</a>) : (<a href={`/presentations/${this.state.doc.content_id}`}>{this.state.doc.title}</a>);
        /*
        if (this.props.mode === "account") {
        } else {
            title = (<span>{this.state.doc.title}</span>);
        }
        */
        if (this.state.doc.education_media_type === "journal") {
            infoBar = (
                <div>
                    <span className="label label-default">Journal</span>
                    <span className="text-uc text-mute text-500 p-l-xs">Volume {this.state.doc.journal_volume}, Issue {this.state.doc.journal_issue}</span>
                    <p className="text-right m-b-sm text-uc">CME Value <span className="circle bg-secondary text-inverse text-700">1</span></p>
                </div>
            )
        } else if (this.state.doc.education_media_type === "presentation") {
            infoBar = (
                <div>
                    <span className="label label-default">Presentation</span>
                    <p className="text-right m-b-sm text-uc">CME Value <span className="circle bg-secondary text-inverse text-700">{this.state.doc.actual_cme_value}</span></p>
                    <p className="m-t-sm m-b-0">{this.state.doc.event_name}</p>
                </div>
            )
        } else if (this.state.doc.education_media_type === "self-assessment test") {
            infoBar = (
                <div>
                    <span className="label label-default">Self-Assessment Test</span>
                </div>
            )
        } else if (this.state.doc.education_media_type === "podcast") {
            infoBar = (
                <div>
                    <span className="label label-default">Podcast</span>
                </div>
            )
        }
        return (
            <div className="col-md-4 col-xs-12 display-flex flex-column" key={this.state.id}>
                <div className="card card-item flex-1 display-flex flex-column">
                    <div className="card-block">
                        <h2 className="text-lg m-b-sm text-600">{title}</h2>
                        {infoBar}
                    </div>
                    <div class="m-t-auto card-footer text-right p-r-sm">
                        {this.props.mode === "chooser" && <button onClick={(event) => this.addToModule(event)} className={`${this.state.doc.inModule ? 'btn-success-outline': 'btn-primary-outline'} btn btn-sm btn-pill`}>{this.state.doc.inModule ? <Icon passedClass="icon-check" /> : <Icon passedClass="icon-plus" />} {`${this.state.doc.inModule ? "In module" : "Add to module"}`}</button>}
                        {this.props.mode === "remover" && <>
                        <label htmlFor="module_cme_value" class="form-control-label-sm m-r-sm">CME</label>
                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.doc.module_cme_value} type="text" className="form-control-sm" name="module_cme_value" />
                        </>}
                        {this.props.mode === "remover" && <>
                        <label htmlFor="module_moc_value" class="form-control-label-sm m-r-sm m-l-sm">MOC</label>
                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.doc.module_moc_value} type="text" className="form-control-sm" name="module_moc_value" />
                        </>}
                        {this.props.mode === "remover" && <button onClick={(event) => this.editCME(event)} className={`${'btn-primary'} btn btn-sm pull-left`}> Update CME</button>}
                        {this.props.mode === "remover" && <button onClick={(event) => this.removeFromModule(event)} className={`${'btn-primary'} btn btn-sm`}><Icon passedClass="icon-minus" /> Remove from module</button>}
                    </div>
                </div>
            </div>
        )
    }
}
