import React, { Component } from 'react';
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha
} from 'react-google-recaptcha-v3';

class ReCaptchaComponent extends Component<{}> {
    handleVerifyRecaptcha = async () => {
        const executeRecaptcha = this.props.googleReCaptchaProps.executeRecaptcha;

        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('paymentForm');
        this.props.setCaptcha(token);
    };
    componentDidMount() {
        this.handleVerifyRecaptcha();
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

export default withGoogleReCaptcha(ReCaptchaComponent);
