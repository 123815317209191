import React, { Component } from 'react';
import axios from 'axios';
import Icon from "./Icon.js";
import PaymentForm from './PaymentForm.js';
import DocumentListing from './DocumentListing.js';
import './ModulesIntro.css';

class ModulesIntro extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            authenticated: this.props.authenticated,
            noticeContent: this.props.noticeContent,
            doc: this.props.doc,
            moduleDocs: []
        };
        this.getModuleData = this.getModuleData.bind(this);
    }
    componentDidMount() {
        this.getModuleData();
    }
    getModuleData() {
        if (this.state.doc.original_id) {
            this.setState({loading: true});
            axios({
                method: "get",
                url: "/api/getModuleData/" + this.state.doc.original_id,
            }).then(res => {
                console.log(res);
                console.log(res.data);
                const data = res.data.result;
                this.setState({moduleDocs: data});
                this.setState({loading: false});
            })
        }
    }
    render() {
        let paid = this.state.doc.modulerecord && !!this.state.doc.modulerecord.length && this.state.doc.modulerecord[0].paid;
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 p-b-md">
                        {!this.state.doc.inAccount && !paid && this.state.authenticated && <button onClick={(event) => this.props.addToAccount(event)} className={`${'btn-primary'} btn btn-sm`}><Icon passedClass="icon-plus" /> Add Module to Account</button>}
                    </div>
                </div>
                <div className="row m-t-md grid-view">
                    { this.state.moduleDocs.map((doc, i) => (<DocumentListing format="grid" doc={doc} parentdoc={this.state.doc} id={'modules' + doc.content_id} key={'modules' + doc.content_id} mode={'modules'} />))}
                </div>
                <div className="row">
                    <div className="col-xs-12 p-b-md">
                        {!paid && this.state.doc && this.state.doc.original_id && !!this.state.noticeContent.testcost && <PaymentForm doctitle={this.state.doc.title} identifier={"module-" + this.state.doc.original_id} amount={this.state.noticeContent.testcost} />}
                    </div>
                </div>
            </div>
        )
    }
}

export default ModulesIntro;
