import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import Icon from "./Icon.js";
import SATestDoneMessage from './SATestDoneMessage.js';

class SATestEvaluation extends Component {
    constructor (props) {
        super(props);
        const initialState = {
            formValid: false,
            doc: {},
            hours: [],
            formErrors: {},
            creditsclaimed_h: "",
            creditsclaimed_m: "00",
            requiredFields: [
                "objective_new",
                "appropriate_new",
                "met_objectives_new",
                "improve_ability_new",
                "apply_new",
                "performance_new",
                "distribution_new",
                "creditsclaimed"
            ],
            fields: [
                "objective_new",
                "objective_disagree",
                "appropriate_new",
                "met_objectives_new",
                "improve_ability_new",
                "apply_new",
                "performance_new",
                "disagree_new",
                "distribution_new",
                "distribution_disagree",
                "use_references_new",
                "changes_new",
                "barriers_new",
                "comments_new",
                "creditsclaimed"
            ]
        };
        initialState.fields.map(x => {initialState[x] = "";});
        this.state = initialState;
        console.log(this.state)
        this.selectAnswer = this.selectAnswer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    selectAnswer(question, answer) {
        console.log(question, answer)
        this.setState({[question]: answer});
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.tid,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const hours = [...Array(parseInt(res.data.result[0].amacredits, 10) + 1).keys()];
            hours.shift();
            const data = res.data.result[0];
            this.setState({doc: data, hours}, ()=>{
                this.setState({creditsclaimed_h: this.state.doc.amacredits}, ()=>{console.log(this.state)});
            });
        })
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        console.log(this.state.requiredFields.indexOf(fieldName));
        if (this.state.requiredFields.indexOf(fieldName) > -1) {
            fieldValidationErrors[fieldName] = !!value ? '' : ' is required';
            console.log(value, !!value, fieldValidationErrors)
        }
        if ((fieldName === "objective_disagree" || fieldName === "disagree_new" || fieldName === "distribution_disagree") && value && value.length && value.length > 200) {
            fieldValidationErrors[fieldName] = ' is longer than 200 characters';
        }
        if (fieldName === "creditsclaimed" && ((this.state.doc.amacredits === this.state.creditsclaimed_h) && this.state.creditsclaimed_m !== "00")) {
            fieldValidationErrors[fieldName] = " - You have claimed too many credits";
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm(callback) {
        let valid = true;
        for (var field in this.state.formErrors) {
            if (this.state.formErrors[field].length) {
                valid = false;
            }
        }
        this.setState({
            formValid: valid
        }, () => {
            if (!this.state.formValid) {
                if (document.getElementById("formErrors")) {
                    document.getElementById("formErrors").scrollIntoView();
                }
            }
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }
    handleSubmit = event => {
        event.preventDefault();
        console.log(this.state);
        let creditsClaimedMinutes = 0;
        if (this.state.creditsclaimed_m === "00") {
            creditsClaimedMinutes = "00";
        } else if (this.state.creditsclaimed_m === "15") {
            creditsClaimedMinutes = "25";
        } else if (this.state.creditsclaimed_m === "30") {
            creditsClaimedMinutes = "50";
        } else if (this.state.creditsclaimed_m === "45") {
            creditsClaimedMinutes = "75";
        }
        this.setState({creditsclaimed: `${this.state.creditsclaimed_h}.${creditsClaimedMinutes}`}, () => {
            this.state.requiredFields.forEach(field => {
                this.validateField(field, this.state[field]);
            });
            var docBody = {};
            this.state.fields.map(x => {
                docBody[x] = this.state[x];
            });
            docBody.content_id = this.state.doc.original_id;
            delete docBody.doc;
            delete docBody.fields;
            delete docBody.requiredFields;
            delete docBody.formErrors;
            this.validateForm(() => {
                if (this.state.formValid) {
                    axios({
                        method: "post",
                        url: "/api/submitSATestEvaluation",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: docBody
                    }).then(res => {
                        window.location.reload();
                    }, () => {
                    });
                }
            });
        })
    }
    render() {
        if (this.state.doc.user && (!this.state.doc.user[`complete${this.state.doc.original_id}`] || !!this.state.doc.user[`eval${this.state.doc.original_id}`])) {
            return <Redirect to={`/satest/${this.props.match.params.tid}`} />
        }
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href="/account">
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back</span>
                        </a>
                    </div>
                    <h2>Evaluation for {this.state.doc.title}</h2>
                    {!!this.state.doc.original_id && <SATestDoneMessage doc={this.state.doc} />}
                    <form onSubmit={this.handleSubmit} className="evalform">
                        {!this.state.formValid && Object.entries(this.state.formErrors).length > 0 && <div id="formErrors" className="alert alert-danger"><ul>{Object.entries(this.state.formErrors).map((key, value) => {if (key[1].length > 0) return (<li>{key}</li>)})}</ul></div>}
                        {this.state.doc.journalrecord && !!this.state.doc.journalrecord.length && <div className="row">
                            <div className="col-xs-12">
                                <JournalStatus doc={this.state.doc} />
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-xs-12">
                                <div class="alert alert-warning">
                                    Please rate each of the following by clicking on your response. A completed evaluation form is required in order to receive CME credit for this activity.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-7">
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Strongly Agree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Somewhat Agree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Agree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Somewhat Disagree</strong>
                            </div>
                            <div className="col-xs-1 text-center">
                                <strong>Strongly Disagree</strong>
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">This activity was objective, balanced and free of commercial bias.*</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective_new" value="Strongly Agree" className="" onClick={() => this.selectAnswer("objective_new", "Strongly Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective_new" value="Somewhat Agree" className="" onClick={() => this.selectAnswer("objective_new", "Somewhat Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective_new" value="Agree" className="" onClick={() => this.selectAnswer("objective_new", "Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective_new" value="Somewhat Disagree" className="" onClick={() => this.selectAnswer("objective_new", "Somewhat Disagree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="objective_new" value="Strongly Disagree" className="" onClick={() => this.selectAnswer("objective_new", "Strongly Disagree")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            *If you responded "Somewhat Disagree" or "Strongly Disagree", please tell us why you disagree.
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="objective_disagree" value={this.state.objective_disagree} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">This activity was appropriate for the scope of my professional practice.**</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="appropriate_new" value="Strongly Agree" className="" onClick={() => this.selectAnswer("appropriate_new", "Strongly Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="appropriate_new" value="Somewhat Agree" className="" onClick={() => this.selectAnswer("appropriate_new", "Somewhat Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="appropriate_new" value="Agree" className="" onClick={() => this.selectAnswer("appropriate_new", "Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="appropriate_new" value="Somewhat Disagree" className="" onClick={() => this.selectAnswer("appropriate_new", "Somewhat Disagree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="appropriate_new" value="Strongly Disagree" className="" onClick={() => this.selectAnswer("appropriate_new", "Strongly Disagree")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">This activity met the stated objectives.**</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives_new" value="Strongly Agree" className="" onClick={() => this.selectAnswer("met_objectives_new", "Strongly Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives_new" value="Somewhat Agree" className="" onClick={() => this.selectAnswer("met_objectives_new", "Somewhat Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives_new" value="Agree" className="" onClick={() => this.selectAnswer("met_objectives_new", "Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives_new" value="Somewhat Disagree" className="" onClick={() => this.selectAnswer("met_objectives_new", "Somewhat Disagree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="met_objectives_new" value="Strongly Disagree" className="" onClick={() => this.selectAnswer("met_objectives_new", "Strongly Disagree")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">This activity will improve my ability to effectively treat and manage my patients.**</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improve_ability_new" value="Strongly Agree" className="" onClick={() => this.selectAnswer("improve_ability_new", "Strongly Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improve_ability_new" value="Somewhat Agree" className="" onClick={() => this.selectAnswer("improve_ability_new", "Somewhat Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improve_ability_new" value="Agree" className="" onClick={() => this.selectAnswer("improve_ability_new", "Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improve_ability_new" value="Somewhat Disagree" className="" onClick={() => this.selectAnswer("improve_ability_new", "Somewhat Disagree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="improve_ability_new" value="Strongly Disagree" className="" onClick={() => this.selectAnswer("improve_ability_new", "Strongly Disagree")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">I will apply knowledge/skills I learned in the program to my practice.**</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_new" value="Strongly Agree" className="" onClick={() => this.selectAnswer("apply_new", "Strongly Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_new" value="Somewhat Agree" className="" onClick={() => this.selectAnswer("apply_new", "Somewhat Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_new" value="Agree" className="" onClick={() => this.selectAnswer("apply_new", "Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_new" value="Somewhat Disagree" className="" onClick={() => this.selectAnswer("apply_new", "Somewhat Disagree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="apply_new" value="Strongly Disagree" className="" onClick={() => this.selectAnswer("apply_new", "Strongly Disagree")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">This activity will result in performance changes in my professional practice.**</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_new" value="Strongly Agree" className="" onClick={() => this.selectAnswer("performance_new", "Strongly Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_new" value="Somewhat Agree" className="" onClick={() => this.selectAnswer("performance_new", "Somewhat Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_new" value="Agree" className="" onClick={() => this.selectAnswer("performance_new", "Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_new" value="Somewhat Disagree" className="" onClick={() => this.selectAnswer("performance_new", "Somewhat Disagree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="performance_new" value="Strongly Disagree" className="" onClick={() => this.selectAnswer("performance_new", "Strongly Disagree")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            *If you responded "Somewhat Disagree" or "Strongly Disagree", please tell us why you disagree.
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="disagree_new" value={this.state.disagree_new} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7"> There was equitable distribution of questions in each category (e.g., esophagus, stomach, etc).*</div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="distribution_new" value="Strongly Agree" className="" onClick={() => this.selectAnswer("distribution_new", "Strongly Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="distribution_new" value="Somewhat Agree" className="" onClick={() => this.selectAnswer("distribution_new", "Somewhat Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="distribution_new" value="Agree" className="" onClick={() => this.selectAnswer("distribution_new", "Agree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="distribution_new" value="Somewhat Disagree" className="" onClick={() => this.selectAnswer("distribution_new", "Somewhat Disagree")} />
                            </div>
                            <div className="col-xs-1 text-center">
                                <input type="radio" name="distribution_new" value="Strongly Disagree" className="" onClick={() => this.selectAnswer("distribution_new", "Strongly Disagree")} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            *If you disagree, please tell us what additional content areas you would like to see included in future tests.
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="distribution_disagree" value={this.state.distribution_disagree} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            Did you use the linked references for additional information?*
                            </div>
                            <div className="col-xs-5 center">
                                <input type="radio" name="use_references_new" value="Yes" className="" onClick={() => this.selectAnswer("use_references_new", "Yes")} /> Yes<br />
                                <input type="radio" name="use_references_new" value="No" className="" onClick={() => this.selectAnswer("use_references_new", "No")} /> No
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            Please list specific changes you will make in your practice as a result of the information you received from this activity.
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="changes_new" value={this.state.changes_new} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            Please describe any barriers you foresee that may prevent you from implementing changes.
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="barriers_new" value={this.state.barriers_new} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-7">
                            General comments (ie, program strengths and areas for improvement)
                            </div>
                            <div className="col-xs-5 center">
                                <textarea name="comments_new" value={this.state.comments_new} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-xs-12">
                                <p><font color="#FF0000">NOTE: Participants  who would like MOC credit will earn MOC points equivalent to the amount of CME  credits claimed for the activity. Once you submit for CME, this total CANNOT BE  ADJUSTED. </font></p>
                                <p>I certify that I completed this CME activity. The actual time I spent on this activity was:<br /> 
                                {!!this.state.creditsclaimed_h &&<select name="creditsclaimed_h" value={this.state.creditsclaimed_h} onChange={this.handleChange}>
                                    {!!this.state.hours.length && this.state.hours.map(x => {return <option>{x}</option>})}
                                </select>} hours <select name="creditsclaimed_m" value={this.state.creditsclaimed_m} onChange={this.handleChange}>
                                    {!!this.state.hours.length && ["00", "15", "30", "45"].map(x => {return <option>{x}</option>})}
                                </select>minutes</p>
                            </div>
                        </div>
                        
                        <div className="p-a-md" />
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default SATestEvaluation;
