import React from 'react';
import DocumentList from './DocumentList.js';
import MyAccountInProgress from './MyAccountInProgress.js';
import MyAccountComplete from './MyAccountComplete.js';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

export default class MyAccount extends React.Component {
    constructor (props) {
        super(props);
        console.log(this.props);
        this.state = {}
    }
    render() {
        return (
            <Router>
                <div>
                    <div>
                        <Route exact path="/account" render={()=><DocumentList mode="account" />} />
                        <Route exact path="/account/complete" render={()=><DocumentList mode="complete" />} />
                    </div>
                </div>
            </Router>
        )
    }
}
