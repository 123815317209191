import React from 'react';
//import './Home.css';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Icon from "./Icon.js";
import ProgressListing from './ProgressListing.js';
import DocumentListing from './DocumentListing.js';
import DocumentList from './DocumentList.js';
import './LoggedIn.css';
import AccountChooser from './AccountChooser.js';
import CTAs from './CTAs.js';
import Spinner from 'react-spinkit';
import StickyBox from "react-sticky-box";
export default class LoggedIn extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            userData: {},
            user: "known",
            authorRedirect: false,
            keywordRedirect: false,
            authenticated: false,
            sessionChecked: false,
            credits: 0,
            isLoading: false,
            docs: [],
            authors: "",
            keywords: "",
            filters: [],
            authoroptions: [],
            options: [],
            cmeonly: false,
            docTypes: [
                "Presentation",
                "Module",
                "Self-Assessment Test",
                "Journal",
                "Podcast"
            ],
            singleSelections: []
        }
        this.handleFilters = this.handleFilters.bind(this)
        this.getData = this.getData.bind(this)
        this.setSingleSelectionsAuthors = this.setSingleSelectionsAuthors.bind(this)
        this.setSingleSelections = this.setSingleSelections.bind(this)
        this.handleSelection = this.handleSelection.bind(this);
        this.keywordSearch = this.keywordSearch.bind(this);
        this.authorSearch = this.authorSearch.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleAuthorsChange = this.handleAuthorsChange.bind(this);
        this.handleCME = this.handleCME.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }
    clearFilters(event) {
        event.preventDefault();
        this.setState({
            tags: [],
            filters: [],
            events: [],
            authors: "",
            cmeonly: false,
            keywords: "",
            usedKeyword: "",
            usedAuthor: "",
            page: 1,
            filtered: false
        }, () => {
            this.getData(this.state.page, this.state.pageSize);
        });
    }
    handleAuthorsChange(event) {
        this.setState({authors: event.target.value});
    }
    handleKeywordChange(event) {
        this.setState({keywords: event.target.value});
    }
    handleCME(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({cmeonly: target.checked});
    }
    handleFilters(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.checked) {
            this.setState(state => {
                const filters = state.filters.concat(name);

                return {
                    filters
                };
            }, () => {
            });
        } else {
            this.setState(state => {
                const filters = state.filters.filter(item => item !== name);

                return {
                    filters
                };
            }, () => {
            });
        }
    }
    authorSearch(event) {
        this.setState({authorRedirect: true});
    }
    keywordSearch(event) {
        this.setState({keywordRedirect: true});
    }
    handleSelection(event) {
        if (event.length) {
            let path = null;
            if (event[0].title === "View More") {
                path = `/search?filter=${this.state.filters}&keyword=${this.state.keywords}&cmeonly=${this.state.cmeonly ? "yes" : "no"}&authors=${this.state.authors}`
            } else {
                path = event[0].education_media_type === "journal" ? `/journals/${event[0].content_id}` : event[0].education_media_type === "self-assessment test" ? `/satest/${event[0].content_id}` : event[0].education_media_type === "self-assessment program for maintenance of certification" ? `/sapmoc/${event[0].content_id}` : event[0].education_media_type === "presentation" ? `/presentations/${event[0].content_id}` : `/modules/${event[0].content_id}`;
            }
            this.props.history.push(path);
        }
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/universesession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            this.setState({authenticated: true}, () => {
                this.setState({sessionChecked: true});
            });
            this.setState({userData: sares.data.user});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            } else {
                axios({
                    method: "get",
                    url: "/api/journalsession",
                }).then(jores => {
                    this.setState({multijournals: jores.data.multi});
                    if (jores.data.multi) {
                        this.setState({accountsJournals: jores.data.result});
                    } else {
                        this.getData();
                    }
                    axios({
                        method: "get",
                        url: "/api/getTotal",
                    }).then(total => {
                        this.setState({credits: total.data.total});
                    });
                })
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"}, () => {
                    this.setState({sessionChecked: true});
                });
                this.getData();
            }
        })
    }
    getData() {
        this.setState({loading: true});
        const requestParams = {
            method: "get",
            url: "/api/getInProgressContent",
            params: {
            }
        };
        axios(requestParams).then(res => {
            const docs = res.data.result;
            this.setState({loading: false});
            this.setState({docs: []});
            this.setState({ docs });
        })
    }
    setSingleSelectionsAuthors(a, b, c) {
        if (a.length < 3) return;
        this.setState({
            isLoading: true,
            authors: a
        });
        const requestParams = {
            method: "get",
            url: "/api/getContent",
            params: {
                page: 1,
                pageSize: 10
            }
        };
        if (this.state.authors.length) {
            requestParams.params.authors = this.state.authors.split(" ").join(",");
        }
        if (this.state.keywords.length) {
            requestParams.params.keywords = this.state.keywords.split(" ").join(",");
        }
        if (this.state.filters.length) {
            requestParams.params.filters = this.state.filters.join(",").toLowerCase();
        }
        if (this.state.cmeonly) {
            requestParams.params.cmeonly = "yes";
        }
        axios(requestParams).then(res => {
            let result = res.data.result.slice(0);
            result.push({
                title: "View More",
                total: 27
            });
            this.setState({
                authoroptions: result,
                isLoading: false
            });
        })
    }
    setSingleSelections(a, b, c) {
        if (a.length < 3) return;
        this.setState({
            isLoading: true,
            keywords: a
        });
        const requestParams = {
            method: "get",
            url: "/api/getContent",
            params: {
                page: 1,
                pageSize: 10
            }
        };
        if (this.state.authors.length) {
            requestParams.params.authors = this.state.authors.split(" ").join(",");
        }
        if (this.state.keywords.length) {
            requestParams.params.keywords = this.state.keywords.split(" ").join(",");
        }
        if (this.state.filters.length) {
            requestParams.params.filters = this.state.filters.join(",").toLowerCase();
        }
        if (this.state.cmeonly) {
            requestParams.params.cmeonly = "yes";
        }
        axios(requestParams).then(res => {
            let result = res.data.result.slice(0);
            result.push({
                title: "View More",
                total: 27
            });
            this.setState({
                options: result,
                isLoading: false
            });
        })
    }
    render() {
        if (this.state.authorRedirect) {
            return <Redirect to={{
                pathname: "/search",
                search: `?authors=${this.state.authors}`
            }} />
        }
        if (this.state.keywordRedirect) {
            return <Redirect to={{
                pathname: "/search",
                search: `?keyword=${this.state.keywords}`
            }} />
        }
        return (
            <div>
                <div className="row">
                    <div className="col-md-3">
                        <StickyBox className="stick-parent" offsetTop={0} offsetBottom={20}>
                        {this.state.authenticated && <div class="br-a-sm border-a border-1 border-gray-lighter card-block card-sm text-xs m-b-md card-no-hover m-t m-md-t-0">
                            <h3 class="text-sm display-flex align-items-flex-start">
                                Welcome {this.state.userData.firstName} {this.state.userData.lastName}
                                <a href="/logout" class="m-l-auto btn btn-xs btn-primary">Logout</a>
                            </h3>
                            {!this.state.userData.isACGMember && <hr />}
                            {!this.state.userData.isACGMember && <div class="display-flex">
                                <h4 class="text-xs text-muted m-b-0">
                                CME Bank
                                </h4>
                                <span class="text-secondary m-l-auto text-700 text-right">
                                {(this.state.credits / 60).toFixed(2)} hours
                                </span>
                            </div>}
                            <div class="m-t-sm text-700">
                                {!this.state.userData.isACGMember && <><a href="/getCredits" class="text-underline">Get credits</a></>}
                            </div>
                        </div>}
                        <div class="card overflow-hidden">
                            <div class="card-header bg-search p-y border-b border-1 brder-b border-gray-lighter">
                                <h3 class="text-md text-uppercase m-a-0 text-400 item-flex">
                                    <span class="circle circle-md bg-orange m-r-sm text-inverse raised-sm">
                                        <svg aria-hidden="true" class="icon"><Icon passedClass="icon-search" /></svg>
                                    </span>
                                    <span class="small"><strong>Search All Content</strong></span>
                                </h3>
                            </div>
                            <div class="card-block bg-search">
                                <label class="m-b-xs text-sm text-uppercase text-400">Search by Keyword</label>
                                <div className="input-group input-group-search">
                                    <input type="text" name="filter" class="form-control" value={this.state.keywords} onChange={this.handleKeywordChange} onKeyDown={(e) => {if (e.keyCode === 13) {this.keywordSearch()}}} />
                                    <span class="input-group-btn"><button type="submit" class="btn btn-orange p-x-sm" onClick={this.keywordSearch}>Search</button></span>
                                </div>
                                <label class="m-b-xs text-sm text-uppercase text-400 m-t-md">Search by Author</label>
                                <div className="input-group input-group-search">
                                    <input type="text" name="authors" class="form-control" value={this.state.authors} onChange={this.handleAuthorsChange} onKeyDown={(e) => {if (e.keyCode === 13) {this.authorSearch()}}} />
                                    <span class="input-group-btn"><button type="submit" class="btn btn-orange p-x-sm" onClick={this.authorSearch}>Search</button></span>
                                </div>
                            </div>
                            {1 === 2 && <div class="card-block p-t-0">
                                <h4 class="p-a-sm text-xs bg-white border-b border-1 border-gray-lighter m-b-0" id="filterHdr">
                                    Filter Content <small><a href="#" onClick={(event) => this.clearFilters(event)}>Clear Filters</a></small>
                                </h4>
                                <div role="gropu" arial-labelledby="filterHdr">
                                    <div class="p-a-sm bg-white">
                                        <div class="p-y-xs">
                                            <input type="checkbox" className="form-checkbox form-checkbox-sm" id={`cmeonly`} onChange={this.handleCME} name="cmeonly" checked={this.state.cmeonly} />
                                            <label className="form-checkbox-label item-flex m-b-0 text-sm" for="cmeonly">CME Only</label>
                                        </div>
                                { this.state.docTypes.map((type, i) => (
                                        <div class="p-y-xs">
                                            <input type="checkbox" className="form-checkbox form-checkbox-sm" id={`filter_${type}`} onChange={this.handleFilters} name={type} checked={this.state.filters.indexOf(type) > -1} />
                                            <label className="form-checkbox-label item-flex m-b-0 text-sm" for={`filter_${type}`}>{type}{type === "CME Only" ? "" : "s"}</label>
                                        </div>
                                ))}
                                    </div>
                                </div>
                            </div>}
                        </div>
                        </StickyBox>
                    </div>
                    <div className="col-md-9">
                        <div className="card-block bg-primary-pale br-a-sm m-b-md">
                            <h2 className="text-normal m-b item-flex">
                                <strong>In-Progress</strong> 
                                <span className="text-primary-dark text-500 m-l-auto">Pick up where you left off</span>
                            </h2>
                            <div className="link-blocks">
                                { !this.state.multi && this.state.docs && this.state.docs.map((doc, i) => (<ProgressListing doc={doc} key={i} mode={this.props.mode} user={this.state.user} />))}
                                <a href="/account" className="link-blocks-more" key={this.state.key}>
                                    View More
                                    <span className="circle circle-sm text-inverse bg-secondary m-l-sm">
                                        <Icon passedClass="icon-arrow-right" />
                                    </span>
                                </a>
                            </div>
                        </div>
                        <CTAs />
                    </div>
                </div>
            </div>
        )
    }
}
