import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FormErrors } from './FormErrors';
import DatePicker from "react-datepicker";
import Spinner from 'react-spinkit';
import axios from 'axios';

const initialState = {
    organizationName: '',
    formErrors: {
        organizationName: '',
    },
    formValid: false,
    loading: false,
};
class AdminSymposiaForm extends Component {
    constructor (props) {
        super(props);
        console.log(this.props.match.params.docid);
        this.state = initialState;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
    }
    componentDidMount() {
        if (this.props.match.params.docid) {
            axios({
                method: "get",
                url: "/api/admin/getSymposia/" + this.props.match.params.docid,
            }).then(res => {
                console.log(res);
                console.log(res.data);
                const data = res.data.result;
                let state = Object.assign(initialState, data);
                this.setState(state);
            })
        }
        //this.getModuleData();
    }
    resetForm() {
        this.setState(initialState);
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        console.log(e, name, value)
        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        const doc = {
            organizationName: this.state.organizationName,
        };

        if (this.props.match.params.docid) {
            doc.docid = this.props.match.params.docid;
            axios({
                method: "put",
                url: "/api/admin/symposia", 
                data: doc
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.resetForm();
                this.props.history.push('/admin/symposia');
            })
        } else {
            axios({
                method: "post",
                url: "/api/admin/symposia", 
                data: doc
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.resetForm();
                this.props.history.push('/admin/symposia');
            })
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors,
            organizationNameValid = !!!this.state.formErrors.organizationName;

        switch(fieldName) {
            case 'organizationName':
                organizationNameValid = value.length >= 2;
                fieldValidationErrors.organizationName = organizationNameValid ? '' : ' is too short';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: !this.state.formErrors.organizationName
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <form onSubmit={this.handleSubmit} className="demoForm">
                            <h2>Add Symposia Organization</h2>
                            <p><a href="/admin/module">Back to Symposia Organizations</a></p>
                            <div className="panel panel-default">
                                <FormErrors formErrors={this.state.formErrors} />
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.organizationName)}`}>
                                        <label htmlFor="organizationName">Organization Name</label>
                                        <input onChange={(event) => this.handleUserInput(event)} value={this.state.organizationName} type="text" className="form-control" name="organizationName" />
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        {this.props.match.params.docid ? "Edit Organization" : "Add Organization"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.props.match.params.docid && <>
                </>}    
            </div>
        )
    }
}

export default AdminSymposiaForm;
