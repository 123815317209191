import React, { Component } from 'react';

class TestSideBox extends Component {
    render() {
        return (
            <div className="bg-gray-lightest p-a">
                {!!this.props.question && <div className="card card-highlight card-sm card-block text-sm sg-usage">
                    <h3 className="text-lg">Article Test Results</h3>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <td>Questions</td>
                                <td className="text-right"><span className="label label-pill label-info">{this.props.question.totalQuestions}</span></td>
                            </tr>
                            <tr>
                                <td>Complete</td>
                                <td className="text-right"><span className="label label-pill label-info">{this.props.question.completeQuestions}</span></td>
                            </tr>
                            <tr>
                                <td>Remaining</td>
                                <td className="text-right"><span className="label label-pill label-warning">{this.props.question.totalQuestions - this.props.question.completeQuestions}</span></td>
                            </tr>
                            <tr>
                                <td><strong>Score</strong></td>
                                <td className="text-right"><span className="label label-pill label-success">{Math.round((this.props.question.completeQuestions/this.props.question.totalQuestions) * 100)}%</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                {!!this.props.doc.journalrecord && !!this.props.doc.journalrecord.length && <div className="btn-group btn-group-sm btn-group-justified">
                    <a className="btn btn-white" href={this.props.doc.url} target="_blank" rel="noopener noreferrer">Article</a>
                    <a className="btn btn-white" href={`/journals/${this.props.doc.content_id}/questions`}>Questions</a>
                </div>}
                {!!this.props.doc.journalrecord && !!!this.props.doc.journalrecord.length && <div className="btn-group btn-group-sm btn-group-justified">
                    <a className="btn btn-primary" href={this.props.doc.url} target="_blank" rel="noopener noreferrer">Article</a>
                </div>}
                {/* <div className="btn-group btn-group-sm btn-group-justified m-t">
                    <a className="btn btn-primary" href="/account">Back to My ACG CME</a>
                </div> */}
            </div>
        )
    }
}

export default TestSideBox;
