import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

class AdminModule extends Component {
    constructor (props) {
        super(props);
        this.state = {
            modules: []
        };
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/admin/getModules"
        }).then(res => {
            console.log(res);
            console.log(res.data);
            this.setState({ modules: res.data.result });
        })
    }
    render() {
        console.log(this.state)
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <h2>Modules</h2>
                        <p><Link to="/admin/moduleform">Add New Module</Link></p>
                        <ul>
                            {this.state.modules.map(item => (<li><Link to={`/admin/moduleform/${item.id}`}>{item.title}</Link></li>))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminModule;
