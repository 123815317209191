import React, { Component } from 'react';
import AccountChooser from './AccountChooser.js';
import axios from 'axios';
import BackButton from "./BackButton.js";
import Icon from "./Icon.js";
import Moment from 'react-moment';
import moment from 'moment';

class Podcasts extends Component {
    constructor (props) {
        super(props);
        this.state = {
            authenticated: false,
            multi: true,
            justAdded: false,
            accounts: [],
            user: null,
            doc: {},
            counting: false,
            setTime: false,
            timer: null,
            duration: null,
            currentTime: null,
            accounts: []
        };
        this.getData = this.getData.bind(this)
        this.timeChange = this.timeChange.bind(this)
        this.sendTime = this.sendTime.bind(this)
        this.audioLoaded = this.audioLoaded.bind(this);
        this.seeked = this.seeked.bind(this);
        this.addToAccount = this.addToAccount.bind(this);
    }
    addToAccount() {
        if (this.state.doc.inAccount) {
            return false;
        } else {
            axios({
                method: "post",
                url: "/api/addToAccount",
                data: {
                    contentId: this.state.doc.content_id
                }
            }).then(res => {
                console.log(res);
                console.log(res.data);
                this.state.doc.inAccount = true;
                this.setState({justAdded: true, doc: this.state.doc});
            })
        }
    }
    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }
    seeked(event) {
        this.sendTime(parseInt(event.currentTarget.currentTime, 10));
    }
    audioLoaded(e) {
        if (!this.state.setTime && this.state.doc && this.state.doc.podcastProgress && this.state.doc.podcastProgress.currentTime) {
            e.currentTarget.currentTime = this.state.doc.podcastProgress.currentTime;
            this.setState({setTime: true});
        }
    }
    sendTime(amount) {
        axios({
            method: "post",
            url: "/api/sendTimePodcast",
            data: {
                contentId: this.state.doc.original_id,
                currentTime: this.state.currentTime,
                duration: this.state.duration,
                totalTime: amount || 60
            }
        }).then(res => {
            console.log(res);
            console.log(res.data);
        })
    }
    timeChange(e) {
        this.setState({currentTime: Math.round(e.currentTarget.currentTime), duration: Math.round(e.currentTarget.duration)}, function(){
            if (!this.state.counting) {
                this.setState({counting: true, timer: setInterval(this.sendTime, 60000)});
            }
        })
    }
    getData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.id,
        }).then(res => {
            console.log(this.props.match.params.id)
            console.log(res);
            console.log(res.data);
            this.setState({doc: res.data.result[0]});
            if (!!!this.props.match.params.mid) {
                this.addToAccount();
            }
        })
    }
    componentDidMount() {
        axios({
            method: "get",
            url: "/api/universesession",
        }).then(sares => {
            this.setState({multi: sares.data.multi});
            this.setState({authenticated: true});
            this.setState({user: "known"});
            if (sares.data.multi) {
                this.setState({accounts: sares.data.result});
            }
        }, error => {
            if (error && error.response && error.response.status === 401) {
                this.setState({multi: false});
                this.setState({user: "anonymous"});
            }
        })
        if (this.props.match.params.mid) {
            axios({
                method: "get",
                url: "/api/moduleview/" + this.props.match.params.mid + "/" + this.props.match.params.id,
            });
        }
        this.getData();
    }

    render() {
        const dateToFormat = moment(this.state.doc.publish_date).utc();
        let accountChooser = this.state.accounts.length > 0 ? (<AccountChooser accountType="universe" accounts={this.state.accounts} />) : "";
        return (
            <>
            <div className="row">
                {accountChooser}
            </div>
            <div className="row">
                <div className="col-md-12 col-xs-12">
                    <div className="m-b-md">
                        <BackButton />
                    </div>
                    {this.state.justAdded && <div className="row">
                        <div className="col-xs-12">
                            <div class="alert alert-success center font-weight-bold text-success font-weight-bold">This item has been successfully added to your account</div>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-xs-12">
                            {!this.state.multi && <>
                            <h1 class="m-b-md">Podcast: {this.state.doc.title}</h1>
                            {this.state.user === null || this.state.user === "anonymous" && <div class="alert alert-warning">You must <a href="/login">login</a> to purchase or complete the podcast.</div>}
                            <div className="alert alert-warning">
                                <strong>Release date:</strong> <Moment format="MMMM YYYY" date={dateToFormat} /><br />
                            </div>
                            <p class="text-sm m-b-0"><Icon passedClass="icon-user2" /> {this.state.doc.authors}</p>
                            <p class="text-sm m-b-xs m-t-xs">{this.state.doc.description}</p>
                                {this.state.doc && this.state.doc.url && <audio controls onSeeked={this.seeked} onCanPlay={this.audioLoaded} onTimeUpdate={this.timeChange} style={{marginTop: '1em'}}>
                                <source src={this.state.doc.url.replace("http://", "https://")} type="audio/mpeg" />
                            </audio>}
                            {this.state.doc && this.state.doc.secondary_url && <p><a href={this.state.doc.secondary_url} target="_blank">Read the Article</a></p>}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default Podcasts;
