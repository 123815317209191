import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from 'axios';
import Icon from "./Icon.js";
import SATestDoneMessage from './SATestDoneMessage.js';
import "react-datepicker/dist/react-datepicker.css";

class SATestMOCSubmit extends Component {
    constructor (props) {
        super(props);
        const initialState = {
            formErrors: {},
            serverErrors: [],
            formSubmitted: false,
            submitted: false,
            dataChecked: false,
            form: {
                abimid: null,
                birthDate: null,
                fname: null,
                lname: null
            },
            formValid: false,
            doc: {},
            formErrors: {},
            requiredFields: [
                "abimid",
                "fname",
                "lname"
            ],
        };
        this.state = initialState;
        this.selectAnswer = this.selectAnswer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getBirthdate = this.getBirthdate.bind(this);
        this.getData = this.getData.bind(this);
    }
    handleDateChange(date) {
        let form = this.state.form;
        form.birthDate = date;
        this.setState({form: form}, () => {console.log(this.state.form)});
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        let form = this.state.form;
        console.log(name, value, form)
        form[name] = value;
        this.setState({form: form}, () => {
            this.validateField(name, value)
        });
    }
    selectAnswer(question, answer) {
        this.setState({[question]: answer});
    }
    getData() {
        axios({
            method: "get",
            url: "/api/getContent/" + this.props.match.params.tid,
        }).then(res => {
            console.log(res);
            console.log(res.data);
            const data = res.data.result[0];
            this.setState({doc: data}, () => {
                this.setState({dataChecked: true, submitted: this.state.doc.user && !!this.state.doc.user[`mocsubmitted${this.state.doc.original_id}`]});
            });
        })
    }
    componentDidMount() {
        this.getData();
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        if (this.state.requiredFields.indexOf(fieldName) > -1) {
            fieldValidationErrors[fieldName] = value && value.length > 0 ? '' : ' is required';
            console.log(value, fieldValidationErrors)
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm(callback) {
        let valid = true;
        for (var field in this.state.formErrors) {
            if (this.state.formErrors[field].length) {
                valid = false;
            }
        }
        this.setState({
            formValid: valid
        }, () => {
            if (document.getElementById("formErrors")) {
                document.getElementById("formErrors").scrollIntoView();
            }
            if (typeof callback === "function") {
                callback();
            }
        });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    getBirthdate() {
        var dd = this.state.form.birthDate.getDate(),
            mm = this.state.form.birthDate.getMonth() + 1,
            yyyy = this.state.form.birthDate.getFullYear();

        return mm + "/" + dd + "/" + yyyy;
    }

    handleSubmit = event => {
        event.preventDefault();
        if (!this.state.formSubmitted) {
            this.setState({formSubmitted: true,formErrors: {}}, () => {
                this.state.requiredFields.forEach(field => {
                    this.validateField(field, this.state.form[field]);
                });
                if (this.state.formValid) {
                    var docBody = {
                        fname: this.state.form.fname,
                        lname: this.state.form.lname,
                        abim_id: this.state.form.abimid,
                        birthdate: this.state.form.birthDate ? this.getBirthdate() : "",
                        content_id: this.state.doc.content_id
                    };
                }
                this.validateForm(() => {
                    if (this.state.formValid) {
                        axios({
                            method: "post",
                            url: "/api/submitMOCSATest",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            data: docBody
                        }).then(res => {
                            console.log(res)
                            const answ = res.data;
                            if (answ.error) {
                                if (answ.errorMessages) {
                                    let errpass = [];
                                    answ.errorMessages.forEach((item) => {
                                        errpass.push(item.Message);
                                    });
                                    this.setState({formSubmitted: false,serverErrors: errpass});
                                    if (document.getElementById("serverErrors")) {
                                        document.getElementById("serverErrors").scrollIntoView();
                                    }
                                }
                            } else {
                                this.getData();
                            }
                        }, (err) => {
                            let errpass = [];
                            errpass.push("There was a problem with the submission, make sure all fields are filled.");
                            this.setState({formSubmitted: false,serverErrors: errpass});
                            if (document.getElementById("serverErrors")) {
                                document.getElementById("serverErrors").scrollIntoView();
                            }
                        });
                    }
                });
            });
        }
    }
    render() {
        //if (!!!this.state.doc.parsId || !!!this.state.doc.journalrecord[0].evalcomplete) {
        //    return <Redirect to={`/journals/${this.props.match.params.jid}`} />
        //}
        if (this.state.doc.user && !this.state.doc.user[`complete${this.state.doc.original_id}`]) {
            return <Redirect to={`/satest/${this.props.match.params.tid}`} />
        }
        if (this.state.doc.user && !this.state.doc.user[`eval${this.state.doc.original_id}`]) {
            return <Redirect to={`/satest/${this.props.match.params.tid}`} />
        }
        if (this.state.doc.user && this.state.doc.user[`mocsubmitted${this.state.doc.original_id}`]) {
            return <Redirect to={`/satest/${this.props.match.params.tid}`} />
        }
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="m-b-md">
                        <a className="btn btn-primary-outline btn-pill" href="/account">
                            <Icon passedClass="icon-arrow_back" />
                            <span className="p-l-sm">Back</span>
                        </a>
                    </div>
                    <h2>MOC Submission for {this.state.doc.title}</h2>
                    {!!this.state.doc.original_id && <SATestDoneMessage doc={this.state.doc} />}
                    <p><strong>To receive ABIM MOC credit, complete the information below and click Submit.</strong></p>
                    {this.state.submitted && <div className="alert alert-success" id="submitmessage">Your MOC was submitted.</div>}
                    {this.state.dataChecked && !this.state.submitted && <form onSubmit={this.handleSubmit} className="evalform">
                        <div className="p-a-md" />
                        {!this.state.formValid && Object.entries(this.state.formErrors).length > 0 && <div id="formErrors" className="alert alert-info"><ul>{Object.entries(this.state.formErrors).map((key, value) => {if (key[1].length > 0) return (<li>{key}</li>)})}</ul></div>}
                        {this.state.serverErrors.length > 0 && <div id="serverErrors" className="alert alert-warning"><ul>{this.state.serverErrors.map((item) => {return (<li>{item}</li>)})}</ul></div>}
                        <div className="row">
                            {!!this.state.doc.eval && <div className="col-xs-12">
                                MOC Points: {this.state.doc.eval.creditsclaimed}
                            </div>}
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <label for="fname">First Name</label>
                                <input id="fname" name="fname" type="text" class="form-control form-control-lg" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.form.fname} />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <label for="lname">Last Name</label>
                                <input id="lname" name="lname" type="text" class="form-control form-control-lg" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.form.lname} />
                            </div>
                        </div>
                        <div className="p-a-md" />
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <label for="abimid">ABIM ID</label>
                                <input id="abimid" name="abimid" type="text" class="form-control form-control-lg" size="40" onChange={(event) => this.handleUserInput(event)} value={this.state.form.abimid} />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <label>Date of Birth</label>
                                <div><DatePicker selected={this.state.form.birthDate} onChange={this.handleDateChange} /> (mm/dd/yyyy)</div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary m-t-lg">Submit</button>
                    </form>}
                </div>
            </div>
        )
    }
}

export default SATestMOCSubmit;
