import React, { Component } from 'react';
import ContentImages from './ContentImages.js';

class ContentQuestionAnswersComplete extends Component {
    render() {
        var references = {
            Ref1: "",
            Ref2: "",
            Ref3: "",
            Ref4: "",
            Ref5: ""
        };
        for (var x in references) {
            if (this.props.question[x]) {
                var url = `http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?db=PubMed&cmd=Retrieve&list_uids=${this.props.question[x + 'PMID']}&dopt=Abstract`;
                if (this.props.question[x + "PMID"]) {
                    references[x] = (<li><a href={url} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: this.props.question[x]}} /></li>);
                } else {
                    references[x] = (<li dangerouslySetInnerHTML={{__html: this.props.question[x]}}></li>);
                }
            }
        }
        var optionA = this.props.question.Option1 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '1' ? 'success' : (this.props.question.Answer !== '1' && this.props.question.answerentered === 1) ? 'danger' : 'info'}`}>A</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option1.replace("A.", "")}} className={`${this.props.question.Answer === '1' ? 'text-success' : (this.props.question.Answer !== '1' && this.props.question.answerentered === 1) ? 'text-danger' : ''}`} />
                    </label>
                </div>
            ) : "",
            optionB = this.props.question.Option2 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '2' ? 'success' : (this.props.question.Answer !== '2' && this.props.question.answerentered === 2) ? 'danger' : 'info'}`}>B</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option2.replace("B.", "")}} className={`${this.props.question.Answer === '2' ? 'text-success' : (this.props.question.Answer !== '2' && this.props.question.answerentered === 2) ? 'text-danger' : ''}`} />
                    </label>
                </div>
            ) : "",
            optionC = this.props.question.Option3 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '3' ? 'success' : (this.props.question.Answer !== '3' && this.props.question.answerentered === 3) ? 'danger' :'info'}`}>C</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option3.replace("C.", "")}} className={`${this.props.question.Answer === '3' ? 'text-success' : (this.props.question.Answer !== '3' && this.props.question.answerentered === 3) ? 'text-danger' : ''}`} />
                    </label>
                </div>
            ) : "",
            optionD = this.props.question.Option4 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '4' ? 'success' : (this.props.question.Answer !== '4' && this.props.question.answerentered === 4) ? 'danger' :'info'}`}>D</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option4.replace("D.", "")}} className={`${this.props.question.Answer === '4' ? 'text-success' : (this.props.question.Answer !== '4' && this.props.question.answerentered === 4) ? 'text-danger' : ''}`} />
                    </label>
                </div>
            ) : "",
            optionE = this.props.question.Option5 ? (
                <div className="border-top text-sm p-t-xs p-b-xs text-md">
                    <label className="item-flex">
                        <span className={`label label-pill m-r-xs label-${this.props.question.Answer === '5' ? 'success' : (this.props.question.Answer !== '5' && this.props.question.answerentered === 5) ? 'danger' :'info'}`}>E</span> <span dangerouslySetInnerHTML={{__html: this.props.question.Option5.replace("E.", "")}} className={`${this.props.question.Answer === '5' ? 'text-success' : (this.props.question.Answer !== '5' && this.props.question.answerentered === 5) ? 'text-danger' : ''}`} />
                    </label>
                </div>
            ) : "";

        return (
            <div>
                {optionA}
                {optionB}
                {optionC}
                {optionD}
                {optionE}
                {this.props.question && <ContentImages question={this.props.question} />}
                <div className=""><h3 className="p-t-sm p-b-sm">Explanation</h3><div className="p-t-xs p-b-xs" dangerouslySetInnerHTML={{__html: this.props.question.Explanation}}></div></div>
                <h4 className="p-t-sm p-b-sm">References</h4>
                <ol>
                    {references.Ref1}
                    {references.Ref2}
                    {references.Ref3}
                    {references.Ref4}
                    {references.Ref5}
                </ol>
            </div>
        )
    }
}

export default ContentQuestionAnswersComplete;
