import React, { Component } from 'react';
import { withRouter, Redirect, BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import axios from 'axios';
import Moment from 'react-moment';
import { SelectOptions } from './SelectOptions';
import moment from 'moment';

class ModuleNotice extends Component {
    constructor (props) {
        super(props);
        this.state = {
            toQuestions: false,
            noticeContent: this.props.noticeContent,
            doc: this.props.doc,
            moduleid: this.props.moduleid
        };
        this.submitNotice = this.submitNotice.bind(this)
    }
    componentDidMount() {
        /*
        axios({
            method: "get",
            url: "/api/countries",
        }).then(res => {
            this.setState({countries: res.data.result});
        })
        */
    }
    submitNotice = event => {
        event.preventDefault();
        if (this.state.moduleid) {
            axios({
                method: "post",
                url: "/api/submitNoticeModule",
                data: {
                    moduleid: this.state.moduleid
                }
            }).then(res => {
                window.location.reload();
                //this.setState({toQuestions: true}, () => {});
            })
        }
    }

    render() {
        if (this.state.toQuestions === true) {
             return <Redirect to={`/modules/module_${this.state.doc.original_id}`} />
        }
        return (
            <>
            <div className="row">
                <div className="col-xs-12">
                    {1 == 2 && !!this.state.noticeContent.approve_1 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_1}}></span>}
                    {!!this.state.noticeContent.approve_2 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_2}}></span>}
                    {!!this.state.noticeContent.approve_3 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_3}}></span>}
                    {!!this.state.noticeContent.approve_4 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_4}}></span>}
                    {!!this.state.noticeContent.approve_5 && <span dangerouslySetInnerHTML={{__html: this.state.noticeContent.approve_5}}></span>}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <a href={`#`} onClick={this.submitNotice} className="btn btn-sm btn-primary">Begin Module</a>
                </div>
            </div>
            </>
        )
    }
}

export default ModuleNotice;
