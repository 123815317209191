import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Tabs from "./Tabs.js";
import Icon from "./Icon.js";
class Login extends Component {
    constructor (props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <>
                <Tabs authenticated={false} user="anonymous"/>
                <div class="row align-items-center m-t-lg">
                    <div class="col-md-6 col-xs-12 p-lg-r-md">
                        <div class="card raised">
                            <div class="card-block p-a-sm bg-primary-light text-center text-inverse">
                                <h2 class="text-lg m-b-0">Member Log In</h2>
                            </div>
                            <div class="card-block">
                                <form role="form" name="loginForm" method="post" action="https://accounts.gi.org/Account/Login" class="">
                                    <div class="form-group text-lg">
                                        <label for="loginemail">Email</label>
                                        <input type="text" name="loginemail" class="form-control form-control-lg" id="loginemail" placeholder="Email" />
                                    </div>
                                    <div class="form-group text-lg">
                                        <label for="password">Password</label>
                                        <input type="password" name="loginpassword" class="form-control form-control-lg" id="password" placeholder="Password" />
                                        <div class="m-t-sm text-muted text-xs"><a href="https://accounts.gi.org/Account/ForgotPassword" class="forgot-password text-xs text-muted" data-feathr-click-track="true">I forgot my password</a></div>
                                    </div>
                                    <input type="hidden" name="returnurl" value="https://education.gi.org" />
                                    <button type="submit" class="btn btn-orange btn-lg btn-block">Submit</button>
                                </form>
                                <p class="text-sm p-t-md"><a class="text-underline" href="https://accounts.gi.org/Account/Create?app=acgmembers" data-feathr-click-track="true">Create an ACG Website Account</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12 p-lg-l-md">
                        <h3 class="text-xl text-muted m-b">Join ACG, one of the leading organizations for clinical gastroenterologists.</h3>
                        <p>More than 17,000 GI professionals in the U.S. and worldwide call themselves an ACG Member. Join the community of clinical gastroenterologists committed to providing quality in patient care. ACG Members gain access to a wide variety of exclusive resources and benefit from ACG’s ongoing educational and legislative initiatives to ensure quality in care. Learn about ACG’s educational programs, physician resources, including GI OnDEMAND—gastroenterology’s virtual integrated care and support platform, The American Journal of Gastroenterology, much more.</p>
                        <p><a href="https://members.gi.org/acgmembership/" class="btn btn-success" data-feathr-click-track="true">Become a Member</a></p>
                    </div>
                </div>
            </>
        );
    }
}

export default Login;
