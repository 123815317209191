import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DocumentTagsListing from './DocumentTagsListing.js';
import Spinner from 'react-spinkit';
import axios from 'axios';
import _ from "lodash";
import './AdminTags.css';

class AdminTags extends Component {
    constructor (props) {
        super(props);
        this.state = {
            availableTags: [],
            filters: [],
            docTypes: [
                "Self-Assessment Program for Maintenance of Certification",
                "Presentation",
                "Module",
                "Self-Assessment Test",
                "Journal",
                "Podcast"
            ],
            loading: false,
            keywords: "",
            docs: [],
            newtag: "",
            total: 0,
            page: 1,
            pageSize: 100
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.keywordSearch = this.keywordSearch.bind(this);
        this.addTag = this.addTag.bind(this);
    }
    componentDidMount() {
        this.getTags();
        this.getData(this.state.page, this.state.pageSize);
    }
    getTags() {
        axios({
            method: "get",
            url: "/api/admin/getTags"
        }).then(res => {
            this.setState({ availableTags: _.sortBy(res.data.result, "name") });
        })
    }
    handleKeywordChange(event) {
        this.setState({keywords: event.target.value});
    }
    handleDateChange(date, field) {
        if (field === "startDate") {
            this.setState({startDate: date}, () => {console.log(this.state)});
        }
        if (field === "endDate") {
            this.setState({endDate: date}, () => {console.log(this.state)});
        }
    }
    handleFilters(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.checked) {
            this.setState(state => {
                const filters = state.filters.concat(name);

                return {
                    filters
                };
            }, () => {
                this.setState({ page: 1 });
                this.getData(this.state.page, this.state.pageSize);
            });
        } else {
            this.setState(state => {
                const filters = state.filters.filter(item => item !== name);

                return {
                    filters
                };
            }, () => {
                this.setState({ page: 1 });
                this.getData(this.state.page, this.state.pageSize);
            });
        }
    }
    addTag() {
        var rootThis = this;
        var existingTag = _.find(this.state.availableTags, function(o) { return o.name === rootThis.state.newtag } );
        if (!!!existingTag) {
            const requestParams = {
                method: "post",
                url: "/api/admin/addTag",
                data: {
                    tag: this.state.newtag
                }
            };
            axios(requestParams).then(res => {
                this.setState({newtag: ""});
                this.getTags();
            })
        }
    }
    getData(page, pageSize) {
        this.setState({loading: true});
        const requestParams = {
            method: "get",
            url: "/api/getContent",
            params: {
                page: page,
                pageSize: pageSize
            }
        };
        if (this.state.filters.length) {
            requestParams.params.filters = this.state.filters.join(",").toLowerCase();
            this.setState({filtered: true});
        }
        if (this.state.keywords.length) {
            requestParams.params.keywords = this.state.keywords.split(" ").join(",");
        }
        axios(requestParams).then(res => {
            const docs = res.data.result;
            const total = res.data.total;
            this.setState({loading: false});
            this.setState({docs: []});
            this.setState({ docs, total, page, pageSize }, this.getModuleData);
        })
    }
    keywordSearch(event) {
        this.setState({ page: 1 });
        this.getData(this.state.page, this.state.pageSize);
    }
    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {
        });
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <h2>Tags</h2>
                        <div className="input-group m-b-md">
                            <input value={this.state.newtag} onChange={(event) => this.handleUserInput(event)} type="text" className="form-control" name="newtag" />
                            <span class="input-group-btn"><button type="submit" class="btn btn-primary" onClick={this.addTag}>Add New Tag</button></span>
                        </div>
                        <div id="tag_container">
                            <ul>
                                {this.state.availableTags.map(item => (<li>{item.tag}</li>))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h3 className="p-t-lg">Find Content to Tag</h3>
                    </div>
                </div>
                {this.state.loading && <Spinner name='double-bounce' />}
                {this.props.mode !== "account" && <div className="container-sm text-center">
                    <div className="input-group m-b-md">
                        <input type="text" name="filter" class="form-control display-inline-block" value={this.state.keywords} onChange={this.handleKeywordChange} placeholder="keywords" />
                        <span class="input-group-btn"><button type="submit" class="btn btn-primary" onClick={this.keywordSearch}>Search</button></span>
                    </div>
                </div>}
                <div className="row">
                        { this.state.docTypes.map((type, i) => (
                            <div className="p-y-sm">
                                <input type="checkbox" className="form-checkbox" id={`filter_${type}`} onChange={this.handleFilters} name={type} checked={this.state.filters.indexOf(type) > -1} />
                                <label className="form-checkbox-label item-flex m-b-0" for={`filter_${type}`}>{type}</label>
                            </div>
                        ))}
                </div>
                <div className="row">
                        { this.state.availableTags && this.state.availableTags.length && this.state.docs.map((doc, i) => (<DocumentTagsListing getModuleData={this.getModuleData} availableTags={this.state.availableTags} doc={doc} id={'chooser' + doc.content_id} key={'chooser' + doc.content_id} mode={'chooser'} />))}
                </div>
                <div className="text-center">
                        <ul className="pagination">
                        { [...Array(Math.ceil(this.state.total/this.state.pageSize))].map((_, i) => (<li className={this.state.page === i+1 ? 'active' : ''}><a className="void" href="#" onClick={(event) => this.getData(i+1, this.state.pageSize)}>{i + 1}</a></li>))}
                        </ul>
                </div>
            </div>
        )
    }
}

export default AdminTags;
